import { Breadcrumb, MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import { Routes, Route } from "react-router";
import { GridView } from "../../components/gridview/GridView/GridView";
import { IOrganizationsProps } from "./Organizations.types";
import { useOrganizations } from "./Organizations.use";
import { OrganizationResource } from "./Controls/OrganizationResource/OrganizationResource";
import { Text } from "@fluentui/react";
export const OrganizationsComponent: React.FunctionComponent<IOrganizationsProps> = (
  props,
) => {
  const {
    breadcrumb,
    selection,
    error,
    t,
    modal,
    ...gridProps
  } = useOrganizations(props);

  const gridview = () => (
    <div >
      {!modal && <Breadcrumb onGrowData={() => undefined} items={breadcrumb} />}
      {gridProps.items && <div><Text>{`${gridProps.items.length} Organisations`}</Text></div>}

      <GridView
        {...props}
        {...gridProps}
        selection={selection}
      >
        {error !== undefined && (
          <MessageBar
            messageBarType={MessageBarType.error}
          >
            {
              t(
                "Organizations.GridView.ErrorMessage",
                "We cannot display departments at the moment.",
              ) as string
            }
          </MessageBar>
        )}
      </GridView>
    </div>
  );

  return (
    <>
      {modal ? (
        gridview()
      ) : (
        <Routes>
          <Route
            path=":organization"
            element={<OrganizationResource {...props}  />}
          />
          <Route
            path=":organization/*"
            element={<OrganizationResource {...props}  />}
          />
          <Route path="" element={gridview()} />
        </Routes>
      )}
    </>
  );
};
