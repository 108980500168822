import {
  IRectangle,
  ITheme,
  mergeStyleSets,
  List,
  FocusZone,
  Icon,
  INavLinkGroup,
} from "@fluentui/react";
import * as React from "react";
import { Theming } from "../Theming";

const ROWS_PER_PAGE = 3;
const MAX_ROW_HEIGHT = 64;

export interface IGlobalNavListProps {
  theme?: ITheme;
  nav: INavLinkGroup;
  onNavigate: (value: string) => void;
}

export const GlobalNavList: React.FunctionComponent<IGlobalNavListProps> = (
  props: IGlobalNavListProps,
) => {
  const columnCount = React.useRef(0);
  const rowHeight = React.useRef(0);
  const { theme } = props;
  const { palette, fonts } = theme ?? Theming.getTheme();
  const classNames = mergeStyleSets({
    listGridNav: {
      overflow: "hidden",
      width: "100%",
      position: "relative",
    },
    listGridNavTile: {
      textAlign: "center",
      outline: "none",
      position: "relative",
      float: "left",
      selectors: {
        "focus:after": {
          content: "",
          position: "absolute",
          left: 2,
          right: 2,
          top: 2,
          bottom: 2,
          boxSizing: "border-box",
          border: `1px solid ${palette.white}`,
        },
      },
    },
    listGridNavSizer: {
      paddingBottom: "100%",
    },
    listGridNavPadder: {
      position: "absolute",
      left: 2,
      top: 2,
      right: 2,
      bottom: 2,
      cursor: "pointer",
    },
    listGridNavLabel: {
      position: "absolute",
      padding: 10,
      bottom: 0,
      left: 0,
      width: "100%",
      fontSize: fonts.small.fontSize,
      boxSizing: "border-box",
    },
    listGridNavImage: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
    },
  });

  const getItemCountForPage = React.useCallback(
    (itemIndex?: number, surfaceRect?: IRectangle) => {
      if (surfaceRect && (itemIndex ?? 0) === 0) {
        columnCount.current = Math.ceil(surfaceRect.width / MAX_ROW_HEIGHT);
        rowHeight.current = Math.floor(surfaceRect.width / columnCount.current);
      }
      return columnCount.current * ROWS_PER_PAGE;
    },
    [],
  );

  const onRenderCell = React.useCallback((item: any) => {
    function onNavigate(): void {
      props.onNavigate(item.href ?? item.path ?? item.url ?? item.key);
    }
    return (
      <div
        className={classNames.listGridNavTile}
        data-is-focusable
        style={{
          width: `${100 / columnCount.current}%`,
        }}
      >
        <div className={classNames.listGridNavSizer}>
          <div className={classNames.listGridNavPadder} onClick={onNavigate}>
            <Icon
              theme={theme}
              iconName={item.iconProps?.iconName}
              styles={{
                root: {
                  fontSize: "20px",
                  color:
                    theme?.isInverted ?? false
                      ? theme?.semanticColors.bodyText
                      : palette?.themePrimary,
                },
              }}
            />
            {item.name !== undefined && (
              <span className={classNames.listGridNavLabel}>{item.name}</span>
            )}
          </div>
        </div>
      </div>
    );
  }, []);

  const getPageHeight = React.useCallback((): number => {
    return rowHeight.current * ROWS_PER_PAGE;
  }, []);

  const items = props.nav.links;

  return (
    <>
      <div style={{ height: "20px" }} />
      <FocusZone>
        <List
          className={classNames.listGridNav}
          items={items}
          getItemCountForPage={getItemCountForPage}
          getPageHeight={getPageHeight}
          renderedWindowsAhead={4}
          onRenderCell={onRenderCell}
        />
      </FocusZone>
    </>
  );
};
