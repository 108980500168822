import { ITimePickerProps, TimePicker } from "@fluentui/react";
import { FieldProps } from "formik";
import { FormikTextField } from "./FormikTextField";
import {
  createFakeEvent,
  getErrorMessage,
  invokeAll,
  handleProps,
  isReadOnly,
} from "./utils";

export function mapFieldToTimePicker<
  V extends string | number | string[] | number[] | null,
  FormValues = any,
>({
  form,
  field,
  meta,
}: FieldProps<V, FormValues>): Pick<
  ITimePickerProps,
  "onDismiss" | "onChange" | "errorMessage"
> {
  const shared = {
    ...field,
    errorMessage: getErrorMessage({ field, form, meta }),
    onDismiss: () => field.onBlur(createFakeEvent(field)),
  };

  return {
    ...shared,
    onChange: (_, option) => {
      form.setFieldValue(field.name, option);
    },
  };
}

export type FormikTimePickerProps<V, FormValues> = ITimePickerProps &
  FieldProps<V, FormValues>;
export function FormikTimePicker<
  V extends string | number | string[] | number[] | null,
  FormValues = any,
>({ field, form, meta, ...props }: FormikTimePickerProps<V, FormValues>) {
  const { errorMessage, onDismiss, ...fieldProps } = mapFieldToTimePicker({
    field,
    form,
    meta,
  });

  const readOnly = isReadOnly(field, form, props);

  return readOnly ? (
    FormikTextField({
      ...props,
      ...fieldProps,
      errorMessage: errorMessage,
      field: {
        ...field,
        readOnly: true,
        type: "text",
      },
      form,
      meta,
    } as any)
  ) : (
    <>
      <TimePicker
        {...handleProps(props)}
        onDismiss={invokeAll(onDismiss, props.onDismiss)}
        {...fieldProps}
        errorMessage={errorMessage}
      />
    </>
  );
}
