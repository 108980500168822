import { Persona, Icon, PersonaSize, Stack, IconButton } from "@fluentui/react";
import { MediaQueries } from "../../../../../../components/MediaQueries";
import * as React from "react";
import { getUserHeaderClassNames } from "./UserHeader.styles";
import { IUserHeaderProps } from "./UserHeader.types";
import { useTranslation } from "react-i18next";

export const UserHeaderComponent: React.FunctionComponent<IUserHeaderProps> = (
  props,
) => {
  const { styles, selected, onOptions, onToggleOptions } = props;
  const { classNames /* , subComponentStyles */ } = getUserHeaderClassNames(
    styles,
    {
      ...props,
    },
  );
  const {t} = useTranslation();
  return (
    <div className={classNames.root}>
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
        <Persona
          text={selected.displayName}
          secondaryText={t("Users.User","User")}
          onRenderPersonaCoin={() => (
            <Icon iconName="Contact" styles={{ root: { fontSize: "40px" } }} />
          )}
          size={PersonaSize.size100}
        />
        <IconButton
          iconProps={{
            iconName: onOptions ? "ChevronUp" : "ChevronDown",
          }}
          onClick={onToggleOptions}
          styles={{
            root: {
              display: "none",
              [MediaQueries.mobile]: {
                display: "block",
              },
            },
          }}
        />
      </Stack>
    </div>
  );
};
