import { MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import { GridView } from "../../../../../../components/gridview/GridView/GridView";
import { IUserAppsProps } from "./UserApps.types";
import { useUserApps } from "./UserApps.use";
//import { CommandBar } from "@fluentui/react";
import { AddAppsComponent, RemoveAppsComponent } from "../../../AddApps/AddApps.base";
//import { Route, Routes } from "react-router";
//import { UserSignInBlade } from "./Controls/UserSignInBlade/UserSignInBlade";
export const UserAppsComponent: React.FunctionComponent<
  IUserAppsProps
> = (props) => {
  const { error,addingApps,onCommonDismiss,RemovingApps,selectedApps,selection,onDismissRemoving, ...gridProps  } = useUserApps(props);
  return (
    <>
      <div>
        {addingApps && <AddAppsComponent selectedApps={[]} {...props} onDismiss={onCommonDismiss} RemovingApps={false} ></AddAppsComponent> }

        {RemovingApps && <RemoveAppsComponent selectedApps={selectedApps}{...props} RemovingApps={RemovingApps} onDismiss={onDismissRemoving} ></RemoveAppsComponent>}
        <GridView selectionMode={2} selection={selection} {...props} {...gridProps}>
          {error !== undefined && (
            <MessageBar messageBarType={MessageBarType.error}>We cannot display Apps at the moment.</MessageBar>
          )}
        </GridView>
      </div>
      {/*
      <Routes>
        <Route path=":id" element={<UserSignInBlade {...props} />} />
        <Route path=":id/*" element={<UserSignInBlade {...props} />} />
      </Routes>
          */}
    </>
  );
};
