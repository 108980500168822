

const copyToClipboardFallback = (val: string) => {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
};

export const copyToClipboard = (text: string) => {
    if (navigator.clipboard === undefined || navigator.clipboard == null) {
        copyToClipboardFallback(text);
        return;
    }
    navigator.clipboard.writeText(text).then(
        function () { },
        function (err) {
            console.error("Could not copy text: ", err);
        },
    );
};