import { styled } from "@fluentui/react";
import { IInvitationsProps } from "./Invitations.types";
import { getStyles, IInvitationsStyleProps, IInvitationsStyles } from "./Invitations.styles";
import { InvitationsComponent } from "./Invitations.base";

/**
 * Invitations
 */
export const Invitations = styled<IInvitationsProps, IInvitationsStyleProps, IInvitationsStyles>(
  InvitationsComponent,
  getStyles,
);
