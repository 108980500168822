import i18next, { InitOptions, TFunction } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const backend: HttpBackendOptions = {
  loadPath: `/locales/{{lng}}/{{ns}}.json?mkt={{lng}}`,
  addPath: document.location.hostname === "localhost" ? `/report/{{lng}}/{{ns}}.json` : undefined,
  crossDomain: true,
  withCredentials: false,
  customHeaders: (() => {
    return {
      "Accept-Language": i18next.language ?? "en-US",
    };
  }) as any,
  queryStringParams: { v: "1.3.5" },
};

export const initializeIntl = (
  nameSpaces: string[],
  suggestedMarket?: string,
  initOptions?: InitOptions,
): Promise<TFunction> => {
  if (suggestedMarket) {
    localStorage.setItem("mkt", suggestedMarket);
  }
  const debug = document.location.hostname === "localhost";
  return i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      backend,
      fallbackLng: "en-US",
      debug,
      ns: nameSpaces,
      defaultNS: nameSpaces[0],
      nsSeparator: ":",
      keySeparator: ".",
      lng: suggestedMarket,
      supportedLngs: false,
      saveMissing: debug,
      returnEmptyString: true,
      load: "currentOnly",
      cleanCode: true,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: [
          "querystring",
          "sessionStorage",
          "localStorage",
          "cookie",
          "navigator",
          "htmlTag",
        ],
        lookupQuerystring: "mkt",
        lookupCookie: "market",
        lookupLocalStorage: "mkt",
        lookupSessionStorage: "mkt",
        lookupFromPathIndex: 0,
        caches: ["localStorage"],
        excludeCacheFor: ["cimode"],
        htmlTag: document.documentElement,
      },
      ...initOptions,
    });
};
