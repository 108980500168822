import {
  ConstrainMode,
  IColumn,
  IDetailsListProps,
  Selection,
  SelectionMode
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { InventoryService } from "../../InventoryService";
import { useCompactRow } from "../../components/CompactDetailsRow";
import { useMediaQueries } from "../../components/MediaQueries";
import { QueryBuilder } from "../../components/QueryBuilder";
import { GridViewDisplayMode } from "../../components/gridview/models/GridViewDisplayMode";
import { IGridViewCommand } from "../../components/gridview/models/IGridViewCommand";
import { IGridViewOptions } from "../../components/gridview/models/IGridViewOptions";
import { IDepartmentsProps } from "./Departments.types";
import { sortArrayByProperty } from "../../components/utils";
import { SearchCommandItem } from "./SearchCommandItem";

export interface IDepartmentsState {
  error?: string;
  options: IGridViewOptions;
  items?: any[];
  submitting: boolean;
  state?: string;
  targetOnly: boolean;
  pendingOnly: boolean;
  approving?: any;
  removing?: any;
  search?: string;
  modal?:boolean;
  item?:any;
}

export const useDepartments = (props: IDepartmentsProps) => {
  const { user, authService } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = React.useState<IDepartmentsState>({
    targetOnly: false,
    pendingOnly: false,
    options: {
      fetched: false,
      displayMode: GridViewDisplayMode.list,
      query: new QueryBuilder(),
    },
    modal:true,
    submitting: false,
    state: undefined,
  });
  const { mobile } = useMediaQueries();
  const [, setSelected] = React.useState<number>(0);

  const { error, options, items, submitting, approving, removing, search ,modal,item} = state;

  const getItems = async (force: boolean) => {
    try {
      const expires_in =
        user?.expires_in == undefined
          ? user?.profile.auth_time === undefined
            ? 0
            : user?.profile.auth_time + 3600 - Math.round(Date.now() / 1000)
          : user?.expires_in;
      if (expires_in < 25) {
        console.log(force);
        authService.login();
        return;
      }
      const payload = await InventoryService.getDepartments(user);

      const orderBy = options.query.get().orderBy();

      setState({
        ...state,
        error: undefined,
        submitting: false,
        options: {
          ...options,
          fetched: true,
        },
        items: orderBy === undefined || orderBy.length === 0 ? force ? payload : (state.items ?? []).concat(payload) : sortArrayByProperty(force ? payload : (state.items ?? []).concat(payload), orderBy[0].replace(" desc", "") as any, orderBy[0].indexOf(' desc') !== -1 ? "desc" : "asc")
      });
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        error: "error",
        submitting: false,
        state: undefined,
        options: {
          ...options,
          nextLink: undefined,
          fetched: true,
        },
        items: [],
      });
    }
  };

  

  React.useEffect(() => {
    state.items === undefined && getItems(true);
  }, []);

  const [selection] = React.useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        setSelected(selection.count);
      },
    }),
  );

  const onFetch = () => {
    options.query = options.query.skip(items?.length ?? 0);
    getItems(false);
  };



  const onDismissApprove = () => setState({ ...state, approving: undefined });

  const onDismissRemoval = () => setState({ ...state, removing: undefined });

  const columns: IColumn[] = mobile
    ? [
      {
        key: "name",
        name: t("Departments.Name", "Name") ?? "Name",
        fieldName: "name",
        minWidth: 40,
        data: {
          isSortable: true,
        },
      },
    ]
    : [
      {
        key: "name",
        name: t("Departments.Name", "Name") ,
        fieldName: "name",
        minWidth: 250,
        maxWidth: 350,
        data: {
          isSortable: true,
        },
      },
      // {
      //   key: "id",
      //   name: t("Departments.GridView.Columns.id", "ID") ?? "UserPrincipalName",
      //   fieldName: "id",
      //   minWidth: 250,
      //   maxWidth: 350,
      // },
    ];

  const onSort = (fieldName: string, desc: boolean) => {
    options.query = options.query.orderBy(
      ...[desc ? fieldName : `${fieldName} desc`],
    );
    getItems(true);
  };

  const results = state.pendingOnly ? items?.filter(x => (x.target ?? "") !== "" && !x.approved)
    .filter(x => !search || x.name?.toLowerCase().includes(search.toLowerCase()) || (x.name?.toLowerCase() ?? "").includes(search.toLowerCase()))
    : items?.filter((x: any) => !state.targetOnly || (x.target ?? "") !== "")
      .filter(x => !search || x.name?.toLowerCase().includes(search.toLowerCase()) || (x.name?.toLowerCase() ?? "").includes(search.toLowerCase()));

  const commands: IGridViewCommand[] = [
    {
      key: "refresh",
      text: t("Common.Refresh", "Refresh") ?? "",
      iconProps: {
        iconName: "Refresh",
      },
      buttonStyles: {
        root: {
          backgroundColor: "transparent",
        },
      },
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        setState({
          ...state,
          options: {
            ...options,
            fetched: undefined,
            nextLink: undefined,
          },
        });
        options.query = options.query.top(999);
        getItems(true);
      },
    },
  ];

  const onSearch = (newValue?: string) => {
    setState({ ...state, search: newValue });
  };

  const commandBarProps = () => {
    return {
      farItems: [
        {
          key: "search",
          name: "",
          onRender: SearchCommandItem(onSearch)
        }
      ],
      items: commands,
    };
  };

  const detailsListProps: IDetailsListProps | undefined =
    items === undefined
      ? undefined
      : {
        items: items,
        compact: true,
        constrainMode: ConstrainMode.horizontalConstrained,
        onRenderRow: useCompactRow,
        onItemInvoked:  (item: any) =>{
            navigate(`/departments/${item.id}`, {
              state: item,
            }),
            setState({ ...state, modal: false, item:item });
          }
      };

  const selectionMode = SelectionMode.multiple;

  const onCommonDismiss = (force?: boolean) => {
    if (
      (typeof force !== "boolean" && typeof force !== "undefined") ||
      !(force ?? false)
    ) {
      setState({
        ...state,
        error: undefined,
        submitting: false,
        state: undefined,
      });
    } else if (force ?? true) {
      options.query = options.query.skip(0).top(999);
      getItems(true);
    }
  };
  const onUserInvitationDismiss = onCommonDismiss;
  const onBulkDismiss = onCommonDismiss;
  const breadcrumb = [
    {
      key: "home",
      text: t("Common.Home", "Home"),
      href: "/",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/");
      },
    },
    {
      key: "Departments",
      text: t("Common.Departments", "Departments"),
    },
  ];

  return {
    selectionPreservedOnEmptyClick: true,
    error,
    onFetch,
    onSort,
    options,
    items: results,
    columns,
    detailsListProps,
    commandBarProps,
    selectionMode,
    selection,
    submitting,
    breadcrumb,
    onBulkDismiss,
    t,
    approving,
    onDismissApprove,
    onDismissRemoval,
    removing,
    onUserInvitationDismiss,
    modal,
    item
  };
};
