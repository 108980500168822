/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IColumn } from "@fluentui/react";
import { QueryBuilder } from "./QueryBuilder";
import { useCopyTo } from "./useCopyTo";

export const GridViewHelper = {
  isSorted: (column: IColumn, query: QueryBuilder) =>
    column.fieldName === undefined
      ? undefined
      : (query.get().orderBy() ?? []).filter(
        (x) => column.fieldName !== undefined && x.includes(column.fieldName),
      ).length > 0,
  isSortedDescending: (column: IColumn, query: QueryBuilder) =>
    column.fieldName === undefined
      ? undefined
      : (query.get().orderBy() ?? []).filter(
        (x) =>
          column.fieldName !== undefined &&
          x.includes(`${column.fieldName} desc`),
      ).length > 0,
};

export const meta = (name: string) => {
  const element = document.querySelectorAll(`meta[name=${name}]`)[0];
  return element?.getAttribute("content") ?? "";
};

export const metaExists = (name: string) => {
  const element = document.querySelectorAll(`meta[name=${name}]`)[0];
  return element !== undefined && element !== null;
};

function isValidHttpUrl(value: string) {
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const ServiceUris = {
  authority: () => meta("WWW-authenticate"),
  cdn: () => meta("WWW-cdn-uri"),
  account: () => meta("WWW-account"),
  admin: () => meta("WWW-admin"),
  home: () => meta("WWW-home"),
  locale: {
    base: () =>
      metaExists("WWW-i18n-base")
        ? isValidHttpUrl(meta("WWW-i18n-base"))
          ? meta("WWW-i18n-base")
          : `${cdn()}/locales`
        : `${cdn()}/locales`,
    report: () => meta("WWW-i18n-report"),
    ui: () =>
      metaExists("WWW-i18n-locale-picker-uri")
        ? meta("WWW-i18n-locale-picker-uri")
        : `${cdn()}/localepicker`,
  },
  feedback: () =>
    metaExists("WWW-feedback-uri")
      ? meta("WWW-feedback-uri")
      : `${cdn()}/webcomponents/feedback-form`,
};

export const cdn = () => ServiceUris.cdn();

export const getContainerStyleBasedOnResolution = () => {
  const screenWidth = window.screen.width;
  if (screenWidth < 1600) {
    return {
      panelWidth: "85%",
      navWidth: "25vw",
      contentWidth: "65vw",
    };
  } else {
    return {
      panelWidth: "62%",
      navWidth: "30vw",
      contentWidth: "70vw",
    };
  }
};

export const delay = async (ms?: number) =>
  await new Promise<void>((resolve) => setTimeout(() => resolve, ms ?? 2000));

export const mock = delay;

export const withQueryString = (uri: string, key: string, value: string) => {
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.includes("?") ? "&" : "?";
  if (uri.match(re) != null) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};




export const formReadOnly = (isReadOnly: boolean) =>
  isReadOnly
    ? {
      borderless: true,
      readOnly: true,
      ...useCopyTo(),
    }
    : undefined;

export const mapObjects = (a: any, b: any, props: string[]) => {
  props.forEach((x) => {
    a[x] = b[x];
  });
};

export const getObjectModel = (value: any) => {
  const obj: any = {};
  Object.keys(value)
    .filter((x) => x !== "_additionalData")
    .sort((x) => (x === "_id" ? -1 : 1))
    .forEach((x) => {
      obj[x.substring(x.startsWith("_") ? 1 : 0)] = value[x];
    });
  obj["additionalData"] = value._additionalData ?? value.additionalData ?? {};
  return obj;
};



type SortingOrder = 'asc' | 'desc';

export function sortArrayByProperty<T>(arr: T[], property: keyof T, order: SortingOrder): T[] {
  const sortedArray = [...arr];
  sortedArray.sort((a, b) => {
    const valueA = a[property];
    const valueB = b[property];

    if (order === 'asc') {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
    } else if (order === 'desc') {
      if (valueA > valueB) return -1;
      if (valueA < valueB) return 1;
    }

    return 0;
  });

  return sortedArray;
}