import { IGroupResourceProps } from "./Groups.types";
import { getGroupResourceClassNames } from "./Groups.styles";
import { IColumn } from "@fluentui/react";
import { useMediaQueries } from "../../components/MediaQueries";
import { IGridViewCommand } from "../../components/gridview/models/IGridViewCommand";
import React from "react";
import { IGridViewOptions } from "../../components/gridview/models/IGridViewOptions";
import { IContextualMenuItem } from "@fluentui/react";
import { GridViewDisplayMode } from "../../components/gridview/models/GridViewDisplayMode";
import { QueryBuilder } from "../../components/QueryBuilder";
import { useNavigate } from "react-router";
import { IDetailsListProps } from "@fluentui/react";
import { ConstrainMode } from "@fluentui/react";
import { useCompactRow } from "../../components/CompactDetailsRow";
import { authService } from "../../components/AuthService";
import { User } from "oidc-react";
import { useTranslation } from "react-i18next";
export interface IGroupsState {
  error?: string;
  options: IGridViewOptions;
  items?: any[];
  apps?: IContextualMenuItem;
  selectedItems?: any[]
  state?: string;
  modal?:boolean;
  item?:any;
  group?:any;
  prev?:any;
}

export const useGroupResource = (
  props: IGroupResourceProps,
) => {
  const { styles,item,api } = props;

  const [state, setState] = React.useState<IGroupsState>({
    options: {
      fetched: false,
      displayMode: GridViewDisplayMode.list,
      query: new QueryBuilder().top(20),
    },
  });
  const { classNames /* , subComponentStyles */ } =
    getGroupResourceClassNames(styles, {
      ...props,
    });
  const { mobile } = useMediaQueries();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const columns: IColumn[] = mobile
  ? [
    {
      key: "Groups",
      name: t("Groups.Groups","Groups"),
      fieldName: "Group name",
      data: {
        type: "isodate",
        isSortable: true,
      },
      minWidth: 275,
    },
  ]
  : [
    {
      key: "displayName",
      name: t("Groups.GroupName","Group name"),
      fieldName: "displayName",
      minWidth: 220,
      maxWidth: 220,
      data: {
        isSortable: true,
      },
      visible: true
    },
    {
      key: "createdDateTime",
      name: t("Groups.CreationDate"," Creation date"),
      fieldName: "createdDateTime",
      data: {
        type: "isodate",
        isSortable: true,
      },
      minWidth: 240,
      maxWidth: 240
    },
    {
      key: "appId",
      name: t("Groups.GroupId", "GroupId"),
      fieldName: "appId",
      minWidth: 240,
      maxWidth: 240
    },
  ].filter((x) => x.visible ?? true);
  const { error, options, items,modal,group,prev  } = state;


  const getItems = async (force: boolean) => {
    try {
      const allusers = item ? await api.api(`/servicePrincipals/${item.id}/memberOf`).get(): undefined
      console.log(force)
      setState({
        ...state,
        error: undefined,
        options: {
          ...options,
          fetched: true,
        },
        prev:item ? item:undefined,
        items: allusers ? allusers.value.filter((c:any) => c["@odata.type"] == "#microsoft.graph.group"):[]
      } );
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        error: "error",
        options: {
          ...options,
          fetched: true,
        },
        items: [],
      });
    } 
  };
  const breadcrumb = [
    {
      key: "Home",
      text: t("Common.Home","Home"),
      href: "/",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/");
      },
    },
    {
      key: "applications",
      text: t("Common.Apps","Apps"),
      href: "/applications",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/applications");
      },
    },
    {
      key: "Appselected",
      text: item ? item.displayName : "Applications"
    },
  ];
  React.startTransition(() => {
    state.items === undefined && getItems(true);
  });

  const onFetch = () => {
    options.query = options.query.skip(items?.length ?? 0);
    getItems(false);
  };
  const detailsListProps: IDetailsListProps | undefined =
    items === undefined
      ? undefined
      : {
        items: items,
        compact: true,
        constrainMode: ConstrainMode.horizontalConstrained,
        onRenderRow: useCompactRow,
        onItemInvoked: async (selected: any)  =>{
          setState({ ...state,modal:true,group:selected});
          navigate(`/groups/${selected?.id}`, {
            state: selected,
          }) 
          setState({ ...state,modal:true,group:selected});
        }
      };

  const commands: IGridViewCommand[] = [
    {
      key: 'refresh',
      text: t("Common.Refresh","Refresh"),
      ariaLabel: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      split: true,
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        getItems(true);
      }
    },
  ];

  return {
    classNames,
    item,
    columns,
    error,
    items,
    commands,
    options,
    onFetch,
    breadcrumb,
    detailsListProps,
    modal,
    group,
    prev,api,authService,User
    
  };
};
