import { ITheme, MessageBarType } from "@fluentui/react";

export interface INotificationItem {
  title: string;
  level: MessageBarType;
  description: string | JSX.Element;
  timestamp: Date;
}

export const notificationIcon = (item: INotificationItem) =>
({
  [MessageBarType.blocked]: "StatusErrorFull",
  [MessageBarType.error]: "StatusErrorFull",
  [MessageBarType.warning]: "WarningSolid",
  [MessageBarType.severeWarning]: "WarningSolid",
  [MessageBarType.info]: "InfoSolid",
  [MessageBarType.success]: "CompletedSolid",
}[item.level]);

export const notificationColor = (item: INotificationItem, theme?: ITheme) =>
({
  [MessageBarType.blocked]: theme?.palette.red,
  [MessageBarType.error]: theme?.palette.red,
  [MessageBarType.warning]: theme?.palette.yellow,
  [MessageBarType.severeWarning]: theme?.palette.yellow,
  [MessageBarType.info]: theme?.palette.themePrimary,
  [MessageBarType.success]: theme?.palette.green,
}[item.level]);
