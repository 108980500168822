import {
  Breadcrumb,
  CommandBar,
  IPanelProps,
  Panel,
  PanelType,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { copyToClipboard } from "../copyToClipboard";
import * as React from "react";
import { getBladePanelClassNames } from "./BladePanel.styles";
import { IBladePanelProps } from "./BladePanel.types";

export const BladePanelComponent: React.FunctionComponent<IBladePanelProps> = (
  props,
) => {
  const {
    styles,
    type,
    children,
    onDismiss,
    title,
    canPin = false,
    breadcrumbProps,
  } = props;
  const { classNames, subComponentStyles } = getBladePanelClassNames(styles, {
    ...props,
  });
  const panelProps: IPanelProps = {
    ...props.panelProps,
    type: type ?? PanelType.custom,
    isOpen: true,
    isBlocking: true,
    isFooterAtBottom: true,
    onDismiss,
  };

  const commands =
    canPin ?? true
      ? [
        {
          key: "pin",
          iconOnly: true,
          buttonStyles: subComponentStyles?.pinButton,
          iconProps: {
            iconName: "Pinned",
            styles: {
              root: {
                color: props.theme?.semanticColors.bodyText,
              },
            },
          },
          subMenuProps: {
            items: [
              {
                key: "pin",
                name: "Pin to dashboard",
                iconProps: {
                  iconName: "Pinned",
                },
              },
            ],
          },
        },
        {
          key: "other",
          text: "...",
          buttonStyles: subComponentStyles?.moreButton,
          subMenuProps: {
            items: [
              {
                key: "copytitle",
                name: "Copy title",
                iconProps: {
                  iconName: "Copy",
                },
                onClick: () => copyToClipboard(title),
              },
              {
                key: "copyurl",
                name: "Copy url",
                iconProps: {
                  iconName: "Copy",
                },
                onClick: () =>
                  copyToClipboard(
                    `${document.location.origin}${location.pathname}`,
                  ),
              },
              {
                key: "print",
                name: "Print",
                iconProps: {
                  iconName: "Print",
                },
              },
            ],
          },
        },
      ]
      : [
        {
          key: "other",
          iconProps: {
            iconName: "More",
          },
          buttonStyles: subComponentStyles?.moreButton,
          subMenuProps: {
            items: [
              {
                key: "copytitle",
                name: "Copy title",
                iconProps: {
                  iconName: "Copy",
                },
                onClick: () => copyToClipboard(title),
              },
              {
                key: "copyurl",
                name: "Copy url",
                iconProps: {
                  iconName: "Copy",
                },
                onClick: () =>
                  copyToClipboard(
                    `${document.location.origin}${location.pathname}`,
                  ),
              },
              {
                key: "print",
                name: "Print",
                iconProps: {
                  iconName: "Print",
                },
              },
            ],
          },
        },
      ];

  const onRenderHeader =
    breadcrumbProps === undefined
      ? title === undefined
        ? undefined
        : () => (
          <Stack
            grow
            horizontal
            verticalAlign="center"
            tokens={{ childrenGap: 10 }}
          >
            <div></div>
            <div>
              <Text variant="xLarge">{title}</Text>
            </div>
          </Stack>
        )
      : () => (
        <Stack grow horizontal verticalAlign="center">
          <Stack grow horizontal verticalAlign="center">
            <Breadcrumb
              onGrowData={() => undefined}
              onReduceData={() => undefined}
              {...breadcrumbProps}
              styles={subComponentStyles?.breadcrumb}
            />
            <StackItem>
              <Stack horizontal tokens={{ childrenGap: 5 }}>
                <CommandBar
                  onGrowData={() => undefined}
                  onReduceData={() => undefined}
                  styles={subComponentStyles?.commandBar}
                  items={commands}
                />
              </Stack>
            </StackItem>
            <StackItem grow>
              <div></div>
            </StackItem>
          </Stack>
        </Stack>
      );
  return (
    <Panel
      {...props}
      {...panelProps}
      styles={subComponentStyles?.panel}
      onRenderHeader={onRenderHeader}
      className={classNames.root}
    >
      {children}
    </Panel>
  );
};
