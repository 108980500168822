import {
  DatePicker,
  DefaultPalette,
  IDatePickerProps,
  Text,
} from "@fluentui/react";
import { FieldProps } from "formik";
import moment from "moment";
import { useCalendarStrings } from "./CalendarStrings";
import { FormikTextField } from "./FormikTextField";
import {
  createFakeEvent,
  getErrorMessage,
  invokeAll,
  handleProps,
  isReadOnly,
  Omit,
} from "./utils";

export function mapFieldToDatePicker<V extends Date = Date, FormValues = any>({
  form,
  field,
}: FieldProps<V, FormValues>): Pick<
  IDatePickerProps,
  "value" | "onSelectDate" | "onAfterMenuDismiss"
> {
  return {
    ...field,
    value: field.value
      ? typeof field.value === "string"
        ? moment(field.value).toDate()
        : field.value
      : field.value,
    onAfterMenuDismiss: () => field.onBlur(createFakeEvent(field)),
    onSelectDate: (date) => form.setFieldValue(field.name, date),
  };
}
export type FormikDatePickerProps<
  V extends Date = Date,
  FormValues = any,
> = Omit<IDatePickerProps, "value" | "onSelectDate" | "onBlur" | "onChange"> &
  FieldProps<V, FormValues>;

export function FormikDatePicker<V extends Date = Date, FormValues = any>({
  field,
  form,
  meta,
  ...props
}: FormikDatePickerProps<V, FormValues>) {
  const { onAfterMenuDismiss, ...fieldProps } = mapFieldToDatePicker({
    field,
    form,
    meta,
  });

  const readOnly = isReadOnly(field, form, props);
  const errorMessage = getErrorMessage({ field, form, meta });
  return readOnly ? (
    FormikTextField({
      ...handleProps(props),
      ...fieldProps,
      errorMessage: errorMessage,
      field: {
        ...field,
        readOnly: true,
        type: "date",
      },
      form,
      meta,
    } as any)
  ) : (
    <>
      <DatePicker
        {...handleProps(props)}
        formatDate={(date: Date) =>
          date ? (date instanceof Date ? date.toDateString() : date) : ""
        }
        strings={useCalendarStrings()}
        onAfterMenuDismiss={invokeAll(
          onAfterMenuDismiss,
          props.onAfterMenuDismiss,
        )}
        {...fieldProps}
      />
      {errorMessage !== undefined && (
        <div>
          <Text
            variant="small"
            styles={{ root: { color: DefaultPalette.redDark } }}>
            {errorMessage}
          </Text>
        </div>
      )}
    </>
  );
}
