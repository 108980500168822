import { Icon, Stack, StackItem } from "@fluentui/react";
import { useMediaQueries } from "../MediaQueries";
import * as React from "react";
import { PropertyBooleanField } from "./Field/PropertyBooleanField/PropertyBooleanField";
import { PropertyEnumField } from "./Field/PropertyEnumField/PropertyEnumField";
import { PropertyNumberField } from "./Field/PropertyNumberField/PropertyNumberField";
import { PropertyTextField } from "./Field/PropertyTextField";
import { getPropertyFieldClassNames } from "./PropertyField.styles";
import { IPropertyFieldProps } from "./PropertyField.types";

export const PropertyFieldComponent: React.FunctionComponent<
  IPropertyFieldProps
> = (props) => {
  const {
    styles,
    onRenderIcon,
    readOnly,
    schema,
    underlined,
    property,
    propertyIconProps,
  } = props;
  const { classNames, subComponentStyles } = getPropertyFieldClassNames(
    styles,
    {
      ...props,
    },
  );

  const { mobile } = useMediaQueries();

  const fields = {
    enum: () => (
      <PropertyEnumField
        {...props}
        required={
          schema?.required !== undefined &&
          schema.required.indexOf(property.key) !== -1
        }
        property={property}
        name={property.key}
        type={property.type}
        styles={{
          subComponentStyles: {
            field: subComponentStyles?.enum,
          },
        }}
      />
    ),
    integer: () => (
      <PropertyNumberField
        {...props}
        required={
          schema?.required !== undefined &&
          schema.required.indexOf(property.key) !== -1
        }
        styles={{
          subComponentStyles: {
            field: subComponentStyles?.number,
          },
        }}
        name={property.key}
      />
    ),
    number: () => (
      <PropertyNumberField
        {...props}
        underlined={underlined ?? !mobile}
        required={
          schema?.required !== undefined &&
          schema.required.indexOf(property.key) !== -1
        }
        styles={{
          subComponentStyles: {
            field: subComponentStyles?.number,
          },
        }}
        name={property.key}
      />
    ),
    boolean: () => <PropertyBooleanField {...props} name={property.key} />,
    string: () => (
      <PropertyTextField
        {...props}
        underlined={underlined ?? !mobile}
        required={
          schema?.required !== undefined &&
          schema.required.indexOf(property.key) !== -1
        }
        value={props.value}
        name={property.key}
        type={property.type}
        styles={{
          subComponentStyles: {
            field: subComponentStyles?.text,
          },
        }}
      />
    ),
  };
  const type =
    property.type === "boolean" && (readOnly === undefined || readOnly)
      ? "string"
      : property.enum !== undefined
        ? readOnly === undefined || readOnly
          ? "string"
          : "enum"
        : property.type;
  const field = ((fields as any)[type] ?? fields.string)();
  return (
    <Stack className={classNames.root} grow horizontal verticalAlign="center">
      <StackItem align="start" grow>
        {field}
      </StackItem>
      <StackItem align="end">
        {onRenderIcon === undefined ? (
          propertyIconProps === undefined ? (
            <></>
          ) : (
            <Icon {...propertyIconProps} />
          )
        ) : (
          onRenderIcon(props)
        )}
      </StackItem>
    </Stack>
  );
};
