import { DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import { useFormikContext } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";

interface IPanelCommandBarProps {
  text?: string;
  dismissText?: string;
  onDismiss?: () => void;
}

export const PanelCommandBar: React.FunctionComponent<
  IPanelCommandBarProps
> = ({ text, dismissText, onDismiss }) => {
  const { t } = useTranslation();
  const form = useFormikContext<any>();
  return (
    <Stack horizontal tokens={{ childrenGap: 10 }}>
      <PrimaryButton
        disabled={form.isSubmitting || form.isValidating}
        text={text ?? t("globals:Ok", "Ok") ?? "Ok"}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          form?.submitForm?.();
        }}
      />
      <DefaultButton
        disabled={form.isSubmitting || form.isValidating}
        text={dismissText ?? t("globals:Cancel", "Cancel") ?? "Cancel"}
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          onDismiss?.();
        }}
      />
    </Stack>
  );
};
