import { FormikHelpers } from "formik";
import { IUser } from "../../../../../../models/IUser";

export const useUserErrors = (e: any, formikHelpers: FormikHelpers<IUser>) => {
  const statusCode = JSON.parse(e.message)?.statusCode ?? 500;
  formikHelpers.setFieldError(
    "form",
    ({
      400: "User cannot be updated for the moment",
      401: "You do not have permissions to perform this action.",
      403: "You do not have permissions to perform this action.",
      500: "User cannot be updated for the moment",
    } as any)[statusCode],
  );
};
