import { styled } from "@fluentui/react";
import { IGridViewCommandBarProps } from "./GridViewCommandBar.types";
import {
  getStyles,
  IGridViewCommandBarStyleProps,
  IGridViewCommandBarStyles,
} from "./GridViewCommandBar.styles";
import { GridViewCommandBarComponent } from "./GridViewCommandBar.base";

/**
 * GridViewCommandBar
 */
export const GridViewCommandBar = styled<
  IGridViewCommandBarProps,
  IGridViewCommandBarStyleProps,
  IGridViewCommandBarStyles
>(GridViewCommandBarComponent, getStyles);
