import { ITheme, IIconProps } from "@fluentui/react";
import React from "react";
import { AppBar, IAppBarProps } from "./AppBar";

export interface IHeaderProps {
  theme?: ITheme;
  globalMenuOnly?: boolean;
  accountIconProps?: IIconProps;
  appBarProps?: IAppBarProps;
}

export const Header: React.FC<IHeaderProps> = ({
  appBarProps,
  theme,
  globalMenuOnly,
}) => (
  <header>
    {appBarProps != null && (
      <AppBar theme={theme} {...appBarProps} globalMenuOnly={globalMenuOnly} />
    )}
  </header>
);
