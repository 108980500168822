import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../components/base";

export interface ILandingSubComponentStyles {
  button?: IButtonStyles;
}

export interface ILandingTokens {
  ver?: string;
}

export interface ILandingStyles
  extends IComponentBaseStyles<ILandingSubComponentStyles> { }

export interface ILandingStyleProps
  extends IComponentBaseStyleProps<ILandingTokens> { }

export const getLandingClassNames = (
  styles?: IStyleFunctionOrObject<ILandingStyleProps, ILandingStyles>,
  props?: ILandingStyleProps,
): IComponentBaseClassNames<
  ILandingStyleProps,
  ILandingStyles,
  ILandingSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ILandingStyleProps,
    ILandingStyles,
    ILandingSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<ILandingStyleProps, ILandingStyles> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: ILandingStyleProps,
): ILandingStyles => {
  return {
    root: ["app-Landing", {}],
  };
};
