import { Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router";
import { BladePanel } from "../../../../components/BladePanel/BladePanel";
import { useMediaQueries } from "../../../../components/MediaQueries";
import { UserExport } from "./Controls/UserExport";
import { UserHeader } from "./Controls/UserHeader";
import { UserNavigation } from "./Controls/UserNavigation/UserNavigation";
import { UserOverview } from "./Controls/UserOverview/UserOverview";
import { UserProfile } from "./Controls/UserProfile";
import { UserSignIns } from "./Controls/UserSignIns";
import { getUserBladeClassNames } from "./UserBlade.styles";
import { IUserBladeProps } from "./UserBlade.types";
import { useUserBlade } from "./UserBlade.use";
import { UserSecurity } from "./Controls/UserSecurity";
import { UserApps } from "./Controls/UserApps";

export const UserBladeComponent: React.FunctionComponent<IUserBladeProps> = (
  props,
) => {
  const { styles } = props;
  const { classNames, subComponentStyles } = getUserBladeClassNames(styles, {
    ...props,
  });

  const {
    bladePanelProps,
    navigation,
    onNavigate,
    onUserChanged,
    user,
    onOptions,
    onToggleOptions,
  } = useUserBlade(props);
  const { mobile } = useMediaQueries();
  return (
    <>
      {user === undefined ? (
        <></>
      ) : (
        <BladePanel {...props} {...bladePanelProps} styles={undefined}>
          <div className={classNames.root}>
            <UserHeader
              {...props}
              selected={user}
              onOptions={onOptions}
              onToggleOptions={onToggleOptions}
              styles={subComponentStyles?.header}
            />
            <Stack grow horizontal={!mobile} tokens={{ childrenGap: 20 }}>
              <StackItem>
                <UserNavigation
                  {...props}
                  enabled={onOptions}
                  navigation={navigation ?? []}
                  onNavigate={onNavigate}
                  styles={subComponentStyles?.navigation}
                />
              </StackItem>
              <StackItem grow verticalFill>
                <Routes>
                  <Route
                    path="activities"
                    element={
                      <UserSignIns
                        {...props}
                        selected={user}
                      />
                    }
                  />
                  <Route
                    path="apps"
                    element={
                      <UserApps
                        {...props}
                        selected={user}
                      />
                    }
                  />
                  <Route
                    path="security"
                    element={
                      <UserSecurity
                        {...props}
                        selected={user}
                        styles={subComponentStyles?.profile}
                      />
                    }
                  />
                  <Route
                    path="profile"
                    element={
                      <UserProfile
                        {...props}
                        selected={user}
                        onUserChanged={onUserChanged}
                        styles={subComponentStyles?.profile}
                      />
                    }
                  />
                  <Route
                    path="export"
                    element={
                      <UserExport
                        {...props}
                        selected={user}
                        styles={undefined}
                      />
                    }
                  />
                  <Route
                    path=""
                    element={
                      <UserOverview
                        {...props}
                        selected={user}
                        styles={subComponentStyles?.overview}
                      />
                    }
                  />
                  <Route
                    path="overview"
                    element={
                      <UserOverview
                        {...props}
                        selected={user}
                        styles={subComponentStyles?.overview}
                      />
                    }
                  />
                  <Route path="*" element={<Navigate to="overview" />} />
                </Routes>
              </StackItem>
            </Stack>
          </div>
        </BladePanel>
      )}
    </>
  );
};
