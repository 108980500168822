import { IApplicationResourceProps } from "./ApplicationsResource.types";
import { getApplicationResourceClassNames } from "./ApplicationsResource.styles";


export const useApplicationResource = (
  props: IApplicationResourceProps,
) => {

  const { styles,item,api,user,authService } = props;
  const { classNames /* , subComponentStyles */ } =
    getApplicationResourceClassNames(styles, {
      ...props,
    });
  return {
    classNames,
    item,
    api,
    user,
    authService
    
    
  };
};
