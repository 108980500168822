import { Breadcrumb, MessageBar, MessageBarType, Text } from "@fluentui/react";
import * as React from "react";
import { Route, Routes } from "react-router";
import { GridView } from "../../components/gridview/GridView/GridView";
import { BulkInvitation } from "./Controls/BulkInvitation";
import { UserBlade } from "./Controls/UserBlade";
import { UserInvitationForm } from "./Controls/UserInvitationForm";
import { getUsersClassNames } from "./Users.styles";
import { IUsersProps } from "./Users.types";
import { useUsers } from "./Users.use";

export const UsersComponent: React.FunctionComponent<IUsersProps> = (props) => {
  const { styles } = props;
  const { classNames, subComponentStyles } = getUsersClassNames(styles, {
    ...props,
  });

  const {
    breadcrumb,
    selection,
    invitation,
    bulk,
    onBulkDismiss,
    onUserInvitationDismiss,
    error,
    t,
    ...gridProps
  } = useUsers(props);
  return (
    <>
      <div className={classNames.root}>
        {invitation && (
          <UserInvitationForm
            {...props}
            onDismiss={onUserInvitationDismiss}
            styles={undefined}
          />
        )}
        {bulk && (<BulkInvitation {...props} onDismiss={onBulkDismiss} styles={undefined} />)}
        <Breadcrumb onGrowData={() => undefined} items={breadcrumb} />
        {gridProps.items && <div><Text>{`${gridProps.items.length} utilisteurs`}</Text></div>}
        <GridView
          {...props}
          {...gridProps}
          selection={selection}
          styles={subComponentStyles?.grid}
        >
          {error !== undefined && (
            <MessageBar
              messageBarType={MessageBarType.error}
              styles={subComponentStyles?.error}
            >
              {t("Users.GridView.ErrorMessage", "We cannot display users at the moment.")}
            </MessageBar>
          )}
        </GridView>
      </div>
      <Routes>
        <Route path=":id" element={<UserBlade {...props} />} />
        <Route path=":id/*" element={<UserBlade {...props} />} />
      </Routes>
    </>
  );
};
