import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IComponentBaseStyleProps, IComponentBaseStyles, IComponentBaseClassNames, ComponentStylesLoader } from "../../../../components/base";

export interface IBulkInvitationSubComponentStyles {
  button?: IButtonStyles;
}

export interface IBulkInvitationTokens {
  ver?: string;
}

export interface IBulkInvitationStyles extends IComponentBaseStyles<IBulkInvitationSubComponentStyles> {

}

export interface IBulkInvitationStyleProps extends IComponentBaseStyleProps<IBulkInvitationTokens> {

}

export const getBulkInvitationClassNames = (
  styles?: IStyleFunctionOrObject<
    IBulkInvitationStyleProps,
    IBulkInvitationStyles
  >,
  props?: IBulkInvitationStyleProps
): IComponentBaseClassNames<
  IBulkInvitationStyleProps,
  IBulkInvitationStyles,
  IBulkInvitationSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IBulkInvitationStyleProps,
    IBulkInvitationStyles,
    IBulkInvitationSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IBulkInvitationStyleProps,
  IBulkInvitationStyles

> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IBulkInvitationStyleProps): IBulkInvitationStyles => {
    return {
      root: ['app-BulkInvitation', {

      }]
    };
  };
