import { FormikDropdown } from "../FormikDropdown";
import { Field } from "formik";
import { registerWidget } from "./registry";

export const optionFormField = (config: any, formikParams: any) => {
  const extraProps = config ?? {};
  if (formikParams.handleChange != null)
    extraProps["onChange"] = formikParams.handleChange;
  if (formikParams.readOnly != null)
    extraProps["readOnly"] = formikParams.readOnly;
  return (
    <Field
      {...extraProps}
      name={config.name}
      type={config.type}
      className="form-control"
      id={config.name}
      options={config.options}
      component={FormikDropdown}
    />
  );
};

registerWidget("option", optionFormField);
