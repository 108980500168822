import { IColumn } from "@fluentui/react";
import { QueryBuilder } from "../../QueryBuilder";
import { useColumn, useLinkColumn, useTextColumn } from "./GridView.renderers";
import { IGridViewProps } from "./GridView.types";
import { GridViewHelper } from "../../utils";

const getRenderer = (props: IGridViewProps, column: IColumn, index: number) => {
  const canInvoke = props?.detailsListProps?.onItemInvoked !== undefined;
  return canInvoke && index === 0
    ? useLinkColumn(props, column)
    : ((useColumn as any)[column.data?.type ?? "text"] ?? useTextColumn)?.(
      props,
      column,
    );
};

const useColumnClick = (
  props: IGridViewProps,
  column: IColumn,
  query: QueryBuilder,
) =>
  props.onSort !== undefined &&
    column.fieldName &&
    (column.data?.isSortable ?? true)
    ? (_ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
      props.onSort?.(
        column.fieldName ?? column.key,
        GridViewHelper.isSortedDescending(column, query) ?? false,
      );
    }
    : undefined;

export const useGridViewColumns = (props: IGridViewProps): IColumn[] => {
  const { columns, options } = props;
  const { query } = options;
  return columns.map((column, index) => {
    return {
      ...column,
      isFiltered:
        column.fieldName === undefined
          ? undefined
          : query.get().filter()?.includes(`${column.fieldName} `) ||
          query.get().filter()?.includes(`(${column.fieldName},`),
      onColumnClick: useColumnClick(props, column, query),
      isSorted: GridViewHelper.isSorted(column, query),
      isSortedDescending: GridViewHelper.isSortedDescending(column, query),
      onRender: column.onRender ?? getRenderer(props, column, index),
    };
  });
};
