import { Breadcrumb , MessageBar, MessageBarType, SelectionMode, Text } from "@fluentui/react";
import * as React from "react";
import { GridView } from "../../components/gridview/GridView/GridView";
import { getDepartmentsClassNames } from "./Departments.styles";
import { IDepartmentsProps } from "./Departments.types";
import { useDepartments } from "./Departments.use";
import { Routes,Route } from "react-router";
import { DepartmentResource } from "./DepartmentResource";

export const DepartmentsComponent: React.FunctionComponent<IDepartmentsProps> = (props) => {
  const { styles } = props;
  const { classNames, subComponentStyles } = getDepartmentsClassNames(styles, {
    ...props,
  });

  const {
    breadcrumb,
    selection,
    onBulkDismiss,
    onUserInvitationDismiss,
    error,
    t,
    approving,
    submitting,
    onDismissApprove,
    onDismissRemoval,
    removing,
    modal,
    item,
    ...gridProps
  } = useDepartments(props);
  const gridview = () => (
    <div className={classNames.root}>
      <Breadcrumb onGrowData={() => undefined} items={breadcrumb} />
      {gridProps.items && <div><Text>{`${gridProps.items.length} départements`}</Text></div>}
      <GridView
        {...props}
        {...gridProps}
        selection={selection}
        selectionMode={SelectionMode.single}
        styles={subComponentStyles?.grid}
      >
        {error !== undefined && (
          <MessageBar
            messageBarType={MessageBarType.error}
            styles={subComponentStyles?.error}
          >
            {t("Invitations.GridView.ErrorMessage", "We cannot display Invitations at the moment.")}
          </MessageBar>
        )}
      </GridView>
    </div>
  );

  return (
    <>
      {modal ? (
        gridview()
      ) : (
        <Routes>
          <Route
            path=":department/*"
            element={<DepartmentResource {...props} item={item} styles={undefined} />}
          />
          <Route
            path=":department/*"
            element={<DepartmentResource {...props} item={item} styles={undefined} />}
          />
          <Route path="" element={gridview()} />
        </Routes>
      )}
    </>
  );
};
