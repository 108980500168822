import {
  ConstrainMode,
  IColumn,
  IDetailsListProps,
  Selection,
  SelectionMode
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { InvitationService } from "../../InvitationService";
import { useCompactRow } from "../../components/CompactDetailsRow";
import { useMediaQueries } from "../../components/MediaQueries";
import { QueryBuilder } from "../../components/QueryBuilder";
import { GridViewDisplayMode } from "../../components/gridview/models/GridViewDisplayMode";
import { IGridViewCommand } from "../../components/gridview/models/IGridViewCommand";
import { IGridViewOptions } from "../../components/gridview/models/IGridViewOptions";
import { onRenderApprove } from "./ApproveColumn";
import { IInvitationsProps } from "./Invitations.types";
import { sortArrayByProperty } from "../../components/utils";
import { SearchCommandItem } from "./SearchCommandItem";
import { ExportCommand } from "../../components/ExportCommand";

export interface IInvitationsState {
  error?: string;
  options: IGridViewOptions;
  items?: any[];
  submitting: boolean;
  state?: string;
  targetOnly: boolean;
  pendingOnly: boolean;
  approving?: any;
  removing?: any;
  search?: string;
}

export const useInvitations = (props: IInvitationsProps) => {
  const { user, authService } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = React.useState<IInvitationsState>({
    targetOnly: false,
    pendingOnly: false,
    options: {
      fetched: false,
      displayMode: GridViewDisplayMode.list,
      query: new QueryBuilder(),
    },
    submitting: false,
    state: undefined,
  });
  const { mobile } = useMediaQueries();
  const [, setSelected] = React.useState<number>(0);

  const { error, options, items, submitting, approving, removing, search } = state;

  const getItems = async (force: boolean) => {
    try {
      const expires_in =
        user?.expires_in == undefined
          ? user?.profile.auth_time === undefined
            ? 0
            : user?.profile.auth_time + 3600 - Math.round(Date.now() / 1000)
          : user?.expires_in;
      if (expires_in < 25) {
        console.log(force);
        authService.login();
        return;
      }
      const payload = await InvitationService.get(user);
      const orderBy = options.query.get().orderBy();
      setState({
        ...state,
        error: undefined,
        submitting: false,
        options: {
          ...options,
          fetched: true,
        },
        items: orderBy === undefined || orderBy.length === 0 ? force ? payload : (state.items ?? []).concat(payload) : sortArrayByProperty(force ? payload : (state.items ?? []).concat(payload), orderBy[0].replace(" desc", "") as any, orderBy[0].indexOf(' desc') !== -1 ? "desc" : "asc")
      });
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        error: "error",
        submitting: false,
        state: undefined,
        options: {
          ...options,
          nextLink: undefined,
          fetched: true,
        },
        items: [],
      });
    }
  };

  React.useEffect(() => {
    state.items === undefined && getItems(true);
  }, []);

  const [selection] = React.useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        setSelected(selection.count);
      },
    }),
  );

  const onFetch = () => {
    options.query = options.query.skip(items?.length ?? 0);
    getItems(false);
  };

  const onApproveRequested = (item: any) => {
    item.target && !item.approved && setState({
      ...state, approving: item
    });
  }

  const onCancelInvitation = (item: any) => {
    item.target && setState({
      ...state,
      removing: item
    });
  }

  const onUpdateInvitation = (targetRemoval: boolean) => {
    const email = removing.email;
    if (email && removing.target) {
      setState({ ...state, submitting: true });
      if (targetRemoval) {
        InvitationService.remove(email, user).then(x => x && setState({
          ...state,
          removing: undefined,
          submitting: false,
          items: items?.map(x => x.email === email ? {
            email,
            approved: false,
          } : x)
        })).catch(() => setState({
          ...state,
          removing: undefined,
          submitting: false
        }));
      }
      else {
        InvitationService.unapprove(email, user).then(x => x && setState({
          ...state,
          removing: undefined,
          submitting: false,
          items: items?.map(x => x.email === removing.email ? { email, target: removing.target, approved: false } : x)
        })).catch(() => setState({
          ...state,
          removing: undefined,
          submitting: false
        }));
      }
    }
  };

  const onRepairRequested = (item: any) => {
    const payload = {
      firstName: item.email,
      lastName: "",
      email: item.email.toLowerCase(),
      roles: ["comsi"],
      company: null,
      enabled: true,
    };

    InvitationService.repair([payload], user);

  }

  const onApprove = (item: any) => {
    const email = item.email;
    if (email && !item.approved) {
      setState({ ...state, submitting: true });
      InvitationService.approve(email, user).then(x => x && setState({
        ...state,
        approving: undefined,
        submitting: false,
        items: items?.map(x => x.email === email ? {
          ...x,
          approved: true,
        } : x)
      })).catch(() => setState({
        ...state,
        removing: undefined,
        submitting: false
      }));
    }
  }

  const onDismissApprove = () => setState({ ...state, approving: undefined });

  const onDismissRemoval = () => setState({ ...state, removing: undefined });

  const columns: IColumn[] = mobile
    ? [
      {
        key: "name",
        name: t("Invitations.GridView.Columns.Name", "Name") ?? "Name",
        fieldName: "email",
        minWidth: 200,
        data: {
          isSortable: true,
        },
      },
    ]
    : [
      {
        key: "email",
        name: t("Invitations.GridView.Columns.Email", "Email") ?? "Email",
        fieldName: "email",
        minWidth: 250,
        maxWidth: 350,
        data: {
          isSortable: true,
        },
      },
      {
        key: "target",
        name: t("Invitations.GridView.Columns.UserPrincipalName", "UserPrincipalName") ?? "UserPrincipalName",
        fieldName: "target",
        minWidth: 250,
        maxWidth: 350,
      },
      {
        key: "approved",
        name: t("Invitations.GridView.Columns.Actions", "Actions") ?? "Actions",
        fieldName: "approved",
        minWidth: 175,
        maxWidth: 175,
        onRender: onRenderApprove(
          onRepairRequested,
          onApproveRequested,
          onCancelInvitation)
      },
      {
        key: "timestamp",
        name: t("Invitations.GridView.Columns.Timestamp", "Timestamp") ?? "Email",
        fieldName: "timestamp",
        minWidth: 250,
        maxWidth: 350,
        data: {
          isSortable: true,
        },
      },
    ];

  const onSort = (fieldName: string, desc: boolean) => {
    options.query = options.query.orderBy(
      ...[desc ? fieldName : `${fieldName} desc`],
    );
    getItems(true);
  };

  const results = state.pendingOnly ? items?.filter(x => (x.target ?? "") !== "" && !x.approved)
    .filter(x => !search || x.email?.toLowerCase().includes(search.toLowerCase()) || (x.target?.toLowerCase() ?? "").includes(search.toLowerCase()))
    : items?.filter((x: any) => !state.targetOnly || (x.target ?? "") !== "")
      .filter(x => !search || x.email?.toLowerCase().includes(search.toLowerCase()) || (x.target?.toLowerCase() ?? "").includes(search.toLowerCase()));

  const commands: IGridViewCommand[] = [
    {
      key: "invite",
      text: t("Invitations.Commands.Invite", "Invite User") ?? "",
      iconProps: {
        iconName: "Mail",
      },
      buttonStyles: {
        root: {
          backgroundColor: "transparent",
        },
      },
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        setState({ ...state, state: "invitation" });
      },
      subMenuProps: {
        items: [
          {
            key: "invite",
            text: t("Invitations.Commands.Invite", "Invite User") ?? "",
            iconProps: {
              iconName: "Mail",
            },
            buttonStyles: {
              root: {
                backgroundColor: "transparent",
              },
            },
            onClick: (ev) => {
              ev?.preventDefault();
              ev?.stopPropagation();
              setState({ ...state, state: "invitation" });
            },
          },

          {
            key: "bulk",
            text: t("Invitations.Commands.BulkInvite", "Bulk Invite") ?? "",
            iconProps: {
              iconName: "BulkUpload",
            },
            buttonStyles: {
              root: {
                backgroundColor: "transparent",
              },
            },
            onClick: (ev) => {
              ev?.preventDefault();
              ev?.stopPropagation();
              setState({ ...state, state: "bulk" });
            },
          }
        ]
      }
    },
    {
      key: "refresh",
      text: t("Invitations.Commands.Refresh", "Refresh") ?? "",
      iconProps: {
        iconName: "Refresh",
      },
      buttonStyles: {
        root: {
          backgroundColor: "transparent",
        },
      },
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        setState({
          ...state,
          options: {
            ...options,
            fetched: undefined,
            nextLink: undefined,
          },
        });
        options.query = options.query.top(999);
        getItems(true);
      },
    },
    {
      key: "show",
      text: state.targetOnly || state.pendingOnly ? t("Invitations.Commands.ShowAll", "Show All") ?? "" : t("Invitations.Commands.ShowConflicts", "Show only conflicts") ?? "",
      iconProps: {
        iconName: "Refresh",
      },
      buttonStyles: {
        root: {
          backgroundColor: "transparent",
        },
      },
      subMenuProps: state.targetOnly || state.pendingOnly ? undefined : {
        items: [
          {
            key: "show",
            text: t("Invitations.Commands.ShowConflicts", "Show all conflicts") ?? "",
            iconProps: {
              iconName: "Refresh",
            },
            buttonStyles: {
              root: {
                backgroundColor: "transparent",
              },
            },
            onClick: (ev) => {
              ev?.preventDefault();
              ev?.stopPropagation();
              setState({ ...state, pendingOnly: false, targetOnly: true });
            }
          },
          {
            key: "showpending",
            text: t("Invitations.Commands.ShowPending", "Show pending conflicts") ?? "",
            iconProps: {
              iconName: "Refresh",
            },
            buttonStyles: {
              root: {
                backgroundColor: "transparent",
              },
            },
            onClick: (ev) => {
              ev?.preventDefault();
              ev?.stopPropagation();
              setState({ ...state, targetOnly: false, pendingOnly: true, });
            }
          }
        ]
      },
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        setState({ ...state, pendingOnly: false, targetOnly: !state.targetOnly });
      }
    },
    ExportCommand(results, columns, selection, t),
  ];

  const onSearch = (newValue?: string) => {
    setState({ ...state, search: newValue });
  };

  const commandBarProps = () => {
    return {
      farItems: [
        {
          key: "search",
          name: "",
          onRender: SearchCommandItem(onSearch)
        }
      ],
      items: commands,
    };
  };

  const detailsListProps: IDetailsListProps | undefined =
    items === undefined
      ? undefined
      : {
        items: items,
        compact: true,
        constrainMode: ConstrainMode.horizontalConstrained,
        onRenderRow: useCompactRow,
      };

  const selectionMode = SelectionMode.multiple;

  const onCommonDismiss = (force?: boolean) => {
    if (
      (typeof force !== "boolean" && typeof force !== "undefined") ||
      !(force ?? false)
    ) {
      setState({
        ...state,
        error: undefined,
        submitting: false,
        state: undefined,
      });
    } else if (force ?? true) {
      options.query = options.query.skip(0).top(999);
      getItems(true);
    }
  };
  const onUserInvitationDismiss = onCommonDismiss;
  const onBulkDismiss = onCommonDismiss;
  const breadcrumb = [
    {
      key: "home",
      text: t("ApplicationMenu.HomeTitle", "Home"),
      href: "/",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/");
      },
    },
    {
      key: "Invitations",
      text: t("Invitations.Title", "Invitations"),
    },
  ];

  return {
    selectionPreservedOnEmptyClick: true,
    error,
    onFetch,
    onSort,
    options,
    items: results,
    columns,
    detailsListProps,
    commandBarProps,
    selectionMode,
    selection,
    submitting,
    invitation: state.state === "invitation",
    bulk: state.state === "bulk",
    breadcrumb,
    onBulkDismiss,
    t,
    approving,
    onApprove,
    onDismissApprove,
    onUpdateInvitation,
    onDismissRemoval,
    removing,
    onUserInvitationDismiss,
  };
};
