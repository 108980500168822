import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../components/base";

export interface IApplicationResourceSubComponentStyles {
  button?: IButtonStyles;
}

export interface IApplicationResourceTokens {
  ver?: string;
}

export interface IApplicationResourceStyles
  extends IComponentBaseStyles<IApplicationResourceSubComponentStyles> {}

export interface IApplicationResourceStyleProps
  extends IComponentBaseStyleProps<IApplicationResourceTokens> {}

export const getApplicationResourceClassNames = (
  styles?: IStyleFunctionOrObject<
    IApplicationResourceStyleProps,
    IApplicationResourceStyles
  >,
  props?: IApplicationResourceStyleProps,
): IComponentBaseClassNames<
  IApplicationResourceStyleProps,
  IApplicationResourceStyles,
  IApplicationResourceSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IApplicationResourceStyleProps,
    IApplicationResourceStyles,
    IApplicationResourceSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IApplicationResourceStyleProps,
  IApplicationResourceStyles
> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IApplicationResourceStyleProps,
): IApplicationResourceStyles => {
  return {
    root: ["app-ApplicationResource", {}],
  };
};
