/* eslint-disable @typescript-eslint/no-extraneous-class */
import {
  AzureCustomizationsDark,
  AzureCustomizationsLight,
  AzureThemeDark,
  AzureThemeLight,
} from "@fluentui/azure-themes";
import { ITheme } from "@fluentui/react";

export const stackTokens = { childrenGap: 10 };

export const defaultCardStyles = {
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    width: "23%",
    height: "140px",
    maxWidth: "500px",
    boxSizing: "border-box",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderRadius: "2px",
    boxShadow:
      "rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px",
    cursor: "pointer",
    minWidth: "unset",
    outline: "none",
    transition: "box-shadow 0.5s ease 0s",
    position: "relative",
    backgroundColor: "rgb(255, 255, 255)",
    float: "left",
    margin: "10px",
    padding: "10px",
    minHeight: "130px",
  },
} as any;

export const Theming = {
  light: (): ITheme => {
    return {
      ...AzureThemeLight,
      components: {
        ...AzureThemeLight.components,
        ...AzureCustomizationsLight.settings,
        TopBar: {
          styles: {
            root: {
              backgroundColor: AzureThemeLight.palette.themePrimary,
              color: "#fff",
            },
          },
        },
      },
      disableGlobalClassNames: false,
    };
  },
  dark: (): ITheme => {
    return {
      ...AzureThemeDark,
      semanticColors: {
        ...AzureThemeDark.semanticColors,
        bodyBackground: "#333",
      },
      components: {
        ...AzureThemeDark.components,
        ...AzureCustomizationsDark.settings,
        TopBar: {
          styles: {
            root: {
              backgroundColor: "#1c1c1c",
            },
          },
        },
      },
      disableGlobalClassNames: false,
    };
  },
  getTheme: (): ITheme => {
    const theme = localStorage.getItem("theme");
    return theme === null || theme === "light" || theme !== "dark"
      ? Theming.light()
      : Theming.dark();
  },
  getThemes: (): ITheme[] => {
    return [Theming.light(), Theming.dark()];
  },
};
