import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../../components/base";

export interface IOrganizationResourceSubComponentStyles {
  button?: IButtonStyles;
}

export interface IOrganizationResourceTokens {
  ver?: string;
}

export interface IOrganizationResourceStyles
  extends IComponentBaseStyles<IOrganizationResourceSubComponentStyles> {}

export interface IOrganizationResourceStyleProps
  extends IComponentBaseStyleProps<IOrganizationResourceTokens> {}

export const getOrganizationResourceClassNames = (
  styles?: IStyleFunctionOrObject<
    IOrganizationResourceStyleProps,
    IOrganizationResourceStyles
  >,
  props?: IOrganizationResourceStyleProps,
): IComponentBaseClassNames<
  IOrganizationResourceStyleProps,
  IOrganizationResourceStyles,
  IOrganizationResourceSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IOrganizationResourceStyleProps,
    IOrganizationResourceStyles,
    IOrganizationResourceSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IOrganizationResourceStyleProps,
  IOrganizationResourceStyles
> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IOrganizationResourceStyleProps,
): IOrganizationResourceStyles => {
  return {
    root: ["app-OrganizationResource", {}],
  };
};
