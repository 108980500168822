import { styled } from "@fluentui/react";
import { IBladePanelProps } from "./BladePanel.types";
import {
  getStyles,
  IBladePanelStyleProps,
  IBladePanelStyles,
} from "./BladePanel.styles";
import { BladePanelComponent } from "./BladePanel.base";

/**
 * BladePanel
 */
export const BladePanel = styled<
  IBladePanelProps,
  IBladePanelStyleProps,
  IBladePanelStyles
>(BladePanelComponent, getStyles);
