import { styled } from "@fluentui/react/lib/Utilities";
import { IGroupResourceProps } from "./GroupResource.types";
import {
  getStyles,
  IGroupResourceStyleProps,
  IGroupResourceStyles,
} from "./GroupResource.styles";
import { GroupResourceComponent } from "./GroupResource.base";

/**
 * GroupResource
 */
export const GroupResource = styled<
  IGroupResourceProps,
  IGroupResourceStyleProps,
  IGroupResourceStyles
>(GroupResourceComponent, getStyles);
