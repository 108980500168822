import { styled } from "@fluentui/react";
import { IUserPersonaProps } from "./UserPersona.types";
import {
  getStyles,
  IUserPersonaStyleProps,
  IUserPersonaStyles,
} from "./UserPersona.styles";
import { UserPersonaComponent } from "./UserPersona.base";

/**
 * UserPersona
 */
export const UserPersona = styled<
  IUserPersonaProps,
  IUserPersonaStyleProps,
  IUserPersonaStyles
>(UserPersonaComponent, getStyles);
