import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../components/base";

export interface IGroupResourceSubComponentStyles {
  button?: IButtonStyles;
}

export interface IGroupResourceTokens {
  ver?: string;
}

export interface IGroupResourceStyles
  extends IComponentBaseStyles<IGroupResourceSubComponentStyles> {}

export interface IGroupResourceStyleProps
  extends IComponentBaseStyleProps<IGroupResourceTokens> {}

export const getGroupResourceClassNames = (
  styles?: IStyleFunctionOrObject<
    IGroupResourceStyleProps,
    IGroupResourceStyles
  >,
  props?: IGroupResourceStyleProps,
): IComponentBaseClassNames<
  IGroupResourceStyleProps,
  IGroupResourceStyles,
  IGroupResourceSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IGroupResourceStyleProps,
    IGroupResourceStyles,
    IGroupResourceSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IGroupResourceStyleProps,
  IGroupResourceStyles
> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IGroupResourceStyleProps,
): IGroupResourceStyles => {
  return {
    root: ["app-GroupResource", {}],
  };
};
