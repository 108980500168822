import {
  IButtonStyles,
  IDialogFooterStyles,
  IDialogStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../base";

export interface IGridViewConfirmDialogSubComponentStyles {
  button?: IButtonStyles;
  dialog?: Partial<IDialogStyles>;
  footer?: Partial<IDialogFooterStyles>;
}

export interface IGridViewConfirmDialogTokens {
  ver?: string;
}

export interface IGridViewConfirmDialogStyles
  extends IComponentBaseStyles<IGridViewConfirmDialogSubComponentStyles> { }

export interface IGridViewConfirmDialogStyleProps
  extends IComponentBaseStyleProps<IGridViewConfirmDialogTokens> { }

export const getGridViewConfirmDialogClassNames = (
  styles?: IStyleFunctionOrObject<
    IGridViewConfirmDialogStyleProps,
    IGridViewConfirmDialogStyles
  >,
  props?: IGridViewConfirmDialogStyleProps,
): IComponentBaseClassNames<
  IGridViewConfirmDialogStyleProps,
  IGridViewConfirmDialogStyles,
  IGridViewConfirmDialogSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IGridViewConfirmDialogStyleProps,
    IGridViewConfirmDialogStyles,
    IGridViewConfirmDialogSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IGridViewConfirmDialogStyleProps,
  IGridViewConfirmDialogStyles
> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IGridViewConfirmDialogStyleProps,
  ): IGridViewConfirmDialogStyles => {
    return {
      root: ["app-GridViewConfirmDialog", {}],
    };
  };
