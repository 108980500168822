import { Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import { IApplicationResourceProps } from "./ApplicationsResource.types";
import { useApplicationResource } from "./ApplicationsResource.use";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Group } from "../../Groups/Groups";
import { Users } from "../../Users/Users";
export const ApplicationResourceComponent: React.FunctionComponent<
  IApplicationResourceProps
> = (props) => {
  const { classNames, item, ...gridProps} = useApplicationResource(props);
  console.log(item)
  const { t } = useTranslation();
  const navigate = useNavigate();

  item ?? navigate("/applications")
  const gridview =() => (
    <div className={classNames.root}>
      <Pivot>
        <PivotItem key={"org"} headerText={t("Applications.Group", "Group")}>
        <Group  item={item} { ...gridProps}></Group>
        </PivotItem>
        <PivotItem key={"org"} headerText={t("Applications.Users", "Users")}>
         <Users external={item} { ...gridProps}></Users>
        </PivotItem>
      </Pivot>
    </div>
  );

  return item ? gridview() : <div></div>
};
