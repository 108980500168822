import { styled } from "@fluentui/react";
import { IPropertyNumberFieldProps } from "./PropertyNumberField.types";
import {
  getStyles,
  IPropertyNumberFieldStyleProps,
  IPropertyNumberFieldStyles,
} from "./PropertyNumberField.styles";
import { PropertyNumberFieldComponent } from "./PropertyNumberField.base";

/**
 * PropertyNumberField
 */
export const PropertyNumberField = styled<
  IPropertyNumberFieldProps,
  IPropertyNumberFieldStyleProps,
  IPropertyNumberFieldStyles
>(PropertyNumberFieldComponent, getStyles);
