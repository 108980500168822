import { Stack, Text } from "@fluentui/react";
import { MediaQueries } from "./components/MediaQueries";
import * as React from "react";
import { useLocation } from "react-router";

export interface IError404Props {
  children?: any;
}

export const Error404: React.FunctionComponent<IError404Props> = ({
  children,
}) => {
  const location = useLocation();
  return (
    <Stack
      title={location.pathname}
      grow
      verticalFill
      styles={{
        root: {
          height: "calc(-44px + 100vh)",
          [MediaQueries.mobile]: {
            marginLeft: "10px",
          },
        },
      }}
      verticalAlign="center"
      horizontalAlign="center"
    >
      <div>
        <Stack tokens={{ childrenGap: 10 }} verticalFill>
          <Text
            variant="xxLargePlus"
            styles={{
              root: {
                fontSize: "84px",
                [MediaQueries.tablet]: {
                  fontSize: "72px",
                },
                [MediaQueries.mobile]: {
                  fontSize: "56px",
                  marginLeft: "5px",
                },
              },
            }}
          >
            :(
          </Text>
          <Text variant="xLarge">
            We could not find the page you are looking for.
          </Text>
        </Stack>
      </div>
      {children}
    </Stack>
  );
};
