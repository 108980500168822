import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IComponentBaseStyleProps, IComponentBaseStyles, IComponentBaseClassNames, ComponentStylesLoader } from "../../../../../../components/base";

export interface IStatusIndicatorSubComponentStyles {
  button?: IButtonStyles;
}

export interface IStatusIndicatorTokens {
  ver?: string;
}

export interface IStatusIndicatorStyles extends IComponentBaseStyles<IStatusIndicatorSubComponentStyles> {

}

export interface IStatusIndicatorStyleProps extends IComponentBaseStyleProps<IStatusIndicatorTokens> {

}

export const getStatusIndicatorClassNames = (
  styles?: IStyleFunctionOrObject<
    IStatusIndicatorStyleProps,
    IStatusIndicatorStyles
  >,
  props?: IStatusIndicatorStyleProps
): IComponentBaseClassNames<
  IStatusIndicatorStyleProps,
  IStatusIndicatorStyles,
  IStatusIndicatorSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IStatusIndicatorStyleProps,
    IStatusIndicatorStyles,
    IStatusIndicatorSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IStatusIndicatorStyleProps,
  IStatusIndicatorStyles

> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IStatusIndicatorStyleProps): IStatusIndicatorStyles => {
    return {
      root: ['app-StatusIndicator', {
        position: "relative"
      } as any]
    };
  };
