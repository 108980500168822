import {
  IButtonStyles,
  IIconStyles,
  IStackStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
  ITextFieldStyles,
} from "@fluentui/react";
import {
  ComponentStylesLoader,
  IComponentBaseClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
} from "../../../base";

export interface IPropertyTextFieldSubComponentStyles {
  button?: IButtonStyles;
  field?: Partial<ITextFieldStyles>;
  label?: Partial<IStackStyles>;
  suffix?: IIconStyles;
}

export interface IPropertyTextFieldTokens {
  ver?: string;
}

export interface IPropertyTextFieldStyles
  extends IComponentBaseStyles<IPropertyTextFieldSubComponentStyles> { }

export interface IPropertyTextFieldStyleProps
  extends IComponentBaseStyleProps<IPropertyTextFieldTokens> {
  underlined?: boolean;
  readOnly?: boolean;
}

export const getPropertyTextFieldClassNames = (
  styles?: IStyleFunctionOrObject<
    IPropertyTextFieldStyleProps,
    IPropertyTextFieldStyles
  >,
  props?: IPropertyTextFieldStyleProps,
): IComponentBaseClassNames<
  IPropertyTextFieldStyleProps,
  IPropertyTextFieldStyles,
  IPropertyTextFieldSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IPropertyTextFieldStyleProps,
    IPropertyTextFieldStyles,
    IPropertyTextFieldSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IPropertyTextFieldStyleProps,
  IPropertyTextFieldStyles
> = (props: IPropertyTextFieldStyleProps): IPropertyTextFieldStyles => {
  return {
    root: ["app-PropertyTextField", {}],
    subComponentStyles: {
      field: {
        fieldGroup: {
          height: "unset",
          minHeight: "24px",
        },
      },
      label: {
        root: {
          height: "unset",
          minHeight: "32px",
          width: "225px",
          padding:
            props.readOnly === undefined || (props.underlined ?? props.readOnly)
              ? "5px 0px 12px 12px"
              : "5px 0px",
        },
      },
      suffix: {
        root: {
          fontSize: "16px",
          marginTop: "2px",
          cursor: "pointer",
        },
      },
    },
  };
};
