import { Text, IToggleProps, Toggle, DefaultPalette } from "@fluentui/react";
import { FieldProps } from "formik";
import { FormikTextField } from "./FormikTextField";
import {
  createFakeEvent,
  getErrorMessage,
  handleProps,
  isReadOnly,
  Omit,
} from "./utils";

export function mapFieldToToggle<V extends boolean, FormValues = any>({
  form,
  field,
}: FieldProps<V, FormValues>): Pick<IToggleProps, "checked" | "onChange"> {
  const shared = {
    readOnly: (field as any).readOnly ?? form?.status?.readOnly,
  };
  return {
    ...field,
    ...shared,
    onChange: (_, checked) => {
      form.setFieldValue(field.name, checked);
      field.onBlur(createFakeEvent(field));
    },
    checked: field.value,
  };
}

export type FormikToggleProps<V = any, FormValues = any> = Omit<
  IToggleProps,
  "checked" | "onChange" | "onBlur"
> &
  FieldProps<V, FormValues>;

export function FormikToggle<V extends boolean, FormValues = any>({
  field,
  form,
  meta,
  ...props
}: FormikToggleProps<V, FormValues>) {
  const fieldProps = mapFieldToToggle({ field, form, meta });
  const { theme } = props;
  const readOnly = isReadOnly(field, form, props);
  const errorMessage = getErrorMessage({ form, field, meta });
  return readOnly ? (
    FormikTextField({
      ...handleProps(props),
      ...fieldProps,
      errorMessage: errorMessage,
      field: field,
      form,
      meta,
    } as any)
  ) : (
    <>
      <Toggle {...handleProps(props)} {...fieldProps} />
      {errorMessage !== undefined && (
        <div>
          <Text
            variant="small"
            styles={{
              root: { color: theme?.palette.redDark ?? DefaultPalette.redDark },
            }}>
            {errorMessage}
          </Text>
        </div>
      )}
    </>
  );
}
