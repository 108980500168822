import { Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import { IGroupResourceProps } from "./GroupResource.types";
import { useGroupResource } from "./GroupResource.use";
import { useTranslation } from "react-i18next";
import { Users } from "../../Users/Users";
import { useLocation } from "react-router-dom";

export const GroupResourceComponent: React.FunctionComponent<
  IGroupResourceProps
> = (props) => {
  const location = useLocation();
  const stateData = location.state;
  const { classNames,item, ...gridprops} = useGroupResource(props);
  const { t } = useTranslation();
  console.log(props)
  const gridview =() => (
    <div className={classNames.root}>
      <Pivot>
        <PivotItem key={"org"} headerText={t("Common.Users", "Users")}>
          <Users external={stateData}  {...gridprops}></Users>
        </PivotItem>
      </Pivot>
    </div>
  );

  return gridview()
};
