export interface IInjector {
  use: <T>(name: string) => T;
}

export class Injector implements IInjector {
  private elements: any = {};
  constructor() {
    this.elements = {};
  }

  public with<T>(name: string, provider: T): Injector {
    this.elements[name] = provider;
    return this;
  }

  public use<T>(name: string): T {
    return this.elements[name] as T;
  }
}
