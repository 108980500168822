import i18next from "i18next";
import * as React from "react";
import { useEvent } from "./useEvent";

interface ILocaleHostProps {
  onLocaleChanged?: (locale: string) => void;
  children?: any;
}

export const LocaleHost: React.FunctionComponent<ILocaleHostProps> = ({
  children,
  onLocaleChanged,
}) => {
  const [mkt, setMkt] = React.useState({ mkt: i18next.language });
  useEvent("languagechanged", () => {
    setMkt({ mkt: i18next.language ?? mkt });
    onLocaleChanged && onLocaleChanged(i18next.language ?? mkt);
  });
  return <>{children}</>;
};
