import { IDatePickerStrings } from "@fluentui/react";
import { useTranslation } from "react-i18next";

export const useCalendarStrings = (): IDatePickerStrings => {
  const { t } = useTranslation();

  const label = (key: string, defaultValue?: string): string => t(key, defaultValue ?? "") as any ?? defaultValue;
  return {
    /**
     * Error message to render for TextField if isRequired validation fails.
     */
    isRequiredErrorMessage: label(
      "calendars:isRequiredErrorMessage",
      "Date is required",
    ),
    /**
     * Error message to render for TextField if input date string parsing fails.
     */
    invalidInputErrorMessage: label(
      "calendars:invalidInputErrorMessage",
      "Invalid date",
    ),
    /**
     * Error message to render for TextField if date boundary (minDate, maxDate) validation fails.
     */
    isOutOfBoundsErrorMessage: label("calendars:isOutOfBoundsErrorMessage", ""),
    /**
     * Status message to render for TextField the input date parsing fails,
     * and the typed value is cleared and reset to the previous value.
     *  e.g. "Invalid entry `{0}`, date reset to `{1}`"
     */
    isResetStatusMessage: label("calendars:isResetStatusMessage", ""),

    months: [
      label("calendars:months.january.long", "january"),
      label("calendars:months.february.long", "february"),
      label("calendars:months.march.long", "march"),
      label("calendars:months.april.long", "april"),
      label("calendars:months.may.long", "may"),
      label("calendars:months.june.long", "june"),
      label("calendars:months.july.long", "july"),
      label("calendars:months.august.long", "august"),
      label("calendars:months.september.long", "september"),
      label("calendars:months.october.long", "october"),
      label("calendars:months.november.long", "november"),
      label("calendars:months.december.long", "december"),
    ],
    shortMonths: [
      label("calendars:months.january.short", "jan"),
      label("calendars:months.february.short", "feb"),
      label("calendars:months.march.short", "mar"),
      label("calendars:months.april.short", "apr"),
      label("calendars:months.may.short", "may"),
      label("calendars:months.june.short", "jun"),
      label("calendars:months.july.short", "jul"),
      label("calendars:months.august.short", "aug"),
      label("calendars:months.september.short", "sep"),
      label("calendars:months.october.short", "oct"),
      label("calendars:months.november.short", "nov"),
      label("calendars:months.december.short", "dec"),
    ],
    days: [
      label("calendars:days.sunday.long", "sunday"),
      label("calendars:days.monday.long", "monday"),
      label("calendars:days.tuesday.long", "tuesday"),
      label("calendars:days.wednesday.long", "wednesday"),
      label("calendars:days.thursday.long", "thursday"),
      label("calendars:days.friday.long", "friday"),
      label("calendars:days.saturday.long", "saturday"),
    ],
    shortDays: [
      label("calendars:days.sunday:short", "sun"),
      label("calendars:days.monday:short", "mon"),
      label("calendars:days.tuesday:short", "tue"),
      label("calendars:days.wednesday:short", "wed"),
      label("calendars:days.thursday:short", "thu"),
      label("calendars:days.friday:short", "fri"),
      label("calendars:days.saturday:short", "sat"),
    ],
    goToToday: label("calendars:today"),
    weekNumberFormatString: label("calendars:weeks", "weeks"),
    prevMonthAriaLabel: label("calendars:previousmonth", "previous month"),
    nextMonthAriaLabel: label("calendars:nextmonth", "next month"),
    prevYearAriaLabel: label("calendars:previousyear", "previous year"),
    nextYearAriaLabel: label("calendars:nextyear", "next year"),
    prevYearRangeAriaLabel: label(
      "calendars:previousyearrange",
      "previous year range",
    ),
    nextYearRangeAriaLabel: label("calendars:nextyearrange", "next year range"),
    closeButtonAriaLabel: label("calendars:close", "close"),
  };
};
