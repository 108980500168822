import {
  ITheme,
  mergeStyles,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import { useFormikContext } from "formik";
import * as React from "react";

interface IErrorMessagesProps {
  theme?: ITheme;
  style?: any;
  children?: any;
}

export const ErrorMessages: React.FunctionComponent<IErrorMessagesProps> = ({
  theme,
  style,
  children,
}) => {
  const containerClassName = mergeStyles(style ?? { margin: "15px 25px" });
  const form = useFormikContext<any>();
  return (
    <>
      {form.errors?.form === undefined ? (
        <div style={{ margin: "15px 25px", height: "34px" }}></div>
      ) : (
        <div className={containerClassName}>
          <MessageBar theme={theme} messageBarType={MessageBarType.error}>
            <>
              {form.errors.form}
              {children}
            </>
          </MessageBar>
        </div>
      )}
    </>
  );
};
