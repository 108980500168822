import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IComponentBaseStyleProps, IComponentBaseStyles, IComponentBaseClassNames, ComponentStylesLoader } from "../../components/base";

export interface INotificationsSubComponentStyles {
  button?: IButtonStyles;
}

export interface INotificationsTokens {
  ver?: string;
}

export interface INotificationsStyles extends IComponentBaseStyles<INotificationsSubComponentStyles> {

}

export interface INotificationsStyleProps extends IComponentBaseStyleProps<INotificationsTokens> {

}

export const getNotificationsClassNames = (
  styles?: IStyleFunctionOrObject<
    INotificationsStyleProps,
    INotificationsStyles
  >,
  props?: INotificationsStyleProps
): IComponentBaseClassNames<
  INotificationsStyleProps,
  INotificationsStyles,
  INotificationsSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    INotificationsStyleProps,
    INotificationsStyles,
    INotificationsSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  INotificationsStyleProps,
  INotificationsStyles

> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: INotificationsStyleProps): INotificationsStyles => {
    return {
      root: ['app-Notifications', {

      }]
    };
  };
