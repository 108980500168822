import { styled } from "@fluentui/react";
import { IGridViewConfirmDialogProps } from "./GridViewConfirmDialog.types";
import {
  getStyles,
  IGridViewConfirmDialogStyleProps,
  IGridViewConfirmDialogStyles,
} from "./GridViewConfirmDialog.styles";
import { GridViewConfirmDialogComponent } from "./GridViewConfirmDialog.base";

/**
 * GridViewConfirmDialog
 */
export const GridViewConfirmDialog = styled<
  IGridViewConfirmDialogProps,
  IGridViewConfirmDialogStyleProps,
  IGridViewConfirmDialogStyles
>(GridViewConfirmDialogComponent, getStyles);
