import { IColumn } from "@fluentui/react";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { copyToClipboard } from "../components/copyToClipboard";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const exportToCSV = (csvData: any[], fileName: string) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

function download(filename: string, text: string) {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text),
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const exporter = {
  xlsx: (items: any[], columns: IColumn[]) => {
    const names = columns.map((x) => x.fieldName);
    const filtered = items.map((item) =>
      Object.keys(item)
        .filter((key) => names.includes(key))
        .reduce((obj, key) => {
          (obj as any)[key] = item[key];
          return obj;
        }, {}),
    );
    exportToCSV(filtered, "export");
  },
  json: (
    items: any[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _: IColumn[],
  ) => {
    download("export.json", JSON.stringify(items, null, 2));
  },
  clipboard: (
    items: any[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _: IColumn[],
  ) => {
    copyToClipboard(JSON.stringify(items, null, 2));
  },
  list: (
    items: any[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _: IColumn[],
  ) => {
    copyToClipboard(
      items.map((x) => x.displayName ? `${x.displayName} ${x.email}` : x.email).join("\n"),
    );
  },
};

export const ExportService = {
  export: async (items: any[], columns: IColumn[], format: string) => {
    if (items && columns && format) {
      (exporter as any)[format](items, columns);
    }
  },
};
