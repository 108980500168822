import {
  DefaultPalette,
  IButtonStyles,
  ICommandBarStyles,
  IStackStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
  ITextStyles,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../base";
import { MediaQueries } from "../../MediaQueries";

export interface IGridViewCommandBarSubComponentStyles {
  button?: IButtonStyles;
  commandBar?: Partial<ICommandBarStyles>;
  stack?: Partial<IStackStyles>;
  text?: Partial<ITextStyles>;
}

export interface IGridViewCommandBarTokens {
  ver?: string;
}

export interface IGridViewCommandBarStyles
  extends IComponentBaseStyles<IGridViewCommandBarSubComponentStyles> { }

export interface IGridViewCommandBarStyleProps
  extends IComponentBaseStyleProps<IGridViewCommandBarTokens> { }

export const getGridViewCommandBarClassNames = (
  styles?: IStyleFunctionOrObject<
    IGridViewCommandBarStyleProps,
    IGridViewCommandBarStyles
  >,
  props?: IGridViewCommandBarStyleProps,
): IComponentBaseClassNames<
  IGridViewCommandBarStyleProps,
  IGridViewCommandBarStyles,
  IGridViewCommandBarSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IGridViewCommandBarStyleProps,
    IGridViewCommandBarStyles,
    IGridViewCommandBarSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IGridViewCommandBarStyleProps,
  IGridViewCommandBarStyles
> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IGridViewCommandBarStyleProps): IGridViewCommandBarStyles => {
    return {
      root: [
        "app-GridViewCommandBar",
        {
          backgroundColor: "#f8f8f8",
        },
      ],
      subComponentStyles: {
        commandBar: {
          root: {
            marginTop: "3px",
            marginRight: "10px",
            backgroundColor: "#f8f8f8",
            height: "26px",
            lineHeight: "26px",
            padding: "3px 0px 3px 0px",
            marginBottom: "0px",
            [MediaQueries.mobile]: {
              borderBottom: "1px solid rgb(182 182 182 / 75%)",
              marginTop: "2px",
            },
            ".ms-CommandBarItem-link": {
              backgroundColor: "#f8f8f8",
              ":hover": {
                backgroundColor: DefaultPalette.neutralLight,
              },
            },
            ".ms-Button-icon": {
              fontSize: "14px",
              lineHeight: "14px",
              height: "14px",
            },
          },
        },
        stack: {
          root: {
            margin: "35px",
            [MediaQueries.mobile]: {
              margin: "10px",
            },
          },
        },
        text: { root: { fontWeight: 600, maxWidth: "300px" } },
      },
    };
  };
