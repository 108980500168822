import { MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import { GridView } from "../../../../../../components/gridview/GridView/GridView";
import { IUserSignInsProps } from "./UserSignIns.types";
import { useUserSignIns } from "./UserSignIns.use";
//import { Route, Routes } from "react-router";
//import { UserSignInBlade } from "./Controls/UserSignInBlade/UserSignInBlade";

export const UserSignInsComponent: React.FunctionComponent<
  IUserSignInsProps
> = (props) => {
  const { error, ...gridProps } = useUserSignIns(props);
  return (
    <>
      <div>
        <GridView {...props} {...gridProps}>
          {error !== undefined && (
            <MessageBar messageBarType={MessageBarType.error}>We cannot display users at the moment.</MessageBar>
          )}
        </GridView>
      </div>
      {/*
      <Routes>
        <Route path=":id" element={<UserSignInBlade {...props} />} />
        <Route path=":id/*" element={<UserSignInBlade {...props} />} />
      </Routes>
          */}
    </>
  );
};
