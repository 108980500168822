import { styled } from "@fluentui/react";
import { ISettingsProps } from "./Settings.types";
import {
  getStyles,
  ISettingsStyleProps,
  ISettingsStyles,
} from "./Settings.styles";
import { SettingsComponent } from "./Settings.base";

/**
 * Settings
 */
export const Settings = styled<
  ISettingsProps,
  ISettingsStyleProps,
  ISettingsStyles
>(SettingsComponent, getStyles);
