import { styled } from "@fluentui/react";
import { IShimmeredListProps } from "./ShimmeredList.types";
import {
  getStyles,
  IShimmeredListStyleProps,
  IShimmeredListStyles,
} from "./ShimmeredList.styles";
import { ShimmeredListComponent } from "./ShimmeredList.base";

/**
 * ShimmeredList
 */
export const ShimmeredList = styled<
  IShimmeredListProps,
  IShimmeredListStyleProps,
  IShimmeredListStyles
>(ShimmeredListComponent, getStyles);
