import { INavLinkGroup } from "@fluentui/react";
import { ResourceBase } from "./components/ResourceBase";

export class GlobalNavigationResources extends ResourceBase {
  public get global(): INavLinkGroup {
    const nav: INavLinkGroup = {
      links: [
        {
          key: "admin",
          name: this.t("Layouts.AppBar.Menu.Services.Admin", "Admin"),
          iconProps: {
            iconName: "AdminALogo32",
          },
          href: document.location.origin,
          url: document.location.origin,
        },
        {
          key: "account",
          name: this.t("Layouts.AppBar.Menu.Services.Account", "Account"),
          iconProps: { iconName: "AccountManagement" },
          href: "https://myaccount.microsoft.com",
          url: "https://myaccount.microsoft.com",
        },
      ],
    };
    return nav;
  }
}

export class NavigationResources extends ResourceBase {
  public get ApplicationTitle(): string {
    return this.t("ApplicationTitle");
  }

  public get application() {
    const appNav: INavLinkGroup[] = [
      {
        links: [
          {
            key: "home",
            iconProps: {
              iconName: "Home",
            },
            name: this.t("ApplicationMenu.HomeTitle"),
            url: "/",
            href: "/",
          },
        ],
      },
      {
        name: this.t("ApplicationMenu.IdentityTitle"),
        links: [
          {
            key: "users",
            iconProps: {
              iconName: "Contact",
            },
            name: this.t("ApplicationMenu.UsersTitle"),
            url: "/users",
            href: "/users",
          },
          {
            key: "invitations",
            iconProps: {
              iconName: "Mail",
            },
            name: this.t("ApplicationMenu.InvitationsTitle"),
            url: "/invitations",
            href: "/invitations",
          },
          {
            key: "departments",
            iconProps: {
              iconName: "CityNext",
            },
            name: this.t("ApplicationMenu.DepartmentsTitle","Departments"),
            url: "/departments",
            href: "/departments",
          },
          {
            key: "applications",
            iconProps: {
              iconName: "AllApps",
            },
            name: this.t("ApplicationMenu.ApplicationsTitle", "Applications"),
            url: "/applications",
            href: "/applications",
          },
          // {
          //   key: "groups",
          //   iconProps: {
          //     iconName: "AllApps",
          //   },
          //   name: this.t("ApplicationMenu.groups", "groups"),
          //   url: "/groups",
          //   href: "/groups",
          // },
          // {
          //   key: "organizations",
          //   iconProps: {
          //     iconName: "Org",
          //   },
          //   name: this.t("ApplicationMenu.OrganizationsTitle", "Organizations"),
          //   url: "/organizations",
          //   href: "/organizations",
          // }

        ],
      },
      {
        name: this.t("ApplicationMenu.PlatformTitle"),
        links: [
          {
            key: "settings",
            iconProps: {
              iconName: "Settings",
            },
            name: this.t("ApplicationMenu.SettingsTitle"),
            url: "/settings",
            href: "/settings",
          },
        ],
      },
    ];
    return appNav;
  }
}
