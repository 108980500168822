import React from "react";
import { useBlade } from "../../../../components/Blade.use";
import { useMediaQueries } from "../../../../components/MediaQueries";
import { QueryBuilder } from "../../../../components/QueryBuilder";
import { IUser } from "../../../../models/IUser";
import { useUserBladeNavigation } from "./UserBlade.nav";
import { IUserBladeProps } from "./UserBlade.types";

export const useUserBlade = (props: IUserBladeProps) => {
  const { api } = props;
  const { navigation, onNavigate, item, bladePanelProps, setItem } =
    useBlade<IUser>({
      ...props,
      get: async (id: string) => {
        const query = new QueryBuilder()
          .select("id", "displayName", "jobTitle", "otherMails", "department", "companyName", "accountEnabled", "userPrincipalName", "identities", "givenName", "surname", "createdDateTime", "userType", "creationType");
        const x = await api.api(`/users/${id}?${query.toQs()}`).get();
        return ({ ...x, key: x.id, email: (x.identities.filter((x: any) => x.signInType == "emailAddress")[0]?.issuerAssignedId ?? x.otherMails?.[0] ?? x.userPrincipalName).toLowerCase() });
      },
      route: "/users",
      name: "Users",
      title: (item?: IUser) =>
        item?.displayName ?? item?.userPrincipalName ?? "",
      navigation: useUserBladeNavigation,
    });

  const onUserChanged = (user: IUser) => setItem(user);
  const { mobile } = useMediaQueries();
  const [onOptions, setOnOptions] = React.useState(!mobile);
  const onToggleOptions = () => setOnOptions(!onOptions);

  return {
    navigation,
    onNavigate: (url: string) => {
      mobile && onToggleOptions();
      onNavigate(url);
    },
    onOptions,
    user: item,
    onToggleOptions,
    onUserChanged,
    bladePanelProps,
  };
};
