import { styled } from "@fluentui/react";
import { IPropertyTextFieldProps } from "./PropertyTextField.types";
import {
  getStyles,
  IPropertyTextFieldStyleProps,
  IPropertyTextFieldStyles,
} from "./PropertyTextField.styles";
import { PropertyTextFieldComponent } from "./PropertyTextField.base";

/**
 * PropertyTextField
 */
export const PropertyTextField = styled<
  IPropertyTextFieldProps,
  IPropertyTextFieldStyleProps,
  IPropertyTextFieldStyles
>(PropertyTextFieldComponent, getStyles);
