import {
  IButtonStyles,
  IMessageBarStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../components/base";
import { IGridViewStyles } from "../../components/gridview/GridView/GridView.styles";

export interface IOrganizationsSubComponentStyles {
  button?: IButtonStyles;
  grid?: IGridViewStyles;
  error?: IMessageBarStyles;
}

export interface IOrganizationsTokens {
  ver?: string;
}

export interface IOrganizationsStyles
  extends IComponentBaseStyles<IOrganizationsSubComponentStyles> {}

export interface IOrganizationsStyleProps
  extends IComponentBaseStyleProps<IOrganizationsTokens> {}

export const getOrganizationsClassNames = (
  styles?: IStyleFunctionOrObject<IOrganizationsStyleProps, IOrganizationsStyles>,
  props?: IOrganizationsStyleProps,
): IComponentBaseClassNames<
  IOrganizationsStyleProps,
  IOrganizationsStyles,
  IOrganizationsSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IOrganizationsStyleProps,
    IOrganizationsStyles,
    IOrganizationsSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<IOrganizationsStyleProps, IOrganizationsStyles> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IOrganizationsStyleProps,
): IOrganizationsStyles => {
  return {
    root: ["app-Organizations", {}],
  };
};
