import { Breadcrumb, DefaultButton, Dialog, DialogContent, DialogFooter, DialogType, MessageBar, MessageBarType, PrimaryButton, SelectionMode, Text } from "@fluentui/react";
import * as React from "react";
import { GridView } from "../../components/gridview/GridView/GridView";
import { BulkInvitation } from "../Users/Controls/BulkInvitation";
import { UserInvitationForm } from "../Users/Controls/UserInvitationForm";
import { getInvitationsClassNames } from "./Invitations.styles";
import { IInvitationsProps } from "./Invitations.types";
import { useInvitations } from "./Invitations.use";

export const InvitationsComponent: React.FunctionComponent<IInvitationsProps> = (props) => {
  const { styles } = props;
  const { classNames, subComponentStyles } = getInvitationsClassNames(styles, {
    ...props,
  });

  const {
    breadcrumb,
    selection,
    invitation,
    bulk,
    onBulkDismiss,
    onUserInvitationDismiss,
    error,
    t,
    approving,
    onApprove,
    submitting,
    onDismissApprove,
    onUpdateInvitation,
    onDismissRemoval,
    removing,
    ...gridProps
  } = useInvitations(props);

  return (
    <div className={classNames.root}>
      {approving && <Dialog
        onDismiss={() => onDismissApprove()}
        hidden={false} dialogContentProps={{
          title: "Approbation",
          type: DialogType.largeHeader,
        }} modalProps={{
          isBlocking: true,
          isOpen: true
        }} styles={{
          main: {
            maxWidth: "512px!important"
          }
        }}>
        <DialogContent>
          <Text>
            {`Êtes-vous sûr(e) de vouloir approuver l'utilisation du compte d'organisme ${approving.target} afin de representer le courriel ${approving.email}?`}
          </Text>
        </DialogContent>
        <DialogFooter>
          <PrimaryButton text="Approuver" style={{ height: "42px" }} onClick={() => onApprove(approving)} disabled={submitting} />
          <DefaultButton text="Annuler" style={{ height: "42px" }} onClick={() => onDismissApprove()} disabled={submitting} />
        </DialogFooter>
      </Dialog>}
      {removing && <Dialog
        onDismiss={() => onDismissRemoval()}
        hidden={false} dialogContentProps={{
          title: "Reinitialiser l'invitation"
        }} modalProps={{
          isBlocking: true,
          isOpen: true,
          className: "ms-Large-Dialog"
        }} styles={{
          main: {
            maxWidth: "512px!important"
          }
        }}>
        <DialogContent>
          <Text>
            {`Mise a jour de l'invitation du compte d'organisme ${removing.target} afin de representer le courriel ${removing.email}.`}
          </Text>
        </DialogContent>
        <DialogFooter>
          <PrimaryButton style={{ height: "42px" }} text="Retirer la demande" onClick={() => onUpdateInvitation(true)} disabled={submitting} />
          <PrimaryButton style={{ height: "42px" }} text="Retirer l'approbation" onClick={() => onUpdateInvitation(false)} disabled={!removing.approved || submitting} />
          <DefaultButton style={{ height: "42px" }} text="Annuler" onClick={() => onDismissRemoval()} disabled={submitting} />
        </DialogFooter>
      </Dialog>}
      {invitation && (
        <UserInvitationForm
          {...props}
          onDismiss={onUserInvitationDismiss}
          styles={undefined}
        />
      )}
      {bulk && (<BulkInvitation {...props} onDismiss={onBulkDismiss} styles={undefined} />)}
      <Breadcrumb onGrowData={() => undefined} items={breadcrumb} />
      {gridProps.items && <div><Text>{`${gridProps.items.length} invitations`}</Text></div>}
      <GridView
        {...props}
        {...gridProps}
        selection={selection}
        selectionMode={SelectionMode.none}
        styles={subComponentStyles?.grid}
      >
        {error !== undefined && (
          <MessageBar
            messageBarType={MessageBarType.error}
            styles={subComponentStyles?.error}
          >
            {t("Invitations.GridView.ErrorMessage", "We cannot display Invitations at the moment.")}
          </MessageBar>
        )}
      </GridView>
    </div>
  );
};
