import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../../../../components/base";

export interface IUserOverviewSubComponentStyles {
  button?: IButtonStyles;
}

export interface IUserOverviewTokens {
  ver?: string;
}

export interface IUserOverviewStyles
  extends IComponentBaseStyles<IUserOverviewSubComponentStyles> { }

export interface IUserOverviewStyleProps
  extends IComponentBaseStyleProps<IUserOverviewTokens> { }

export const getUserOverviewClassNames = (
  styles?: IStyleFunctionOrObject<IUserOverviewStyleProps, IUserOverviewStyles>,
  props?: IUserOverviewStyleProps,
): IComponentBaseClassNames<
  IUserOverviewStyleProps,
  IUserOverviewStyles,
  IUserOverviewSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IUserOverviewStyleProps,
    IUserOverviewStyles,
    IUserOverviewSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IUserOverviewStyleProps,
  IUserOverviewStyles
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (_props: IUserOverviewStyleProps): IUserOverviewStyles => {
  return {
    root: ["app-UserOverview", {}],
  };
};
