import { IColumn } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router";
import { useMediaQueries } from "../../../../../../components/MediaQueries";
import { QueryBuilder } from "../../../../../../components/QueryBuilder";
import { GridViewDisplayMode } from "../../../../../../components/gridview/models/GridViewDisplayMode";
import { IGridViewOptions } from "../../../../../../components/gridview/models/IGridViewOptions";
//import { renderSignInActivity } from "./Controls/SignInActivity/SignInActivity.render";
import { IUserAppsProps } from "./UserApps.types";
import { IContextualMenuItem } from "@fluentui/react";
import {
  Selection,
} from "@fluentui/react";
import { IGridViewCommand } from "../../../../../../components/gridview/models/IGridViewCommand";
import { useTranslation } from "react-i18next";

const renderAppsActivity = (_: IUserAppsProps, onItemInvoked: (item: any) => void): any => onItemInvoked !== undefined ? "" : " ";

export interface IUsersState {
  error?: string;
  options: IGridViewOptions;
  items?: any[];
  apps?: IContextualMenuItem;
  selectedItems?: any[]
  state?: string;
  addingApps?: boolean;
  RemovingApps?: boolean;
}

export const useUserApps = (props: IUserAppsProps) => {
  const { selected,api } = props;
  const navigate = useNavigate();
  const [state, setState] = React.useState<IUsersState>({
    options: {
      fetched: false,
      displayMode: GridViewDisplayMode.list,
      query: new QueryBuilder().top(20),
    },
  });
  

  const { error, options, items , addingApps, RemovingApps  } = state;

  const getItems = async (force: boolean) => {
    try {
      if (selected.id === undefined) throw new Error();
      const result = await api.api(`/users/${selected.id}/appRoleAssignments`)
      .header('ConsistencyLevel','eventual')
      .get();
      const idAuth = result.value.map((c:any) =>  c.resourceDisplayName)
      const apps = await api.api(`/applications`)
      .get();
      const newApps = apps.value.filter((c:any) => !idAuth.includes(c.displayName))
      setState({
        ...state,
        error: undefined,
        options: {
          ...options,
          fetched: true,
        },
        items: force
          ? result?.value ?? []
          : (items ?? []).concat(
            result?.value ?? [],
          ),
        apps: newApps
      } );
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        error: "error",
        options: {
          ...options,
          fetched: true,
        },
        items: [],
      });
    } 
  };

  const onCommonDismiss = (itemsAdd?:any[],force?: boolean) => {
    if (
      (typeof force !== "boolean" && typeof force !== "undefined") ||
      !(force ?? false)
    ) {
      itemsAdd?.map((c:any) => {c.resourceDisplayName = c.displayName; c.principalType =""; c.resourceId = c.id;c.creationTimestamp =c.createdDateTime })
      console.log(state)
      setState({
        ...state,
        items:RemovingApps  ? items?.filter((c:any)  => !selectedApps.includes(c)):items?.concat(itemsAdd),
        error: undefined,
        addingApps: false,
        RemovingApps: false,
        state: undefined,
      });
    } else if (force ?? true) {
      options.query = options.query.skip(0).top(999);
      //getItems(true);
    }
  };

  const onDismissRemoving = () => {
      setState({
        ...state,
        RemovingApps: false,
        state: undefined,
      });
  };


  React.startTransition(() => {
    state.items === undefined && getItems(true);
  });

  const onFetch = () => {
    options.query = options.query.skip(items?.length ?? 0);
    getItems(false);
  };
  
  const { mobile } = useMediaQueries();

  const onItemInvoked = (item: any) =>
    item.id !== undefined && navigate(item.id);
  const {t} = useTranslation();
  const columns: IColumn[] = mobile
    ? [
      {
        key: "Apps",
        name: t("Users.Apps.Apps","Apps"),
        fieldName: "Apps",
        data: {
          type: "isodate",
          isSortable: true,
        },
        minWidth: 275,
        onRender: renderAppsActivity(props, onItemInvoked),
      },
    ]
    : [
      {
        key: "creationTimestamp",
        name: t("Users.Apps.Creationdate","Creation Date"),
        fieldName: "creationTimestamp",
        minWidth: 220,
        maxWidth: 220,
        data: {
          type: "isodate",
          isSortable: true,
        },
        visible: true
      },
      {
        key: "resourceDisplayName",
        name: t("Users.Apps.AppName","App Name"),
        fieldName: "resourceDisplayName",
        minWidth: 240,
        maxWidth: 240
      },
      {
        key: "principalType",
        name: t("Users.Apps.UserType","User Type"),
        fieldName: "principalType",
        minWidth: 240,
        maxWidth: 240
      },
      {
        key: "resourceId",
        name: t("Users.Apps.AppAssiId","Application Assignement id"),
        fieldName: "resourceId",
        minWidth: 240,
        maxWidth: 240
      },
    ].filter((x) => x.visible ?? true);

  const onSort = (fieldName: string, desc: boolean) => {
    options.query = options.query
      .skip(0)
      .top(20)
      .orderBy(...[desc ? fieldName : `${fieldName} desc`]);
    getItems(true);
  };
  const commands: IGridViewCommand[] = [
    {
      key: 'newItem',
      text: t("Users.Commands.Add",'Add'),
      iconProps: { iconName: 'Add' },
      ariaLabel: 'New',
      buttonStyles: {
        root: {
          backgroundColor: "transparent",
        },
      },
      onClick: (ev) => {
        console.log("clicked")
        ev?.preventDefault();
        ev?.stopPropagation();
        setState({
          ...state,
          addingApps:true
        });

      }
    },
    {
      key: 'delete',
      text: t("Users.Commands.Delete","Delete"),
      iconProps: { iconName: 'Delete' },
      selectionRequired: true,
      ariaLabel: 'Delete',
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        setState({
          ...state,
          RemovingApps:true
        });
      }
    },
    {
      key: 'refresh',
      text: t("Users.Commands.Refresh", "Refresh"),
      ariaLabel: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      split: true,
      onClick: (ev) => {
        console.log(state.selectedItems)
        ev?.preventDefault();
        ev?.stopPropagation();
        getItems(true);
      }
    },
  ];
  const commandBarProps = () => {
    return {
      items: commands,
    };
  };
  const [selectedApps, setSelected] = React.useState<any[]>([])

  const [selection] = React.useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        setSelected(selection.getSelection()??[]);
      },
    }),
  );

  const onSelectionChange = () => { };
  
  return {
    error,
    onFetch,
    onSort,
    options,
    items,
    onItemInvoked,
    onSelectionChange,
    columns,
    commands,
    addingApps,
    onCommonDismiss,
    onDismissRemoving,
    RemovingApps,
    commandBarProps,
    selectedApps,
    selection,
  };
};
