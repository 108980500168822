import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../components/base";

export interface IDepartmentResourceSubComponentStyles {
  button?: IButtonStyles;
}

export interface IDepartmentResourceTokens {
  ver?: string;
}

export interface IDepartmentResourceStyles
  extends IComponentBaseStyles<IDepartmentResourceSubComponentStyles> {}

export interface IDepartmentResourceStyleProps
  extends IComponentBaseStyleProps<IDepartmentResourceTokens> {}

export const getDepartmentResourceClassNames = (
  styles?: IStyleFunctionOrObject<
    IDepartmentResourceStyleProps,
    IDepartmentResourceStyles
  >,
  props?: IDepartmentResourceStyleProps,
): IComponentBaseClassNames<
  IDepartmentResourceStyleProps,
  IDepartmentResourceStyles,
  IDepartmentResourceSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IDepartmentResourceStyleProps,
    IDepartmentResourceStyles,
    IDepartmentResourceSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IDepartmentResourceStyleProps,
  IDepartmentResourceStyles
> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IDepartmentResourceStyleProps,
): IDepartmentResourceStyles => {
  return {
    root: ["app-DepartmentResource", {}],
  };
};
