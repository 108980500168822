import { MarqueeSelection, Selection } from "@fluentui/react";
import * as React from "react";

interface IGridViewSelectionProps {
  selection?: Selection;
  isMarqueeEnabled: boolean;
  children?: any;
}

export const GridViewSelection: React.FunctionComponent<
  IGridViewSelectionProps
> = ({ selection, isMarqueeEnabled, children }) => {
  return (
    <>
      {selection === undefined ? (
        <>{children}</>
      ) : (
        <MarqueeSelection selection={selection} isEnabled={isMarqueeEnabled}>
          {children}
        </MarqueeSelection>
      )}
    </>
  );
};
