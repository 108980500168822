import { ITheme, ThemeProvider } from "@fluentui/react";
import React from "react";
import { Theming } from "./components/Theming";

interface IAppContainerProps {
  theme?: ITheme;
  delay?: number;
  children?: any;
}

function debounce(this: any, fn: any, ms: number) {
  let timer: any | undefined;
  return (...args: any) => {
    timer !== undefined && clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const mt = this;
      // eslint-disable-next-line prefer-rest-params
      fn.apply(mt, args);
    }, ms);
  };
}

export const AppContainer: React.FunctionComponent<IAppContainerProps> = (
  props: IAppContainerProps
) => {
  const delay = props.delay ?? 600;
  const theme = props.theme ?? Theming.getTheme();
  const [counter, setCounter] = React.useState(true);
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setCounter(false);
      setTimeout(() => setCounter(true), 100);
    }, delay);
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);
  return (
    <ThemeProvider theme={theme} applyTo="body">
      {counter && props.children}
    </ThemeProvider>
  );
};
