import {
  IBreadcrumbStyles,
  IButtonStyles,
  ICommandBarStyles,
  IPanelStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../base";

export interface IBladePanelSubComponentStyles {
  button?: IButtonStyles;
  pinButton?: IButtonStyles;
  moreButton?: IButtonStyles;
  panel?: Partial<IPanelStyles>;
  breadcrumb?: Partial<IBreadcrumbStyles>;
  commandBar?: ICommandBarStyles;
}

export interface IBladePanelTokens {
  ver?: string;
}

export interface IBladePanelStyles
  extends IComponentBaseStyles<IBladePanelSubComponentStyles> { }

export interface IBladePanelStyleProps
  extends IComponentBaseStyleProps<IBladePanelTokens> { }

export const getBladePanelClassNames = (
  styles?: IStyleFunctionOrObject<IBladePanelStyleProps, IBladePanelStyles>,
  props?: IBladePanelStyleProps,
): IComponentBaseClassNames<
  IBladePanelStyleProps,
  IBladePanelStyles,
  IBladePanelSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IBladePanelStyleProps,
    IBladePanelStyles,
    IBladePanelSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IBladePanelStyleProps,
  IBladePanelStyles
> = (props: IBladePanelStyleProps): IBladePanelStyles => {
  return {
    root: ["app-BladePanel", {}],
    subComponentStyles: {
      breadcrumb: {
        root: {
          marginTop: "-5px",
        },
      },
      pinButton: {
        root: {
          backgroundColor: (props.theme?.semanticColors as any).bodyBackground,
        },
        textContainer: {
          color: (props.theme?.semanticColors as any).bodyText,
        },
        menuIcon: {
          display: "none",
        },
      },
      moreButton: {
        root: {
          backgroundColor: "transparent!important",
        },
        textContainer: {
          color: (props.theme?.semanticColors as any).bodyText,
        },
        menuIcon: {
          display: "none",
        },
      },
      commandBar: {
        root: {
          ".ms-CommandBarItem-link": {
            backgroundColor: "transparent!important",
          },
          backgroundColor: "transparent",
          borderBottom: "0px",
          ".ms-Button-menuIcon": {
            display: "none",
          },
          ".ms-Button-menuIcon.is-expanded": {
            display: "none",
          },
        },
      },
      panel: {
        root: {
          marginTop: "33px",
          maxHeight: "calc(-33px + 100vh)",
        },
        commands: {
          backgroundColor: (props.theme?.semanticColors as any)["controlBackground"],
          zIndex: 99,
          paddingTop: "0px",
        },
        content: {
          paddingLeft: "10px",
          paddingRight: "unset",
          paddingBottom: "0px",
        },
        navigation: {
          paddingTop: "18px",
        },
        contentInner: {
          height: "calc(-58px + 100vh)",
        },
        footer: {
          backgroundColor: (props.theme?.semanticColors as any)["controlBackground"],
        },
        scrollableContent: {
          height: "100%",
        },
      },
    },
  };
};
