import * as React from "react";

export interface IAppNavigationHeaderProps {
  children?: React.ReactNode;
}

export const AppNavigationHeader: React.FunctionComponent<
  IAppNavigationHeaderProps
> = (props) => {
  return <>{props.children}</>;
};
