import { styled } from "@fluentui/react/lib/Utilities";
import { IApplicationResourceProps } from "./ApplicationsResource.types";
import {
  getStyles,
  IApplicationResourceStyleProps,
  IApplicationResourceStyles,
} from "./ApplicationsResource.styles";
import { ApplicationResourceComponent } from "./ApplicationsResource.base";

/**
 * ApplicationResource
 */
export const ApplicationResource = styled<
  IApplicationResourceProps,
  IApplicationResourceStyleProps,
  IApplicationResourceStyles
>(ApplicationResourceComponent, getStyles);
