import { Stack, StackItem, Text } from '@fluentui/react';
import * as React from 'react';
import { getStatusIndicatorClassNames } from './StatusIndicator.styles';
import {
  IStatusIndicatorProps
} from './StatusIndicator.types';

export const StatusIndicatorComponent: React.FunctionComponent<
  IStatusIndicatorProps
> = (props) => {
  const { styles, total, done, fileName } = props;
  const { classNames /* , subComponentStyles */ } = getStatusIndicatorClassNames(
    styles,
    {
      ...props,
    }
  );
  return (
    <div className={classNames.root}>
      <Stack horizontal>
        <div style={{
          position: "absolute", width: "110px"
        }}>
          <div style={{
            position: "absolute",
            top: "38px",
            right: "18px"
          }}>
            <Stack horizontal tokens={{ childrenGap: 5 }}>
              <Text variant="xLargePlus">115</Text>
              <Text variant="xLarge">/</Text>
              <Text variant="xLarge">115</Text>
            </Stack>
          </div>
        </div>
        <svg id="loadingSpinner" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 80 80" style={{ visibility: "visible", opacity: 1 }}>
          <defs>
            <path id="als" d="M0 0h40v40H0z"></path>
          </defs>
          <g fill="none" fillRule="evenodd">
            <circle id="spinnerCircle" cx="40" cy="40" r="38" strokeWidth="2.817"></circle>
            <g transform="translate(40)">
              <mask id="bls" fill="#fff">
                <use xlinkHref="#als"></use>
              </mask>
              <circle cy="40" r="38" stroke="#0078D4" strokeWidth="2.817" mask="url(#bls)"></circle>
            </g>
          </g>
        </svg>
        <StackItem>
          <div style={{ margin: 10 }}>
            <Stack tokens={{ childrenGap: 10 }}>
              <Text>Filename: {fileName}</Text>
              <Text>Progress: {total === 0 ? 0 : Math.round(done / total)}%</Text>
            </Stack>
          </div>
        </StackItem>
      </Stack>
    </div>
  );
}
