import { ICommandBarItemProps, Icon } from "@fluentui/react";
import { IAppLayoutProps } from "../../AppLayout";
import { NotificationToast } from "./NotificationToast";

export const asNotificationsCommand = (
  props: IAppLayoutProps,
  onOpenNotifications: () => void,
): ICommandBarItemProps => {
  return {
    key: "notifications",
    theme: props.theme,
    iconOnly: true,
    buttonStyles: {
      root: {
        backgroundColor: "transparent",
        ":hover i": {
          color: "#000"
        }
      },
      icon: {
        color: props.theme.semanticColors.bodyText,
      },
      iconHovered: {
        color: props.theme.semanticColors.bodyText,
      },
      iconPressed: {
        color: props.theme.semanticColors.bodyText,
      },
    },
    iconProps: {
      iconName: "Ringer",
      styles: {
        root: {
          color: "#fff",
        }
      }
    },
    onClick: () => onOpenNotifications(),
    onRenderIcon: (item) => (
      <>
        <Icon
          id="notifications-icon"
          iconName="Ringer"
          styles={{
            root: {
              color: "#fff",
              fontSize: "16px",
              ":hover": {
                color: "#000"
              }
            },
          }}
          className={item?.classNames?.icon}
        />
        <NotificationToast {...props} />
      </>
    ),
  };
};
