import {
  IStyle,
  ITheme,
  StackItem,
  Stack,
  INavLinkGroup,
} from "@fluentui/react";
import * as React from "react";
import { Theming } from "../Theming";
import { AppNavigation } from "./AppNavigation";

export interface IMenuLayoutProps {
  theme?: ITheme;
  initialSelectedKey?: string;
  layoutStyles?: IStyle;
  navStyle?: IStyle;
  bodyStyles?: IStyle;
  nav: INavLinkGroup[];
  initialCollapsed?: boolean;
  onNavigate: (value: string) => void;
  children?: any;
  absolute?: boolean;
}

export interface IMenuLayoutState {
  collapsed: boolean;
}

export class MenuLayout extends React.Component<
  IMenuLayoutProps,
  IMenuLayoutState
> {
  constructor(props: IMenuLayoutProps) {
    super(props);
    this.state = {
      collapsed: props.initialCollapsed ?? false,
    };
  }

  public render(): JSX.Element {
    const {
      onNavigate,
      initialSelectedKey,
      theme,
      layoutStyles,
      bodyStyles,
      navStyle,
      nav,
      absolute,
      children,
    } = this.props;
    const { collapsed } = this.state;
    return (
      <Stack
        theme={theme}
        grow
        horizontal
        verticalAlign="start"
        styles={{
          root: {
            backgroundColor: (theme ?? Theming.getTheme()).semanticColors
              .bodyBackground,
            position: "relative",
            ...(layoutStyles as any),
          },
        }}
      >
        {nav !== undefined && (
          <StackItem
            theme={theme}
            align="start"
            verticalFill
            styles={{
              root: {
                position: absolute ?? false ? "absolute" : "unset",
                maxWidth: "228px",
                height: "100%",
                minHeight: "calc(-44px + 100vh)",
                ...(navStyle as any),
              },
            }}
          >
            <Stack
              theme={theme}
              verticalFill
              verticalAlign="start"
              styles={{
                root: {
                  backgroundColor:
                    theme?.isInverted ?? false
                      ? (theme ?? Theming.getTheme()).semanticColors
                          .bodyBackground
                      : "transparent",
                },
              }}
            >
              <div style={{ height: "5px" }} />
              <AppNavigation
                theme={theme}
                initialSelectedKey={initialSelectedKey}
                collapsible={true}
                collapsed={collapsed}
                onCollapsed={() =>
                  this.setState({
                    collapsed: !collapsed,
                  })
                }
                navigation={nav}
                onNavigate={onNavigate}
              />
            </Stack>
          </StackItem>
        )}
        <StackItem
          grow
          align="start"
          styles={{
            root: {
              position: absolute ?? false ? "absolute" : "unset",
              overflowX: "hidden",
              overflowY: "auto",
              height: "auto",
              width: "100%",
              backgroundColor: (theme ?? Theming.getTheme()).semanticColors
                .bodyBackground,
              left:
                nav === undefined || !(absolute ?? false)
                  ? undefined
                  : collapsed
                  ? "40px"
                  : "186px",
              ...(bodyStyles as any),
            },
          }}
        >
          {children}
        </StackItem>
      </Stack>
    );
  }
}
