import { styled } from "@fluentui/react";
import { ILandingProps } from "./Landing.types";
import {
  getStyles,
  ILandingStyleProps,
  ILandingStyles,
} from "./Landing.styles";
import { LandingComponent } from "./Landing.base";

/**
 * Landing
 */
export const Landing = styled<
  ILandingProps,
  ILandingStyleProps,
  ILandingStyles
>(LandingComponent, getStyles);
