import { ICommandBarItemProps } from "@fluentui/react";
import React from "react";
import { IGridViewCommandBarProps } from "./GridViewCommandBar.types";

export const useGridViewCommandBar = (props: IGridViewCommandBarProps) => {
  const { selection, commandBarProps } = props;
  const [confirmingItem, setConfirmingItem] = React.useState<
    ICommandBarItemProps | undefined
  >(undefined);

  const onConfirming = (result: boolean) => {
    if (result) {
      confirmingItem?.onClick?.(undefined);
    }
    selection?.setAllSelected(false);
    setConfirmingItem(undefined);
  };

  return {
    onConfirming,
    confirmingItem,
    commandBarProps: {
      ...commandBarProps,
      items: commandBarProps.items.map((x: any) => {
        return {
          ...x,
          onClick:
            x.confirmMessage === undefined
              ? x.onClick
              : (ev: any) => {
                ev.preventDefault();
                ev.stopPropagation();
                setConfirmingItem(x);
              },
          disabled:
            x.selectionRequired ?? false
              ? props.selection?.count === 0
              : x.disabled,
        };
      }),
    },
  };
};
