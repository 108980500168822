import {
  classNamesFunction,
  IStyleFunctionOrObject
} from "@fluentui/react";
import { IComponentBaseClassNames } from "./IComponentBaseClassNames";

/**
 * Component Styles Loader
 *
 * Helps with assembling and merging styles into css
 */
export const ComponentStylesLoader = {
  /**
   * Get component class names of component styles loader
   */
  getComponentClassNames: <
    TStyleProps extends {},
    TStyleSet,
    TSubComponentStyles,
  >(
    styles?: IStyleFunctionOrObject<TStyleProps, any>,
    props?: TStyleProps,
  ): IComponentBaseClassNames<TStyleProps, TStyleSet, TSubComponentStyles> => {
    const getClassNames = classNamesFunction<TStyleProps, any>();
    const classNames = getClassNames(styles, props);
    return {
      classNames: classNames,
      subComponentStyles:
        classNames.subComponentStyles as unknown as TSubComponentStyles,
    };
  },
};
