import { List } from "@fluentui/react";
import * as React from "react";
import { ShimmeredCard } from "./Controls/ShimmeredCard";
import { getShimmeredListClassNames } from "./ShimmeredList.styles";
import { IShimmeredListProps } from "./ShimmeredList.types";

export const ShimmeredListComponent: React.FunctionComponent<
  IShimmeredListProps
> = (props) => {
  const { styles } = props;
  const { classNames /* , subComponentStyles */ } = getShimmeredListClassNames(
    styles,
    {
      ...props,
    },
  );
  const count = 10;
  return (
    <div className={classNames.root}>
      <List
        {...props}
        className={classNames.root}
        items={new Array(count)}
        getPageSpecification={() => {
          return { itemCount: count };
        }}
        onRenderCell={(/* item?: any, index?: number */) => (
          <ShimmeredCard columnWidth={200} />
        )}
      />
    </div>
  );
};
