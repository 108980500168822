import { styled } from "@fluentui/react";
import { IUsersProps } from "./Users.types";
import { getStyles, IUsersStyleProps, IUsersStyles } from "./Users.styles";
import { UsersComponent } from "./Users.base";

/**
 * Users
 */
export const Users = styled<IUsersProps, IUsersStyleProps, IUsersStyles>(
  UsersComponent,
  getStyles,
);
