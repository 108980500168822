import { IColumn, Selection } from "@fluentui/react";
import { TFunction } from "i18next";
import { IGridViewCommand } from "../components/gridview/models/IGridViewCommand";
import { ExportService } from "../services/ExportService";

export const ExportCommand = (
  items: any[] | undefined,
  columns: IColumn[],
  selection: Selection,
  t: TFunction,
): IGridViewCommand => ({
  key: "xlsx",
  text: t("Commands.Export", "Export") ?? "Export",
  iconProps: {
    iconName: "ExcelDocument",
  },
  buttonStyles: {
    root: {
      backgroundColor: "transparent",
    },
  },
  subMenuProps: {
    items: [
      {
        key: "json",
        text: t("Commands.ExportJson", "Export to json") ?? "Export to json",
        iconProps: {
          iconName: "FileCode",
        },
        buttonStyles: {
          root: {
            backgroundColor: "transparent",
          },
        },
        onClick: (ev: any) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          const sel = selection.getSelection();
          ExportService.export(
            (sel?.length ?? 0) > 0 ? sel : items ?? [],
            columns,
            "json",
          );
        },
      },
      {
        key: "xlsx",
        text: t("Commands.ExportExcel", "Export to Excel") ?? "Export to Excel",
        iconProps: {
          iconName: "ExcelDocument",
        },
        buttonStyles: {
          root: {
            backgroundColor: "transparent",
          },
        },
        onClick: (ev: any) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          const sel = selection.getSelection();
          ExportService.export(
            (sel?.length ?? 0) > 0 ? sel : items ?? [],
            columns,
            "xlsx",
          );
        },
      },
      {
        key: "copyjson",
        text:
          t("Commands.ExportClipboard", "Copy json to clipboard") ??
          "Copy json to clipboard",
        iconProps: {
          iconName: "PasteAsCode",
        },
        buttonStyles: {
          root: {
            backgroundColor: "transparent",
          },
        },
        onClick: (ev: any) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          const sel = selection.getSelection();
          ExportService.export(
            (sel?.length ?? 0) > 0 ? sel : items ?? [],
            columns,
            "clipboard",
          );
        },
      },
      {
        key: "copyjson",
        text:
          t("Commands.ExportClipboard", "Copy list to clipboard") ??
          "Copy list to clipboard",
        iconProps: {
          iconName: "PasteAsCode",
        },
        buttonStyles: {
          root: {
            backgroundColor: "transparent",
          },
        },
        onClick: (ev: any) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          const sel = selection.getSelection();
          ExportService.export(
            (sel?.length ?? 0) > 0 ? sel : items ?? [],
            columns,
            "list",
          );
        },
      },
    ],
  },
  onClick: (ev: any) => {
    ev?.preventDefault();
    ev?.stopPropagation();
    const sel = selection.getSelection();
    ExportService.export(
      (sel?.length ?? 0) > 0 ? sel : items ?? [],
      columns,
      "xlsx",
    );
  },
});
