import { styled } from "@fluentui/react";
import { IGridViewProps } from "./GridView.types";
import {
  getStyles,
  IGridViewStyleProps,
  IGridViewStyles,
} from "./GridView.styles";
import { GridViewComponent } from "./GridView.base";

/**
 * GridView
 */
export const GridView = styled<
  IGridViewProps,
  IGridViewStyleProps,
  IGridViewStyles
>(GridViewComponent, getStyles);
