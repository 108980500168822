import React from "react";
import { AppContainer } from "./AppContainer";
import { AppRouter } from "./AppRouter";
import { Theming } from "./components/Theming";
import { useEvent } from "./components/useEvent";

export interface IAppProps {
    ver?: string;
}

const defaultTheme = Theming.getTheme();

export const App: React.FunctionComponent<IAppProps> = () => {
    const [state, setState] = React.useState({
        theme: defaultTheme,
    });

    const theme = state.theme ?? defaultTheme;

    useEvent("onThemeChanged", ({ themeName }) => {
        localStorage.setItem("theme", themeName);
        setState({
            theme: Theming.getTheme(),
        });
    });

    return (
        <AppContainer theme={theme}>
            <AppRouter
                theme={theme}
                onThemeChanged={(themeName) => {
                    localStorage.setItem("theme", themeName);
                    setState({
                        theme: Theming.getTheme(),
                    });
                }}
            />
        </AppContainer>
    );
};
