import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../../components/base";

export interface IUserInvitationFormSubComponentStyles {
  button?: IButtonStyles;
}

export interface IUserInvitationFormTokens {
  ver?: string;
}

export interface IUserInvitationFormStyles
  extends IComponentBaseStyles<IUserInvitationFormSubComponentStyles> {}

export interface IUserInvitationFormStyleProps
  extends IComponentBaseStyleProps<IUserInvitationFormTokens> {}

export const getUserInvitationFormClassNames = (
  styles?: IStyleFunctionOrObject<
    IUserInvitationFormStyleProps,
    IUserInvitationFormStyles
  >,
  props?: IUserInvitationFormStyleProps,
): IComponentBaseClassNames<
  IUserInvitationFormStyleProps,
  IUserInvitationFormStyles,
  IUserInvitationFormSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IUserInvitationFormStyleProps,
    IUserInvitationFormStyles,
    IUserInvitationFormSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IUserInvitationFormStyleProps,
  IUserInvitationFormStyles
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (_props: IUserInvitationFormStyleProps): IUserInvitationFormStyles => {
  return {
    root: [
      "app-UserInvitationForm",
      {
        padding: "10px",
      },
    ],
  };
};
