import { User } from "oidc-react";
import { meta } from "./components/utils";

export const InvitationService = {
    keys: async (user: User) => {
        const x = await fetch(`${meta("WWW-api-url")}/.well-known/keys`, {
            method: 'GET',
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`,
                "Content-Type": "application/json",
            }
        });
        const blob = await x.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "keys";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again 
    },
    openid: async (user: User) => {
        const x = await fetch(`${meta("WWW-api-url")}/.well-known/openid-configuration`, {
            method: 'GET',
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`,
                "Content-Type": "application/json",
            }
        });

        const blob = await x.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "openid-configuration";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();  //afterwards we remove the element again 
    },
    invite: async (values: any, user: User) => {
        return await fetch(`${meta("WWW-api-url")}/invitations`, {
            method: "POST",
            body: JSON.stringify(values, null, 2),
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`,
                "Content-Type": "application/json",
            }
        });
    },
    get: async (user: User) => {
        const response = await fetch(`${meta("WWW-api-url")}/invitations`, {
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`
            }
        });
        const payload = await response.json();
        return payload?.items ?? [];
    },
    repair: async (payload: any[], user: User) => {
        const response = await fetch(`${meta("WWW-api-url")}/synchronize`, {
            method: "POST",
            body: JSON.stringify(payload),
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`,
                "Content-Type": "application/json",
            }
        });
        return response.status < 299;
    },
    approve: async (email: string, user: User) => {
        const response = await fetch(`${meta("WWW-api-url")}/invitations/upn?email=${email}`, {
            method: "POST",
            body: "",
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`,
                "Content-Type": "application/json",
            }
        });
        return response.status < 299;
    },
    unapprove: async (email: string, user: User) => {
        const response = await fetch(`${meta("WWW-api-url")}/invitations/upn?email=${email}`, {
            method: "DELETE",
            body: "",
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`,
                "Content-Type": "application/json",
            }
        });
        return response.status < 299;
    },
    remove: async (email: string, user: User) => {
        const response = await fetch(`${meta("WWW-api-url")}/invitations?email=${email}`, {
            method: "DELETE",
            body: "",
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`,
                "Content-Type": "application/json",
            }
        });
        return response.status < 299;
    },
}