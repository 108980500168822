import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../../../../components/base";

export interface IUserProfileSubComponentStyles {
  button?: IButtonStyles;
}

export interface IUserProfileTokens {
  ver?: string;
}

export interface IUserProfileStyles
  extends IComponentBaseStyles<IUserProfileSubComponentStyles> { }

export interface IUserProfileStyleProps
  extends IComponentBaseStyleProps<IUserProfileTokens> { }

export const getUserProfileClassNames = (
  styles?: IStyleFunctionOrObject<IUserProfileStyleProps, IUserProfileStyles>,
  props?: IUserProfileStyleProps,
): IComponentBaseClassNames<
  IUserProfileStyleProps,
  IUserProfileStyles,
  IUserProfileSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IUserProfileStyleProps,
    IUserProfileStyles,
    IUserProfileSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IUserProfileStyleProps,
  IUserProfileStyles
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (_props: IUserProfileStyleProps): IUserProfileStyles => {
  return {
    root: [
      "app-UserProfile",
      {
        maxWidth: "820px",
      },
    ],
  };
};
