import {
  Icon,
  INavLinkGroup,
  IPanelProps,
  ITheme,
  Link,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { useMediaQueries } from "../MediaQueries";
import i18next from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ServiceUris } from "../utils";
import { AppNavigation } from "./AppNavigation";
import { GlobalNavList } from "./GlobalNavList";

export interface INavigationPanelProps {
  title?: string | JSX.Element;
  theme?: ITheme;
  globalMenuOnly?: boolean;
  nav?: INavLinkGroup;
  appNav?: INavLinkGroup[];
  areaNav?: INavLinkGroup[];
  onDismiss: () => void;
  onNavigate: (value: string) => void;
}

function NavigationHeader(props?: IPanelProps): JSX.Element {
  return (
    <Stack horizontal verticalAlign="start">
      <StackItem grow>
        <div
          style={{
            margin: "10px 10px",
            height: "24px!important",
          }}
        >
          <Icon
            /* iconName="WaffleOffice365" */
            iconName="GlobalNavButton"
            styles={{
              root: {
                cursor: "pointer",
                fontSize: "18px",
              },
            }}
            onClick={props?.onDismiss}
          />
        </div>
      </StackItem>
      <StackItem align="end">
        <div
          style={{
            height: "24px!important",
          }}
        >
          <Icon
            iconName="Clear"
            styles={{
              root: {
                position: "absolute",
                right: "15px",
                top: "15px",
                color: "lightgray",
                cursor: "pointer",
                fontSize: "10px",
              },
            }}
            onClick={props?.onDismiss}
          />
        </div>
      </StackItem>
    </Stack>
  );
}

export const NavigationPanel: React.FunctionComponent<
  INavigationPanelProps
> = ({
  title,
  theme,
  globalMenuOnly,
  nav,
  appNav,
  areaNav,
  onNavigate,
  onDismiss,
}) => {
    const { t } = useTranslation();
    const targetNav =
      areaNav === undefined
        ? globalMenuOnly ?? false
          ? appNav
          : [
            {
              links: [
                {
                  title: t("ApplicationMenu.AzurePortal", "Azure Portal") ?? "Azure Portal",
                  iconProps: {
                    iconName: "Cloud"
                  },
                  name: t("ApplicationMenu.AzurePortal", "Azure Portal") ?? "Azure Portal",
                  url: "https://portal.azure.com"
                }
              ]
            }
          ]
        : areaNav;
    const { mobile } = useMediaQueries();
    const [state, setState] = React.useState({
      navIndex: 0,
      collapsed: false,
    });
    const wwwHome = ServiceUris.home();
    const { navIndex, collapsed } = state;
    function onLinkClick(item?: PivotItem): void {
      if (item?.props.itemKey !== undefined) {
        setState({
          navIndex: parseInt(item.props.itemKey, undefined),
          collapsed,
        });
      }
    }
    const pivots = [
      {
        condition: () => mobile && targetNav !== undefined,
        name:
          targetNav != null && (appNav == null || (globalMenuOnly ?? true))
            ? t("globals:Layouts.AppBar.Menu.Application", "Application")
            : t("globals:Layouts.AppBar.Menu.Section", "Sections"),
        onRender: () =>
          targetNav !== undefined ? (
            <AppNavigation
              theme={theme}
              key={1}
              collapsible={false}
              collapsed={collapsed}
              onCollapsed={() =>
                setState({
                  navIndex,
                  collapsed: !collapsed,
                })
              }
              navigation={targetNav}
              onNavigate={onNavigate}
            />
          ) : (
            <></>
          ),
      },
      {
        condition: () => appNav !== undefined && (mobile || targetNav),
        name: t("globals:Layouts.AppBar.Menu.Application", "Application"),
        onRender: () =>
          targetNav !== undefined ? (
            <AppNavigation
              theme={theme}
              key={2}
              collapsible={false}
              collapsed={collapsed}
              onCollapsed={() =>
                setState({
                  navIndex,
                  collapsed: !collapsed,
                })
              }
              navigation={targetNav}
              onNavigate={onNavigate}
            />
          ) : (
            <></>
          ),
      },
      {
        condition: () => true,
        name: t("globals:Layouts.AppBar.Menu.Portal", "Portal"),
        onRender: () =>
          nav !== undefined ? (
            <GlobalNavList
              theme={theme}
              key={3}
              nav={nav}
              onNavigate={onNavigate}
            />
          ) : (
            <></>
          ),
      },
    ].filter((x) => x.condition());
    return (
      <Panel
        isOpen={true}
        isLightDismiss={true}
        hasCloseButton={false}
        type={mobile ? PanelType.smallFluid : PanelType.smallFixedNear}
        onDismiss={onDismiss}
        onRenderHeader={NavigationHeader}
        styles={{
          content: {
            paddingLeft: "8px",
            paddingRight: "0px",
          },
          scrollableContent: {
            overflow: "hidden",
          },
          commands: {
            display: "none",
          },
          main: {
            left: 0,
            right: "auto",
          },
        }}
      >
        <div style={{ height: "7px" }} />
        <Stack grow horizontal>
          <StackItem grow>
            {typeof title === "string" ? (
              <Text
                variant="mediumPlus"
                styles={{ root: { fontWeight: "bold", paddingLeft: "4px" } }}
              >
                {title}
              </Text>
            ) : (
              title
            )}
          </StackItem>
          <StackItem styles={{ root: { marginTop: "2px" } }}>
            {wwwHome !== undefined && wwwHome !== null && wwwHome !== "" && (
              <Link
                href={wwwHome}
                title={wwwHome}
                styles={{
                  root: {
                    textDecoration: "none!important",
                  },
                }}
              >
                <Stack horizontal>
                  <Text>
                    <>{i18next.t("globals:Layouts.AppBar.Menu.Home")}</>
                  </Text>
                  <Icon
                    iconName="NavigateForward"
                    styles={{
                      root: {
                        fontSize: "18px",
                        margin: "2px 5px",
                        paddingLeft: "5px",
                      },
                    }}
                  />
                </Stack>
              </Link>
            )}
          </StackItem>
        </Stack>
        {pivots.length === 1 ? (
          pivots[0].onRender()
        ) : (
          <>
            <Pivot selectedKey={navIndex.toString()} onLinkClick={onLinkClick}>
              {pivots.map((x, index) => (
                <PivotItem
                  headerText={x.name}
                  key={index.toString()}
                  itemKey={index.toString()}
                />
              ))}
            </Pivot>
            {pivots
              .filter((x, index) => x !== undefined && index === navIndex)
              .map((x) => x.onRender())}
          </>
        )}
      </Panel>
    );
  };
