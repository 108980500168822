import { FieldProps } from "formik";
import {
  Text,
  Checkbox,
  ICheckboxProps,
  DefaultPalette,
} from "@fluentui/react";
import {
  createFakeEvent,
  getErrorMessage,
  handleProps,
  isReadOnly,
  Omit,
} from "./utils";
import { FormikTextField } from "./FormikTextField";

export function mapFieldToCheckbox<V extends boolean, FormValues = any>({
  form,
  field: { value, onChange, onBlur, checked, ...field },
}: FieldProps<V, FormValues>): Pick<
  ICheckboxProps,
  "checked" | "name" | "onChange"
> {
  const shared = {
    onChange,
    checked,
    readOnly: (field as any).readOnly ?? form?.status?.readOnly,
  };
  return {
    ...field,
    ...shared,
    onChange: (_, checked) => {
      form.setFieldValue(field.name, checked);
      onBlur(createFakeEvent(field));
    },
    checked: value,
  };
}

export type FormikCheckboxProps<V extends boolean, FormValues = any> = Omit<
  ICheckboxProps,
  "checked" | "name" | "onChange"
> &
  FieldProps<V, FormValues>;

export function FormikCheckbox<V extends boolean, FormValues = any>({
  field,
  form,
  meta,
  ...props
}: FormikCheckboxProps<V, FormValues>) {
  const fieldProps = mapFieldToCheckbox({ field, form, meta });
  const { theme } = props;
  const readOnly = isReadOnly(field, form, props);
  const errorMessage = getErrorMessage({ form, field, meta });
  return readOnly ? (
    FormikTextField({
      ...props,
      ...fieldProps,
      errorMessage: errorMessage,
      field: field,
      form,
      meta,
    } as any)
  ) : (
    <>
      <Checkbox {...handleProps(props)} {...fieldProps} />
      {errorMessage !== undefined && (
        <div>
          <Text
            variant="small"
            styles={{
              root: { color: theme?.palette.redDark ?? DefaultPalette.redDark },
            }}>
            {errorMessage}
          </Text>
        </div>
      )}
    </>
  );
}
