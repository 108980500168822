import {
  DetailsListLayoutMode,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import * as React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { GridViewCommandBar } from "../GridViewCommandBar";
import { GridViewDisplayMode } from "../models/GridViewDisplayMode";
import { ShimmeredList } from "../ShimmeredList/ShimmeredList";
import { onRenderRow } from "./GridView.row";
import { GridViewSelection } from "./GridView.selection";
import { getGridViewClassNames } from "./GridView.styles";
import { IGridViewProps } from "./GridView.types";
import { useGridView } from "./GridView.use";

export const GridViewComponent: React.FunctionComponent<IGridViewProps> = (
  props,
) => {
  const {
    styles,
    children,
    selection,
    theme,
    selectionMode,
    items = [],
    detailsListProps,
    commandBarProps,
    onRenderLeftPane,
  } = props;
  const { classNames, subComponentStyles } = getGridViewClassNames(styles, {
    ...props,
    sortable: props.onSort !== undefined,
  });
  const {
    emptyMessage,
    listProps,
    top,
    columns,
    fetched,
    displayMode,
    onFetch,
  } = useGridView(props);

  return (
    <Stack horizontal grow verticalFill className={(classNames as any).root}>
      {onRenderLeftPane !== undefined && (
        <StackItem styles={subComponentStyles?.leftPane}>
          {onRenderLeftPane()}
        </StackItem>
      )}
      <Stack grow>
        {commandBarProps !== undefined && (
          <GridViewCommandBar
            {...props}
            commandBarProps={commandBarProps(props)}
            styles={undefined}
          />
        )}
        {children}
        <Stack
          id="scrollableId"
          className={(classNames as any).root}
          grow
          verticalFill
          styles={subComponentStyles?.main}>
          {!fetched && displayMode === GridViewDisplayMode.tiles && (
            <ShimmeredList
              {...props}
              styles={undefined}
              getItemCountForPage={() => top}
            />
          )}
          {displayMode !== GridViewDisplayMode.tiles && (
            <InfiniteScroll
              dataLength={items?.length ?? 0}
              loader={<div></div>}
              next={onFetch}
              scrollableTarget="scrollableId"
              hasMore={true}>
              <GridViewSelection
                selection={selection}
                isMarqueeEnabled={selection !== undefined}>
                <ShimmeredDetailsList
                  theme={theme}
                  onRenderRow={onRenderRow(theme)}
                  layoutMode={DetailsListLayoutMode.justified}
                  listProps={listProps}
                  selectionPreservedOnEmptyClick={
                    props.selectionPreservedOnEmptyClick ?? true
                  }
                  selectionZoneProps={
                    selection === undefined
                      ? undefined
                      : {
                        selection: selection,
                        isSelectedOnFocus: true,
                        disableAutoSelectOnInputElements: true,
                        selectionPreservedOnEmptyClick:
                          props.selectionPreservedOnEmptyClick ?? true,
                        selectionMode: selectionMode ?? SelectionMode.none,
                      }
                  }
                  selectionMode={selectionMode ?? SelectionMode.none}
                  selection={selection}
                  enableShimmer={!props.options.fetched}
                  {...detailsListProps}
                  columns={columns}
                  items={items}
                  detailsListStyles={subComponentStyles?.detailsList}
                />
              </GridViewSelection>
            </InfiniteScroll>
          )}
          {props.options.fetched && items.length === 0 && (
            <div className={(classNames as any).emptyMessageContainer}>
              <Text as="h3" className={(classNames as any).emptyMessage}>
                {emptyMessage}
              </Text>
            </div>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
