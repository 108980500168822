export class ResourceBase {
  private _path: string | undefined;
  private _t: (key: string, defaultValue?: string) => string;
  constructor(
    t: any,
    path?: string,
  ) {
    this._path = path;
    this._t = t;
  }

  public t(key: string, defaultValue?: string): string {
    return this._path
      ? this._t(`${this._path}:${key}`, defaultValue)
      : this._t(`${key}`, defaultValue);
  }

  public get version(): string {
    return this.t("version");
  }
}
