import { DefaultPalette, DetailsList, Icon, SelectionMode, Spinner } from '@fluentui/react';
import * as React from 'react';
import { IUserInvitation } from '../../../../../../models/IUserInvitation';
import { getInvitationListClassNames } from './InvitationList.styles';
import { useHighlightedDetailsRow } from "../../../../../../components/HighlightedDetailsRow"
import {
  IInvitationListProps
} from './InvitationList.types';



export const InvitationListComponent: React.FunctionComponent<
  IInvitationListProps
> = (props) => {
  const { styles, items, done, index } = props;
  const { classNames /* , subComponentStyles */ } = getInvitationListClassNames(
    styles,
    {
      ...props,
    }
  );
  const columns = [
    {
      key: "displayName",
      name: "Display Name",
      fieldName: "displayName",
      minWidth: 200,
    },
    {
      key: "email",
      name: "Email",
      fieldName: "email",
      minWidth: 200,
    },
    {
      key: "status",
      name: "Status",
      fieldName: "status",
      minWidth: 60,
      maxWidth: 60,
      onRender: (item: IUserInvitation) => done.filter(x => x.email === item.email).length === 0 ? <Spinner /> : <Icon iconName="Completed" styles={{
        root: {
          color: DefaultPalette.green,
        }
      }} />
    }
  ];
  return (
    <div className={classNames.root}>
      <DetailsList {...props} selectionMode={SelectionMode.none} columns={columns} items={items} styles={undefined} onRenderRow={useHighlightedDetailsRow((row) => row?.itemIndex !== undefined && row.itemIndex < index)} />
    </div>
  );
}
