import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../components/base";

export interface ISettingsSubComponentStyles {
  button?: IButtonStyles;
}

export interface ISettingsTokens {
  ver?: string;
}

export interface ISettingsStyles
  extends IComponentBaseStyles<ISettingsSubComponentStyles> {}

export interface ISettingsStyleProps
  extends IComponentBaseStyleProps<ISettingsTokens> {}

export const getSettingsClassNames = (
  styles?: IStyleFunctionOrObject<ISettingsStyleProps, ISettingsStyles>,
  props?: ISettingsStyleProps,
): IComponentBaseClassNames<
  ISettingsStyleProps,
  ISettingsStyles,
  ISettingsSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ISettingsStyleProps,
    ISettingsStyles,
    ISettingsSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<ISettingsStyleProps, ISettingsStyles> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: ISettingsStyleProps,
): ISettingsStyles => {
  return {
    root: ["app-Settings", {}],
  };
};
