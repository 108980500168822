import { AppNavigation } from "../../../../../../components/Layouts/AppNavigation";
import * as React from "react";
import { getUserNavigationClassNames } from "./UserNavigation.styles";
import { IUserNavigationProps } from "./UserNavigation.types";

export const UserNavigationComponent: React.FunctionComponent<
  IUserNavigationProps
> = (props) => {
  const { styles, theme, enabled, onNavigate, navigation, initialSelectedKey } =
    props;
  const { classNames /* , subComponentStyles */ } = getUserNavigationClassNames(
    styles,
    {
      ...props,
    },
  );
  return enabled ? (
    <div className={classNames.root}>
      <div className={classNames.root} onClick={(ev) => ev?.stopPropagation()}>
        <AppNavigation
          theme={theme}
          initialSelectedKey={initialSelectedKey}
          collapsible={false}
          collapsed={false}
          onNavigate={onNavigate}
          onCollapsed={() => {
            console.log("collapsed");
          }}
          navigation={navigation}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};
