import {
  CompactPeoplePicker,
  IPeoplePickerProps,
  IPersonaProps,
  ListPeoplePicker,
  NormalPeoplePicker,
  TextField,
} from "@fluentui/react";
import { FieldProps } from "formik";
import { createFakeEvent, getErrorMessage, handleProps, Omit } from "./utils";

export function mapFieldToPeoplePicker<
  V extends IPersonaProps[] = IPersonaProps[],
  FormValues = any,
>({
  form,
  field,
}: FieldProps<V, FormValues>): Pick<
  IPeoplePickerProps,
  "selectedItems" | "onChange" | "onBlur"
> {
  return {
    ...field,
    selectedItems: field.value,
    onBlur: () => field.onBlur(createFakeEvent(field)),
    onChange: (items) => form.setFieldValue(field.name, items),
  };
}
export type FormikPeoplePickerProps<
  V extends IPersonaProps[] = IPersonaProps[],
  FormValues = any,
> = Omit<IPeoplePickerProps, "selectedItems" | "onBlur" | "onChange"> &
  FieldProps<V, FormValues>;

export function FormikNormalPeoplePicker<
  V extends IPersonaProps[] = IPersonaProps[],
  FormValues = any,
>({ field, form, meta, ...props }: FormikPeoplePickerProps<V, FormValues>) {
  return (
    <TextField
      {...props}
      {...(field as any)}
      value={undefined}
      componentRef={undefined}
      errorMessage={getErrorMessage({ form, field, meta })}
      styles={getDefaultStyle(props)}
      onRenderInput={() => (
        <NormalPeoplePicker
          {...props}
          {...mapFieldToPeoplePicker({
            field,
            form,
            meta,
          })}
        />
      )}
    />
  );
}

export function FormikCompactPeoplePicker<
  V extends IPersonaProps[] = IPersonaProps[],
  FormValues = any,
>({ field, form, meta, ...props }: FormikPeoplePickerProps<V, FormValues>) {
  return (
    <TextField
      {...props}
      {...(field as any)}
      value={undefined}
      componentRef={undefined}
      styles={getDefaultStyle(props)}
      errorMessage={getErrorMessage({ form, field, meta })}
      onRenderInput={() => (
        <CompactPeoplePicker
          {...props}
          {...mapFieldToPeoplePicker({
            field,
            form,
            meta,
          })}
        />
      )}
    />
  );
}

export function FormikListPeoplePicker<
  V extends IPersonaProps[] = IPersonaProps[],
  FormValues = any,
>({ field, form, meta, ...props }: FormikPeoplePickerProps<V, FormValues>) {
  const fieldProps = handleProps(props);

  return (
    <TextField
      {...fieldProps}
      {...(field as any)}
      value={undefined}
      componentRef={undefined}
      errorMessage={getErrorMessage({ form, field, meta })}
      styles={getDefaultStyle(props)}
      readOnly
      onRenderInput={() => (
        <ListPeoplePicker
          {...fieldProps}
          {...mapFieldToPeoplePicker({
            field,
            form,
            meta,
          })}
        />
      )}
    />
  );
}

const getDefaultStyle = (props: any) => {
  const styles = props?.styles as any;

  const defaultStyle = {
    ...props.styles,
    fieldGroup: {
      ...styles?.fieldGroup,
      height: "100%",
      display: "block",
      border: "0px",
    },
    field: {
      ...styles?.field,
      height: "100%",
    },
    suffix: {
      alignItems: "start!important",
      ...styles?.suffix,
    },
  };
  return defaultStyle;
};
