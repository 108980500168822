import { Field } from "formik";
import { registerWidget } from "./registry";
import { FormikTextField } from "../FormikTextField";
import { FormikSpinButton } from "../FormikSpinButton";
import { getClassName } from "./utils";

// Define default widgets: textarea, money, date, address

export const defaultFormField = (config: any, formikParams: any) => {
  const extraProps = config ?? {};
  if (formikParams.handleChange != null)
    extraProps["onChange"] = formikParams.handleChange;
  return (
    <Field
      {...extraProps}
      name={config.name}
      type={config.type}
      className={getClassName(config)}
      id={config.name}
      component={FormikTextField}
    />
  );
};

export const numberFormField = (config: any, formikParams: any) => {
  const extraProps = config ?? {};
  if (formikParams.handleChange != null)
    extraProps["onChange"] = formikParams.handleChange;
  return (
    <Field
      {...extraProps}
      name={config.name}
      type={config.type}
      className={getClassName(config)}
      id={config.name}
      component={FormikSpinButton}
    />
  );
};

// <input type="text" ...> and variants
registerWidget("text", defaultFormField);
registerWidget("email", defaultFormField);
registerWidget("password", defaultFormField);
registerWidget("url", defaultFormField);
registerWidget("number", numberFormField);
registerWidget("search", defaultFormField);
registerWidget("tel", defaultFormField);
