import {
  Stack,
  StackItem,
  ISearchBoxProps,
  SearchBox,
  CommandBar,
  ITheme,
} from "@fluentui/react";
import * as React from "react";

export interface IMobileSearchProps {
  searchProps: ISearchBoxProps;
  backgroundColor?: string;
  theme?: ITheme;
  invertedBackgroundColor?: string;
  color?: string;
  invertedColor?: string;
  onDismiss: () => void;
}

export const MobileSearch: React.FunctionComponent<IMobileSearchProps> = ({
  searchProps,
  backgroundColor,
  theme,
  invertedBackgroundColor,
  color,
  invertedColor,
  onDismiss,
}) => {
  const topBarBackgroundColor =
    theme?.isInverted ?? false
      ? invertedBackgroundColor ?? theme?.semanticColors.menuBackground
      : backgroundColor ?? theme?.semanticColors.primaryButtonBackground;
  const topBarColor =
    theme?.isInverted ?? false
      ? invertedColor ?? theme?.semanticColors.bodyText
      : color ?? theme?.palette.white;
  return (
    <Stack
      horizontal
      styles={{
        root: {
          backgroundColor: topBarBackgroundColor,
          color: topBarColor,
        },
      }}
      grow
    >
      <StackItem align="center">
        <CommandBar
          onReduceData={() => undefined}
          items={[
            {
              key: "back",
              iconProps: {
                iconName: "NavigateBack",
                styles: {
                  root: {
                    color: topBarColor,
                    fontSize: "18px",
                  },
                },
              },
              buttonStyles: {
                root: {
                  backgroundColor: topBarBackgroundColor,
                  color: topBarColor,
                },
              },
              onClick: onDismiss,
            },
          ]}
          styles={{
            root: {
              paddingLeft: 0,
              paddingRight: 0,
              borderBottom: 0,
            },
          }}
        />
      </StackItem>
      <StackItem
        grow
        align="center"
        styles={{
          root: {
            ".ms-SearchBox": {
              borderRadius: "4px",
            },
            ".ms-SearchBox::after": {
              borderRadius: "4px",
            },
          },
        }}
      >
        <SearchBox
          {...searchProps}
          data-dashlane-rid="0"
          autoComplete="off"
          theme={theme}
        />
      </StackItem>
      <StackItem grow align="center">
        <div style={{ width: "5px" }} />
      </StackItem>
    </Stack>
  );
};
