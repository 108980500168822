import { IColumn } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router";
import { useMediaQueries } from "../../../../../../components/MediaQueries";
import { QueryBuilder } from "../../../../../../components/QueryBuilder";
import { GridViewDisplayMode } from "../../../../../../components/gridview/models/GridViewDisplayMode";
import { IGridViewOptions } from "../../../../../../components/gridview/models/IGridViewOptions";
import { meta } from "../../../../../../components/utils";
//import { renderSignInActivity } from "./Controls/SignInActivity/SignInActivity.render";
import { IUserSignInsProps } from "./UserSignIns.types";


const renderSignInActivity = (_: IUserSignInsProps, onItemInvoked: (item: any) => void): any => onItemInvoked !== undefined ? "" : " ";

export interface IUsersState {
  error?: string;
  options: IGridViewOptions;
  items?: any[];
  state?: string;
}

export const useUserSignIns = (props: IUserSignInsProps) => {
  const { selected, user } = props;
  const navigate = useNavigate();
  const [state, setState] = React.useState<IUsersState>({
    options: {
      fetched: false,
      displayMode: GridViewDisplayMode.list,
      query: new QueryBuilder().top(20),
    },
  });

  const { error, options, items } = state;

  const getItems = async (force: boolean) => {
    try {
      if (selected.id === undefined) throw new Error();
      const response = await fetch(`${meta("WWW-api-url")}/users/${selected.id}/activities`, {
        mode: 'cors',
        credentials: "include",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.id_token}`,
          "Content-Type": "application/json",
          "x-az-email": selected.email,
        }
      });
      const result = await response.json();

      setState({
        ...state,
        error: undefined,
        options: {
          ...options,
          fetched: true,
        },
        items: force
          ? result?.items ?? []
          : (items ?? []).concat(
            result?.items ?? [],
          ),
      });
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        error: "error",
        options: {
          ...options,
          fetched: true,
        },
        items: [],
      });
    }
  };

  React.startTransition(() => {
    state.items === undefined && getItems(true);
  });

  const onFetch = () => {
    options.query = options.query.skip(items?.length ?? 0);
    getItems(false);
  };

  const { mobile } = useMediaQueries();

  const onItemInvoked = (item: any) =>
    item.id !== undefined && navigate(item.id);

  const columns: IColumn[] = mobile
    ? [
      {
        key: "activity",
        name: "Sign Ins",
        fieldName: "timeGenerated",
        data: {
          type: "isodate",
          isSortable: true,
        },
        minWidth: 275,
        onRender: renderSignInActivity(props, onItemInvoked),
      },
    ]
    : [
      {
        key: "date",
        name: "Date",
        fieldName: "timeGenerated",
        minWidth: 220,
        maxWidth: 220,
        data: {
          type: "isodate",
          isSortable: true,
        },
        visible: true
      },
      {
        key: "name",
        name: "Event Name",
        fieldName: "name",
        minWidth: 240,
        maxWidth: 240,
        visible: true
      },
      {
        key: "name",
        name: "City",
        fieldName: "clientCity",
        minWidth: 240,
        maxWidth: 240
      },
      {
        key: "name",
        name: "Country/Region",
        fieldName: "clientCountryOrRegion",
        minWidth: 240,
        maxWidth: 240
      }
    ].filter((x) => x.visible ?? true);

  const onSort = (fieldName: string, desc: boolean) => {
    options.query = options.query
      .skip(0)
      .top(20)
      .orderBy(...[desc ? fieldName : `${fieldName} desc`]);
    getItems(true);
  };

  const onSelectionChange = () => { };

  return {
    error,
    onFetch,
    onSort,
    options,
    items,
    onItemInvoked,
    onSelectionChange,
    columns,
  };
};
