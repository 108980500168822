import { INavLinkGroup } from "@fluentui/react";
import { IUser } from "../../../../models/IUser";

export const useUserBladeNavigation = (user?: IUser): INavLinkGroup[] => [
  {
    links: [
      {
        key: "overview",
        iconProps: {
          iconName: "Info",
        },
        name: "Overview",
        url: `/users/${user?.id ?? "0"}`,
        href: `/users/${user?.id ?? "0"}`,
      },
      {
        key: "activities",
        iconProps: {
          iconName: "UserEvent"
        },
        name: "Activity logs",
        url: `/users/${user?.id ?? "0"}/activities`,
        href: `/users/${user?.id ?? "0"}/activities`,
      }
    ],
  },
  {
    name: "Manage",
    links: [
      {
        key: "profile",
        iconProps: {
          iconName: "Contact",
        },
        name: "Profile",
        url: `/users/${user?.id ?? "0"}/profile`,
        href: `/users/${user?.id ?? "0"}/profile`,
      },
      {
        key: "security",
        iconProps: {
          iconName: "LaptopSecure",
        },
        name: "Security",
        url: `/users/${user?.id ?? "0"}/security`,
        href: `/users/${user?.id ?? "0"}/security`,
      },
      {
        key: "apps",
        iconProps: {
          iconName: "AllApps",
        },
        name: "Apps",
        url: `/users/${user?.id ?? "0"}/apps`,
        href: `/users/${user?.id ?? "0"}/apps`,
      },
    ],
  },
  {
    name: "System",
    links: [
      {
        key: "export",
        iconProps: {
          iconName: "DownloadDocument",
        },
        name: "Export",
        url: `/users/${user?.id ?? "0"}/export`,
        href: `/users/${user?.id ?? "0"}/export`,
      },
    ],
  },
];
