import { styled } from "@fluentui/react";
import { IDepartmentsProps } from "./Departments.types";
import { getStyles, IDepartmentsStyleProps, IDepartmentsStyles } from "./Departments.styles";
import { DepartmentsComponent } from "./Departments.base";

/**
 * Departments
 */
export const Departments = styled<IDepartmentsProps, IDepartmentsStyleProps, IDepartmentsStyles>(
  DepartmentsComponent,
  getStyles,
);
