import { MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import { GridView } from "../../components/gridview/GridView/GridView";
import { IApplicationsProps } from "./Applications.types";
import { useApplications } from "./Applications.use";
//import { CommandBar } from "@fluentui/react";
import { Route, Routes } from "react-router";
//import { UserSignInBlade } from "./Controls/UserSignInBlade/UserSignInBlade";
import { ApplicationResource } from "./ApplicationsResource";
import { Breadcrumb } from "@fluentui/react";
export const ApplicationsComponent: React.FunctionComponent<
  IApplicationsProps
> = (props) => {
  const { error,addingApps,onCommonDismiss,RemovingApps,selectedApps,selection,modal, item,breadcrumb,...gridProps  } = useApplications(props);
  const gridview = () =>  (
    <>
      <div>
        <Breadcrumb onGrowData={() => undefined} items={breadcrumb} />
        <GridView selectionMode={2} selection={selection} {...props} {...gridProps}>
          {error !== undefined && (
            <MessageBar messageBarType={MessageBarType.error}>We cannot display Apps at the moment.</MessageBar>
          )}
        </GridView>
      </div>
    </>
  );
  return (
    <>
      {modal ? (
        gridview()
      ) : (
        <Routes>
          <Route
            path=":applications/*"
            element={<ApplicationResource {...props} item={item} styles={undefined} />}
          />
          <Route
            path=":applications/*"
            element={<ApplicationResource {...props}  item={item} styles={undefined} />}
          />
          <Route path="" element={gridview()} />
        </Routes>
      )}
    </>
  );
};
