import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../base";

export interface IShimmeredListSubComponentStyles {
  button?: IButtonStyles;
}

export interface IShimmeredListTokens {
  ver?: string;
}

export interface IShimmeredListStyles
  extends IComponentBaseStyles<IShimmeredListSubComponentStyles> { }

export interface IShimmeredListStyleProps
  extends IComponentBaseStyleProps<IShimmeredListTokens> { }

export const getShimmeredListClassNames = (
  styles?: IStyleFunctionOrObject<
    IShimmeredListStyleProps,
    IShimmeredListStyles
  >,
  props?: IShimmeredListStyleProps,
): IComponentBaseClassNames<
  IShimmeredListStyleProps,
  IShimmeredListStyles,
  IShimmeredListSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IShimmeredListStyleProps,
    IShimmeredListStyles,
    IShimmeredListSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IShimmeredListStyleProps,
  IShimmeredListStyles
> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IShimmeredListStyleProps): IShimmeredListStyles => {
    return {
      root: ["app-ShimmeredList", {}],
    };
  };
