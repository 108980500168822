import { Text } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { getLandingClassNames } from "./Landing.styles";
import { ILandingProps } from "./Landing.types";

export const LandingComponent: React.FunctionComponent<ILandingProps> = (
  props,
) => {
  const { styles } = props;
  const { classNames /* , subComponentStyles */ } = getLandingClassNames(
    styles,
    {
      ...props,
    },
  );
  const { t } = useTranslation();
  return (
    <div className={classNames.root}>
      <Text variant="xLarge">{t("Landing.Title", "Welcome to CGCD Adminsitration Center")}</Text>
      <div>
        <div style={{ height: "10px" }}></div>
        <img src="https://images.unsplash.com/photo-1655635643486-a17bc48771ff?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1232&q=80" />
      </div>
    </div>
  );
};
