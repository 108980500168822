import { Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import { IDepartmentResourceProps } from "./DepartmentResource.types";
import { useDepartmentResource } from "./DepartmentResource.use";
import { useTranslation } from "react-i18next";
import { Organizations } from "../../Organizations";
// import { Organizations } from "../../../Organizations/Organizations";

export const DepartmentResourceComponent: React.FunctionComponent<
  IDepartmentResourceProps
> = (props) => {
  const { classNames, item } = useDepartmentResource(props);
  console.log(item)
  const { t } = useTranslation();
  return (
    <div className={classNames.root}>
      <Pivot>
        <PivotItem key={"org"} headerText={t("Common.Organizations", "Organizations")}>
          <Organizations {...props} item={item} styles={undefined} /> 
        </PivotItem>
        <PivotItem key={"users"} headerText={t("Common.Users", "Users")}>
          {/* <Subnets {...props} styles={undefined} /> */}
        </PivotItem>
      </Pivot>
    </div>
  );
};
