import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IComponentBaseStyleProps, IComponentBaseStyles, IComponentBaseClassNames, ComponentStylesLoader } from "../../../../components/base";

export interface IAddAppsSubComponentStyles {
  button?: IButtonStyles;
}

export interface IAddAppsTokens {
  ver?: string;
}

export interface IAddAppsStyles extends IComponentBaseStyles<IAddAppsSubComponentStyles> {

}

export interface IAddAppsStyleProps extends IComponentBaseStyleProps<IAddAppsTokens> {

}

export const getAddAppsClassNames = (
  styles?: IStyleFunctionOrObject<
    IAddAppsStyleProps,
    IAddAppsStyles
  >,
  props?: IAddAppsStyleProps
): IComponentBaseClassNames<
  IAddAppsStyleProps,
  IAddAppsStyles,
  IAddAppsSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IAddAppsStyleProps,
    IAddAppsStyles,
    IAddAppsSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IAddAppsStyleProps,
  IAddAppsStyles

> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IAddAppsStyleProps): IAddAppsStyles => {
    return {
      root: ['app-BulkInvitation', {

      }]
    };
  };
