import { styled } from "@fluentui/react/lib/Utilities";
import { IOrganizationResourceProps } from "./OrganizationResource.types";
import {
  getStyles,
  IOrganizationResourceStyleProps,
  IOrganizationResourceStyles,
} from "./OrganizationResource.styles";
import { OrganizationResourceComponent } from "./OrganizationResource.base";

/**
 * OrganizationResource
 */
export const OrganizationResource = styled<
  IOrganizationResourceProps,
  IOrganizationResourceStyleProps,
  IOrganizationResourceStyles
>(OrganizationResourceComponent, getStyles);
