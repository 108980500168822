import { IconButton, Stack, StackItem, Text } from "@fluentui/react";
import { FieldArray } from "formik";
import React from "react";
import { makeWidget, registerWidget } from "./registry";

export const arrayFormField = (config: any, formikParams: any) => {
  const {
    name,
    arrayType,
    insertAtTop = false,
    translatedType,
    property,
    label,
    onRenderAddButton,
  } = config;

  const getValues = (items: any) =>
    items === undefined ? [] : (items?.[name] as any[]) ?? [];
  const propertyValue = property === undefined ? "" : `.${property}`;
  const [isOpen, setIsOpen] = React.useState(false);
  const extraProps =
    config.arrayType === "translated"
      ? {
        isOpen,
        setIsOpen,
      }
      : {};

  const onClick =
    (insert: (index: number, item: any) => void, push: (item: any) => void) =>
      (ev: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        const newItem = config.onAdd?.() ?? {};
        insertAtTop && newItem !== undefined && insert(0, newItem);
        !insertAtTop && newItem !== undefined && push(newItem);
      };

  return (
    <FieldArray name={name}>
      {({ form, remove, insert, push }: any) => (
        <Stack grow tokens={{ childrenGap: 10 }}>
          {label !== undefined && (
            <Stack>
              <Text styles={{ root: { fontWeight: 600 } }}>
                {label ?? name}
              </Text>
            </Stack>
          )}
          {getValues(form?.values).map((_x, index) => (
            <Stack
              key={index.toString()}
              grow
              horizontal
              tokens={{ childrenGap: 5 }}>
              <StackItem grow>
                {makeWidget(
                  {
                    ...config,
                    label: "",
                    headerText: label ?? name,
                    name: `${name}[${index}]${propertyValue}`,
                    type: arrayType ?? "text",
                    arrayType: translatedType ?? "text",
                    ...extraProps,
                  },
                  formikParams,
                )}
              </StackItem>
              <StackItem>
                <IconButton
                  iconProps={{
                    iconName: "Cancel",
                  }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    remove?.(index);
                  }}
                />
              </StackItem>
            </Stack>
          ))}
          <Stack grow>
            <StackItem align="end">
              {onRenderAddButton === undefined ? (
                <IconButton
                  iconProps={{
                    iconName: "Add",
                  }}
                  onClick={onClick(insert, push)}
                />
              ) : (
                onRenderAddButton({
                  ...config,
                  insert,
                  push,
                  onClick: onClick(insert, push),
                })
              )}
            </StackItem>
          </Stack>
        </Stack>
      )}
    </FieldArray>
  );
};

registerWidget("array", arrayFormField);
