import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../../../../components/base";

export interface IUserSecuritySubComponentStyles {
  button?: IButtonStyles;
}

export interface IUserSecurityTokens {
  ver?: string;
}

export interface IUserSecurityStyles
  extends IComponentBaseStyles<IUserSecuritySubComponentStyles> { }

export interface IUserSecurityStyleProps
  extends IComponentBaseStyleProps<IUserSecurityTokens> { }

export const getUserSecurityClassNames = (
  styles?: IStyleFunctionOrObject<IUserSecurityStyleProps, IUserSecurityStyles>,
  props?: IUserSecurityStyleProps,
): IComponentBaseClassNames<
  IUserSecurityStyleProps,
  IUserSecurityStyles,
  IUserSecuritySubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IUserSecurityStyleProps,
    IUserSecurityStyles,
    IUserSecuritySubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IUserSecurityStyleProps,
  IUserSecurityStyles
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (_props: IUserSecurityStyleProps): IUserSecurityStyles => {
  return {
    root: ["app-UserSecurity", {}],
  };
};
