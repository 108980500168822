import {
  IButtonStyles,
  IDetailsListStyles,
  IStackItemStyles,
  IStackStyles,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  ComponentStylesLoader,
  IComponentBaseClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
} from "../../base";
import { MediaQueries } from "../../MediaQueries";

export interface IGridViewSubComponentStyles {
  button?: IButtonStyles;
  detailsList?: Partial<IDetailsListStyles>;
  leftPane?: Partial<IStackItemStyles>;
  main?: Partial<IStackStyles>;
}

export interface IGridViewTokens {
  ver?: string;
}

export interface IGridViewStyles
  extends IComponentBaseStyles<IGridViewSubComponentStyles> {
  emptyMessageContainer?: IStyle;
  emptyMessage?: IStyle;
}

export interface IGridViewStyleProps
  extends IComponentBaseStyleProps<IGridViewTokens> {
  sortable: boolean;
}

export const getGridViewClassNames = (
  styles?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>,
  props?: IGridViewStyleProps,
): IComponentBaseClassNames<
  IGridViewStyleProps,
  IGridViewStyles,
  IGridViewSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IGridViewStyleProps,
    IGridViewStyles,
    IGridViewSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<IGridViewStyleProps, IGridViewStyles> = (
  props: IGridViewStyleProps,
): IGridViewStyles => {
  return {
    root: [
      "app-GridView",
      {
        ".app-GridViewCommandBar": {
          backgroundColor: "transparent",
        },
        ".ms-CommandBar": {
          backgroundColor: "transparent",
          borderBottom: `1px solid ${props.theme?.palette.neutralLighter}`,
        },
        ".ms-Button.ms-Button--commandBar.ms-CommandBarItem-link": {
          backgroundColor: "transparent!important",
        },
        ".ms-List::after": {
          backgroundImage: "unset",
        },
      },
    ],
    emptyMessageContainer: {
      width: "100%",
      textAlign: "center",
      marginTop: "60px",
      selectors: {
        [MediaQueries.mobile]: {
          marginTop: "40px",
        },
      },
    },
    emptyMessage: {
      fontStyle: "italic",
    },
    subComponentStyles: {
      detailsList: {
        root: {
          ".ms-DetailsHeader": {
            height: "32px",
            lineHeight: "32px",
            paddingTop: "0px",
          },
          ".ms-DetailsHeader-cell.ms-DetailsHeader-cellIsCheck.ms-DetailsRow-check--isHeader.ms-Check-checkHost":
          {
            marginBottom: "8px",
          },
          ".ms-DetailsHeader .ms-DetailsHeader-cell.is-actionable": {
            cursor: props.sortable ? "pointer" : undefined,
            backgroundColor: "#f8f8f8f8",
          },
          ".ms-DetailsHeader .ms-DetailsHeader-cell.is-actionable.ms-NonSortable":
          {
            cursor: props.sortable ? "default!important" : undefined,
          },
          ".ms-List::after": {
            backgroundImage: "unset",
          },
        },
      },
      leftPane: {
        root: {
          backgroundColor: props.theme?.palette.neutralLighter,
        },
      },
      main: {
        root: {
          position: "relative",
          maxHeight: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        },
      },
    },
  };
};
