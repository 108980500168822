import { ITextFieldProps, MaskedTextField, TextField } from "@fluentui/react";
import { FieldProps } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getErrorMessage, handleProps, isReadOnly, Omit } from "./utils";

export function mapFieldToTextField<
  V extends string = string,
  FormValues = any,
>({
  form,
  field,
  meta,
}: FieldProps<V, FormValues>): Pick<
  ITextFieldProps,
  "value" | "name" | "type" | "onChange" | "onBlur" | "errorMessage" | "form"
> {
  const { t } = useTranslation();
  const { value } = field;
  const engine = [
    {
      condition:
        (field as any).type === "boolean" || typeof value === "boolean",
      format: () =>
        field.value ? t("globals:YesLabel", "Yes") : t("globals:NoLabel", "No"),
    },
    {
      condition: (value as any) instanceof Date,
      format: () => moment(value).format((field as any).format ?? "LLLL"),
    },
    {
      condition: (field as any).type === "date",
      format: () =>
        value === undefined || value == null
          ? ""
          : moment(new Date(Date.parse(value))).format(
              (field as any).format ?? "LLLL",
            ),
    },
    {
      condition: true,
      format: () => value,
    },
  ];

  const formatted = engine.filter((x) => x.condition)?.[0].format() ?? value;

  return {
    ...field,
    type:
      (field as any).type === "boolean" || (field as any).type === "date"
        ? "text"
        : (field as any).type,
    value: formatted,
    errorMessage: getErrorMessage({ form, field, meta }),
  };
}

export function mapFieldToMaskedTextField<V extends string, FormValues = any>({
  form,
  field,
  meta,
}: FieldProps<V, FormValues>): Pick<
  ITextFieldProps,
  "value" | "name" | "onChange" | "onBlur" | "errorMessage" | "form"
> {
  return {
    ...mapFieldToTextField({ form, field, meta }),
    // ev hsa wrong balue for MaskedTextField
    onChange: (_, value) => form.setFieldValue(field.name, value),
  };
}

export type FormikTextFieldProps<V extends string, FormValues = any> = Omit<
  ITextFieldProps,
  "value" | "name" | "onChange" | "onBlur" | "form"
> &
  FieldProps<V, FormValues>;

export function FormikTextField<V extends string, FormValues = any>({
  field,
  form,
  meta,
  ...props
}: FormikTextFieldProps<V, FormValues>) {
  const { errorMessage, ...fieldProps } = mapFieldToTextField({
    field,
    form,
    meta,
  });

  const readOnly = isReadOnly(field, form, props);

  return (
    <TextField
      {...handleProps(props)}
      {...fieldProps}
      readOnly={readOnly}
      errorMessage={errorMessage}
    />
  );
}

export function FormikMaskedTextField<V extends string, FormValues = any>({
  field,
  form,
  meta,
  ...props
}: FormikTextFieldProps<V, FormValues>) {
  const { errorMessage, ...fieldProps } = mapFieldToMaskedTextField({
    field,
    form,
    meta,
  });

  const readOnly = isReadOnly(field, form, props);

  return (
    <MaskedTextField
      {...handleProps(props)}
      {...fieldProps}
      readOnly={readOnly}
      errorMessage={errorMessage}
    />
  );
}
