import {
  CommandBar,
  Icon,
  ITheme,
  Nav,
  Panel,
  Persona,
  Separator,
  Stack,
  StackItem,
  Text
} from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { trigger } from "../events";
import { IdentityUser } from "../IdentityUser";

export interface IUserPanelProps {
  user: any;
  theme?: ITheme;
  onSignout?: () => void;
  onUserChangeRequested?: (id: string, action?: string) => void;
}

export interface IUSerPanelState {
  settings: boolean;
}

export const UserPanel: React.FunctionComponent<IUserPanelProps> = ({
  user,
  theme,
  onSignout,
}) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState<IUSerPanelState>({
    settings: false
  });

  const persona = <Persona
    initialsColor={
      theme?.isInverted ?? false
        ? theme?.semanticColors.bodyText
        : theme?.palette.themePrimary
    }
    text={IdentityUser.nameOf(user)}
    coinSize={66}
    secondaryText={IdentityUser.emailOf(user)}
    coinProps={{
      onRenderInitials: () => (
        <Icon
          theme={theme}
          iconName="IDBadge"
          styles={{
            root: { fontSize: "36px", fontWeight: "400!important" },
          }}
        />
      ),
    }}
  />

  return (
    <>
      <Panel isOpen={state.settings} headerText="Account Settings" onDismiss={() => setState({
        settings: false
      })}>
        <Stack grow tokens={{ childrenGap: 5 }}>
          <div style={{ height: "20px" }}></div>
          {persona}
          <Separator />
          <CommandBar
            styles={{
              root: {
                borderBottom: "0px!important"
              },
              primarySet: {
                borderBottom: "0px!important"
              }
            }}
            items={[{
              key: "signout",
              text: t(
                "Layouts.AppBar.Me.SignOut",
                "Sign out",
              ) ?? "Sign out",
              onClick: onSignout,
            }]} />
          <div style={{ height: "40px" }}></div>
          <h3>Theme</h3>
          <Nav groups={[
            {
              name: "",
              links: [
                {
                  key: "light",
                  disabled: !(theme?.isInverted ?? false),
                  name: "Light",
                  url: "#",
                  onClick: (ev) => {
                    ev?.preventDefault();
                    ev?.stopPropagation();
                    trigger("onThemeChanged", {
                      themeName: "light"
                    })
                  },
                  iconProps: {
                    iconName: "Light"
                  }
                },
                {
                  key: "dark",
                  disabled: theme?.isInverted ?? false,
                  name: "Dark",
                  url: "#",
                  onClick: (ev) => {
                    ev?.preventDefault();
                    ev?.stopPropagation();
                    trigger("onThemeChanged", {
                      themeName: "dark"
                    })
                  },
                  iconProps: {
                    iconName: "ClearNight"
                  }
                }
              ]
            }
          ]} />
        </Stack>
      </Panel>
      <Stack grow verticalFill>
        <Stack horizontal grow>
          <StackItem
            grow
            styles={{
              root: {
                padding: "10px",
              },
            }}
          >
            <Text>{" "}</Text>
          </StackItem>
          <StackItem>
            <CommandBar
              styles={{
                root: {
                  paddingRight: "1px",
                  borderBottom: 0,
                  backgroundColor: "transparent",
                },
                primarySet: { backgroundColor: "transparent" },
              }}
              onReduceData={() => undefined}
              items={[
                {
                  key: "signout",
                  text: t(
                    "Layouts.AppBar.Me.SignOut",
                    "Sign out",
                  ) ?? "Sign out",
                  iconProps: {
                    iconName: "SignOut",
                    styles: {
                      root: {
                        color:
                          theme?.isInverted ?? false
                            ? theme?.semanticColors.bodyText
                            : theme?.palette.themePrimary,
                      },
                    },
                  },
                  onClick: (ev) => {
                    ev?.preventDefault();
                    ev?.stopPropagation();
                    onSignout?.();
                    return false;
                  },
                },
              ]}
            />
          </StackItem>
        </Stack>
        <div style={{ height: "5px" }} />
        <Stack
          grow
          verticalFill
          styles={{
            root: {
              marginLeft: "10px",
            },
          }}
        >
          {persona}
          <Stack styles={{ root: { paddingLeft: "42px" } }}>
            <CommandBar
              onReduceData={() => undefined}
              theme={theme}
              styles={{
                root: {
                  borderBottom: 0,
                  backgroundColor: "transparent",
                  ":hover": { backgroundColor: "transparent" },
                },
                primarySet: {
                  backgroundColor: "transparent",
                  ":hover": { backgroundColor: "transparent" },
                },
              }}
              items={[
                {
                  key: "account",
                  text: t(
                    "Layouts.AppBar.Me.Account",
                    "Account Settings",
                  ) ?? "Account Settings",
                  iconProps: {
                    iconName: "AccountManagement",
                    styles: {
                      root: {
                        backgroundColor: "transparent",
                        color:
                          theme?.isInverted ?? false
                            ? theme?.semanticColors.bodyText
                            : theme?.palette.themePrimary,
                        ":hover": {
                          backgroundColor: "transparent",
                          color:
                            theme?.isInverted ?? false
                              ? theme?.semanticColors.bodyText
                              : theme?.palette.themePrimary,
                        },
                      },
                    },
                  },
                  onClick: () => {
                    setState({
                      settings: true
                    })
                  },
                },
              ]}
            />
          </Stack>
        </Stack>
        <div style={{ clear: "both", height: "14px" }} />
      </Stack>
    </>
  );
};
