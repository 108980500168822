import { IDetailsRowProps } from "@fluentui/react";

const RowHeight = 32;

export const useCompactRow = (
  props?: IDetailsRowProps,
  defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null,
) => {
  if (!props) return null;
  props.styles = {
    cell: {
      paddingTop: 6,
      paddingBottom: 0,
      minHeight: 0,
      height: RowHeight,
      alignSelf: "center",
      ".ms-Check-circle": {
        width: "15px",
        height: "15px",
      },
      ".ms-Check-check": {
        width: "15px",
        height: "15px",
      },
      ".ms-DetailsHeader-check": {
        paddingBottom: "3px",
      },
    },
    root: {
      height: RowHeight,
      minHeight: 0,
      ".ms-DetailsHeader-cell.ms-DetailsHeader-cellIsCheck": {
        height: RowHeight,
      },
      ".ms-DetailsHeader.ms-DetailsHeader-cell.is-actionable": {
        backgroundColor: "unset",
      },
    },
    checkCell: {
      height: RowHeight,
      minHeight: 0,
    },
  };
  return defaultRender?.(props) ?? null;
};
