import { styled } from "@fluentui/react";
import { IPropertyBooleanFieldProps } from "./PropertyBooleanField.types";
import {
  getStyles,
  IPropertyBooleanFieldStyleProps,
  IPropertyBooleanFieldStyles,
} from "./PropertyBooleanField.styles";
import { PropertyBooleanFieldComponent } from "./PropertyBooleanField.base";

/**
 * PropertyBooleanField
 */
export const PropertyBooleanField = styled<
  IPropertyBooleanFieldProps,
  IPropertyBooleanFieldStyleProps,
  IPropertyBooleanFieldStyles
>(PropertyBooleanFieldComponent, getStyles);
