import { styled } from "@fluentui/react";
import { IUserExportProps } from "./UserExport.types";
import {
  getStyles,
  IUserExportStyleProps,
  IUserExportStyles,
} from "./UserExport.styles";
import { UserExportComponent } from "./UserExport.base";

/**
 * UserExport
 */
export const UserExport = styled<
  IUserExportProps,
  IUserExportStyleProps,
  IUserExportStyles
>(UserExportComponent, getStyles);
