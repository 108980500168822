import { User } from "oidc-react";
import { meta } from "./components/utils";

export const InventoryService = {
    getDepartments: async (user: User) => {
        const response = await fetch(`${meta("WWW-api-url")}/departments`, {
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`
            }
        });
        const payload = await response.json();
        return payload?.items ?? [];
    },
    getOrganizations: async (user: User,) => {
        const response = await fetch(`${meta("WWW-api-url")}/organizations`, {
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`
            }
        });
        const payload = await response.json();
        return payload ?? [];
    },
    getOrganizationsByDepId: async (user: User,id:any) => {
        const response = await fetch(`${meta("WWW-api-url")}/departments/${id}/organizations`, {
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`
            }
        });
        const payload = await response.json();
        return payload ?? [];
    },
    getOrganizationByDepIdByorgId: async (user: User,depid:any,orgid:any) => {
        const response = await fetch(`${meta("WWW-api-url")}/departments/${depid}/organizations${orgid}`, {
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`
            }
        });
        const payload = await response.json();
        return payload ?? [];
    },
    getGroupsMap: async (user: User,) => {
        const response = await fetch(`${meta("WWW-api-url")}/groupMappings`, {
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`
            }
        });
        const payload = await response.json();
        return payload?.item ?? [];
    },
    getGroupsMapAppid: async (user: User,AppId:string) => {
        const response = await fetch(`${meta("WWW-api-url")}/groupMappings/${AppId}`, {
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`
            }
        });
        const payload = await response.json();
        return payload?.item ?? [];
    },
    getGroupsMapAppidUserid: async (user: User,AppId:string,Userid:string) => {
        const response = await fetch(`${meta("WWW-api-url")}/groupMappings/${AppId}/users/${Userid}`, {
            mode: 'cors',
            credentials: "include",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${user.id_token}`
            }
        });
        const payload = await response.json();
        return payload?.items ?? [];
    },
}