import { Text, Icon, Stack, TooltipHost } from "@fluentui/react";
import { copyToClipboard } from "../../../copyToClipboard";
import { FormikTextField } from "../../../formik/FormikTextField";
import { Field } from "formik";
import * as React from "react";
import { getPropertyNumberFieldClassNames } from "./PropertyNumberField.styles";
import { IPropertyNumberFieldProps } from "./PropertyNumberField.types";

export const PropertyNumberFieldComponent: React.FunctionComponent<
  IPropertyNumberFieldProps
> = (props) => {
  const { styles, underlined, name, description, value, readOnly, required } =
    props;
  const { classNames, subComponentStyles } = getPropertyNumberFieldClassNames(
    styles,
    {
      ...props,
    },
  );
  const copyTo = (
    <>
      {value !== undefined && value != null && (
        <Icon
          onClick={() => copyToClipboard(value)}
          iconName="Copy"
          title={"Copy to clipboard"}
          styles={subComponentStyles?.suffix}
        />
      )}
    </>
  );
  return (
    <Field
      {...props}
      name={name}
      required={readOnly === undefined || readOnly ? false : required}
      data-form-type="other"
      autoComplete="off"
      type="number"
      description={undefined}
      onRenderLabel={
        description === undefined || description === ""
          ? undefined
          : () => (
            <Stack
              styles={subComponentStyles?.label}
              horizontal
              tokens={{ childrenGap: 5 }}
            >
              <Text>{props.label}</Text>
              <TooltipHost content={description}>
                <Icon
                  iconName="Info"
                  styles={{
                    root: {
                      fontSize: "12px",
                      cursor: "pointer",
                    },
                  }}
                />
              </TooltipHost>
            </Stack>
          )
      }
      component={FormikTextField}
      className={classNames.root}
      styles={subComponentStyles?.field}
      borderless={readOnly === undefined || readOnly}
      readOnly={readOnly === undefined ? true : readOnly}
      underlined={readOnly === undefined ? underlined ?? true : readOnly}
      onRenderSuffix={() => copyTo}
      prefix={readOnly === undefined || readOnly === true ? ":" : undefined}
    />
  );
};
