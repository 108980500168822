import { initializeIcons } from "@fluentui/react";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { App } from "./App";
import { Injector } from "./components/di/di";
import { initializeIntl } from "./components/i18n";
import { LocaleHost } from "./components/LocaleHost";
import "./index.css";
import { INotificationManager, NotificationManager } from "./modules/Notifications/NotificationManager";

initializeIcons();
const container = document.getElementById("root");
if (container == null) throw new Error("no_root");
const root = ReactDOMClient.createRoot(container);
window.di = new Injector().with<INotificationManager>(
  "notifications",
  NotificationManager,
);
initializeIntl(["admin", "globals"],
  localStorage.getItem("mkt") ?? "fr-CA").then(() => {
    root.render(
      <React.Suspense fallback={<div></div>}>
        <LocaleHost>
          <App />
        </LocaleHost>
      </React.Suspense>,
    )
  });
