import { IconButton, Link, Stack } from "@fluentui/react";
import React from "react";
import { copyToClipboard } from "../../../../components/copyToClipboard";

interface INotificationItemProps {
    url?: string;
    text?: string;
}

export const NotificationItem: React.FunctionComponent<INotificationItemProps> = ({ url, text }) =>
    <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign="center">
        <Link target="_blank" href={url}>{text}</Link>
        <IconButton iconProps={{
            iconName: "Copy"
        }} onClick={() => {
            url !== undefined && copyToClipboard(url);
        }} />
    </Stack>

export const asNotificationItem = (props: INotificationItemProps) => <NotificationItem {...props} />