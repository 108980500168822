import {
  IButtonStyles,
  IMessageBarStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../components/base";
import { IGridViewStyles } from "../../components/gridview/GridView/GridView.styles";

export interface IInvitationsSubComponentStyles {
  button?: IButtonStyles;
  grid?: IGridViewStyles;
  error?: IMessageBarStyles;
}

export interface IInvitationsTokens {
  ver?: string;
}

export interface IInvitationsStyles
  extends IComponentBaseStyles<IInvitationsSubComponentStyles> { }

export interface IInvitationsStyleProps
  extends IComponentBaseStyleProps<IInvitationsTokens> { }

export const getInvitationsClassNames = (
  styles?: IStyleFunctionOrObject<IInvitationsStyleProps, IInvitationsStyles>,
  props?: IInvitationsStyleProps,
): IComponentBaseClassNames<
  IInvitationsStyleProps,
  IInvitationsStyles,
  IInvitationsSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IInvitationsStyleProps,
    IInvitationsStyles,
    IInvitationsSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<IInvitationsStyleProps, IInvitationsStyles> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IInvitationsStyleProps,
): IInvitationsStyles => {
  return {
    root: [
      "app-Invitations",
      {
        width: "100%",
      },
    ],
    subComponentStyles: {
      grid: {
        subComponentStyles: {
          detailsList: {
            root: {
              ".ms-List::after": {
                backgroundImage: "unset",
              },
            },
          },
        },
      },
    },
  };
};
