import { Icon, Stack, Text } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router";
import {
  notificationColor,
  notificationIcon,
} from "../../../../models/INotificationItem";
import { getNotificationItemClassNames } from "./NotificationItem.styles";
import { INotificationItemProps } from "./NotificationItem.types";

export const NotificationItemComponent: React.FunctionComponent<
  INotificationItemProps
> = (props) => {
  const { styles, theme, item } = props;
  const { classNames /* , subComponentStyles */ } =
    getNotificationItemClassNames(styles, {
      ...props,
    });
  const navigate = useNavigate();
  const onNavigate = () => navigate("/activities");

  return (
    <div>
      <Stack className={classNames.root} onClick={onNavigate}>
        <div
          style={{
            width: "100%",
            padding: "12px",
            borderBottom: `1px solid ${theme?.palette.neutralLighter}`,
          }}
        >
          <div>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 10 }}
            >
              <Icon
                styles={{
                  root: {
                    color: notificationColor(item, theme),
                  },
                }}
                iconName={notificationIcon(item)}
              />
              <Text variant="large">{item.title}</Text>
            </Stack>
          </div>
          <div style={{ padding: "12px" }}>
            <div>
              <Text>{item.description}</Text>
            </div>
          </div>
          <Stack grow horizontalAlign="end">
            {item.timestamp.toDateString()}
          </Stack>
        </div>
      </Stack>
    </div>
  );
};
