import {
  Dropdown,
  IDropdownOption,
  IDropdownProps,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { FieldProps } from "formik";
import React from "react";
import { FormikTextField } from "./FormikTextField";
import {
  getErrorMessage,
  handleProps,
  invokeAll,
  isReadOnly,
  Omit,
} from "./utils";

export function mapFieldToDropdown<
  V extends string | number | string[] | number[] | null,
  FormValues = any,
  >({
    form,
    field,
    meta,
  }: FieldProps<V, FormValues>): Pick<
    IDropdownProps,
    "selectedKey" | "selectedKeys" | "onDismiss" | "onChange" | "errorMessage"
  > {
  const multiSelect = Array.isArray(field.value);
  const shared = {
    ...field,
    readOnly: (field as any).readOnly ?? form?.status?.readOnly,
    errorMessage: getErrorMessage({ field, form, meta }),
    // onDismiss: () => field.onBlur(createFakeEvent(field)),
  };

  return multiSelect
    ? {
      ...shared,
      selectedKeys: field.value as string[] | number[],
      onChange: (_, option) => {
        const value = field.value as any[];

        if (typeof option !== "undefined") {
          if (option.selected) {
            form.setFieldValue(field.name, [...value, option.key]);
          } else {
            const idx = (field.value as any[]).indexOf(option.key);

            if (idx !== -1) {
              form.setFieldValue(field.name, [
                ...value.slice(0, idx),
                ...value.slice(idx + 1),
              ]);
            }
          }
        }
      },
    }
    : {
      ...shared,
      selectedKey: field.value as string | number | null,
      onChange: (_, option) => {
        form.setFieldValue(field.name, option?.key);
      },
    };
}

const getDependencies = (props: any) => {
  const { dependencies, form } = props;
  const { values } = form ?? {};
  return (dependencies ?? []).map((x: string) => values[x]);
};

const useOptions = (props: any) => {
  const [options, setOptions] = React.useState<IDropdownOption[] | undefined>(
    typeof props.options === "function" ? undefined : props.options,
  );

  async function getOptions(
    func: (props: any) => Promise<IDropdownOption[]> | undefined,
  ) {
    setOptions(await func(props));
  }

  const dependencies = getDependencies(props);

  React.useEffect(() => {
    const { form } = props;
    typeof props.options === "function" && getOptions(props.options);
    options !== undefined &&
      typeof props.options === "function" &&
      dependencies.length > 0 &&
      form.setFieldValue(props.name, undefined);
  }, dependencies);

  return { options };
};

const mappedOptions = (
  options: IDropdownOption[] | undefined,
  value: string | string[] | number[] | number | null,
) =>
  options
    ?.filter((x: any) =>
      Array.isArray(value)
        ? (value as any[]).map((x) => x.toString()).includes(x.key.toString())
        : x.key === value,
    )
    .map((x: any) => x.text)
    .join(", ") ?? value;

export type FormikDropdownProps<V, FormValues> = Omit<
  IDropdownProps,
  "selectedKey"
> &
  FieldProps<V, FormValues>;
export function FormikDropdown<
  V extends string | number | string[] | number[] | null,
  FormValues = any,
  >({ field, form, meta, ...props }: FormikDropdownProps<V, FormValues>) {
  const { errorMessage, onDismiss, ...fieldProps } = mapFieldToDropdown({
    field,
    form,
    meta,
  });
  const readOnly = isReadOnly(field, form, props);
  const { options } = useOptions({
    ...props,
    form,
  });

  return readOnly ? (
    FormikTextField({
      ...handleProps(props),
      ...fieldProps,
      errorMessage: errorMessage,
      readOnly,
      field: {
        ...field,
        type: "text",
        readOnly: true,
        value: mappedOptions(options, field.value),
      },
      form,
      meta,
    } as any)
  ) : typeof props.options === "function" && options === undefined ? (
    <Spinner size={SpinnerSize.medium} label={"Loading..."} />
  ) : (
    <Dropdown
      {...handleProps(props)}
      {...fieldProps}
      options={options ?? []}
      onDismiss={invokeAll(onDismiss, props.onDismiss)}
      errorMessage={errorMessage}
    />
  );
}
