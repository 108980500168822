import { Persona, PersonaSize } from "@fluentui/react";
import * as React from "react";
import { getUserPersonaClassNames } from "./UserPersona.styles";
import { IUserPersonaProps } from "./UserPersona.types";

export const UserPersonaComponent: React.FunctionComponent<
  IUserPersonaProps
> = (props) => {
  const { styles, item, onItemInvoked } = props;
  const { classNames /* , subComponentStyles */ } = getUserPersonaClassNames(
    styles,
    {
      ...props,
    },
  );
  return (
    <div className={classNames.root}>
      <Persona
        onClick={() => onItemInvoked(item)}
        size={PersonaSize.size8}
        text={item.displayName}
        showSecondaryText={true}
        secondaryText={(item as any).identities.filter((x: any) => x.signInType == "emailAddress")[0]?.issuerAssignedId ?? item.userPrincipalName}
      />
    </div>
  );
};
