import { INotificationItem } from "../../models/INotificationItem";

export interface INotificationManager {
  notifications: INotificationItem[];
  add: (item: INotificationItem) => void;
  clear: () => void;
}

export const NotificationManager: INotificationManager = {
  notifications: [],
  add: (item: INotificationItem) => {
    NotificationManager.notifications = [item].concat(
      NotificationManager.notifications,
    );
  },
  clear: () => {
    NotificationManager.notifications = [];
  },
};
