import {
  ConstrainMode,
  IColumn,
  IDetailsListProps,
  Selection,
  SelectionMode
} from "@fluentui/react";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useCompactRow } from "../../components/CompactDetailsRow";
import { ExportCommand } from "../../components/ExportCommand";
import { IdentityUser } from "../../components/IdentityUser";
import { useMediaQueries } from "../../components/MediaQueries";
import { QueryBuilder } from "../../components/QueryBuilder";
import { renderUserPersona } from "../../components/UserPersona/UserPersona.render";
import { GridViewDisplayMode } from "../../components/gridview/models/GridViewDisplayMode";
import { IGridViewCommand } from "../../components/gridview/models/IGridViewCommand";
import { IGridViewOptions } from "../../components/gridview/models/IGridViewOptions";
import { sortArrayByProperty } from "../../components/utils";
import { IUser } from "../../models/IUser";
import { SearchCommandItem } from "./SearchCommandItem";
import { IUsersProps } from "./Users.types";

export interface IUsersState {
  error?: string;
  options: IGridViewOptions;
  items?: IUser[];
  submitting: boolean;
  state?: string;
  search?: string;
}

/*
async function deleteItems(api: any, items: any) {
  for (let i = 0; i < items.length; i++) {
    const x = await api.usersById(items[i].id).get();
    if (x?.id === undefined) continue;
    x.accountEnabled = false;
    const payload = Object.assign({}, x);
    await api.usersById(x.id).patch(payload);
  }
}
*/

export const useUsers = (props: IUsersProps) => {
  const { api, user, authService ,external} = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [state, setState] = React.useState<IUsersState>({
    options: {
      fetched: false,
      displayMode: GridViewDisplayMode.list,
      query: new QueryBuilder()
        .select("id", "displayName", "otherMails", "accountEnabled", "userPrincipalName", "identities", "userType", "createdDateTime", "creationType", "companyName", "department")
        .filter(y => y.and(x => x.eq("userType", "Member"), x => x.eq("accountEnabled", true)))
        .top(999),
    },
    submitting: false,
    state: (location.state as any)?.newuser ?? false ? "newuser" : undefined,
  });
  const { mobile } = useMediaQueries();
  const [, setSelected] = React.useState<number>(0);

  const { error, options, items, submitting, search } = state;

  const getItems = async (force: boolean) => {
    try {
      const expires_in =
        user?.expires_in == undefined
          ? user?.profile.auth_time === undefined
            ? 0
            : user?.profile.auth_time + 3600 - Math.round(Date.now() / 1000)
          : user?.expires_in;
      if (expires_in < 25) {
        authService.login();
        return;
      }
      const callString = external?.servicePrincipalType =="Application"? `/servicePrincipals/${external?.id}/appRoleAssignedTo` : `/groups/${external?.id}/members`
      const result = external?.id ? await api.api(options.nextLink ?? callString).get():await api.api(options.nextLink ?? `/users?${options.query.toQs(true)}`).get();
      const payload = external?.id ? external?.servicePrincipalType =="Application" ? result.value: result?.value.filter((c:any)=> c["@odata.type"] == "#microsoft.graph.user") :(force
        ? (result?.value ?? [])
          .filter((x: any) => x.userPrincipalName.startsWith("cpim_") || (x.creationType !== null && x.identities.filter((y: any) => y.issuer.endsWith("b2c.onmicrosoft.com")).length > 0))
          .map((x: any) => ({ ...x, key: x.id, email: (x.identities.filter((x: any) => x.signInType == "emailAddress")[0]?.issuerAssignedId ?? x.otherMails?.[0] ?? x.userPrincipalName).toLowerCase() }))
        : (items ?? []).concat((result?.value ?? [])
          .filter((x: any) => x.userPrincipalName.startsWith("cpim_") || (x.creationType !== null && x.identities.filter((y: any) => y.issuer.endsWith("b2c.onmicrosoft.com")).length > 0))
          .map((x: any) => ({ ...x, key: x.id, email: (x.identities.filter((x: any) => x.signInType == "emailAddress")[0]?.issuerAssignedId ?? x.otherMails?.[0] ?? x.userPrincipalName).toLowerCase() }))));
      const orderBy = options.query.get().orderBy();
      // accountEnabled is always false for B2C
      setState({
        ...state,
        error: undefined,
        submitting: false,
        options: {
          ...options,
          fetched: true,
          nextLink: result["@odata.nextLink"],
        },
        items:
          orderBy === undefined || orderBy.length === 0 ? force ? payload : (state.items ?? []).concat(payload) : sortArrayByProperty(force ? payload : (state.items ?? []).concat(payload), orderBy[0].replace(" desc", "") as any, orderBy[0].indexOf(' desc') !== -1 ? "desc" : "asc"),
      });
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        error: "error",
        submitting: false,
        state: undefined,
        options: {
          ...options,
          nextLink: undefined,
          fetched: true,
        },
        items: [],
      });
    }
  };

  React.useEffect(() => {
    state.items === undefined && getItems(true);
  }, []);

  const [selection] = React.useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        setSelected(selection.count);
      },
    }),
  );

  const onFetch = () => {
    options.query = options.query.skip(items?.length ?? 0);
    getItems(false);
  };

  const columnsAllUsers: IColumn[] = mobile
    ? [
      {
        key: "name",
        name: t("Users.GridView.Columns.Name", "Name") ?? "Name",
        fieldName: "displayName",
        minWidth: 200,
        onRender: renderUserPersona(props, (item) =>
          navigate(`/users/${item.id}`, {
            state: item,
          }),
        ),
        data: {
          isSortable: true,
        },
      },
    ]
    : [
      {
        key: "name",
        name: t("Users.GridView.Columns.Name", "Name") ?? "Name",
        fieldName: "displayName",
        minWidth: 250,
        maxWidth: 350,
        data: {
          isSortable: true,
        },
      },
      {
        key: "email",
        name: t("Users.GridView.Columns.Email", "Email") ?? "Email",
        fieldName: "email",
        minWidth: 250,
        maxWidth: 350
      },
      {
        key: "department",
        name: t("Users.GridView.Columns.Department", "Department") ?? "Department",
        fieldName: "department",
        minWidth: 250,
        maxWidth: 350
      },
      {
        key: "companyName",
        name: t("Users.GridView.Columns.Organization", "Organization") ?? "Organization",
        fieldName: "companyName",
        minWidth: 250
      },
      {
        key: "type",
        name: t("Users.GridView.Columns.UserType", "User type") ?? "User type",
        fieldName: "@graph.type",
        minWidth: 175,
        maxWidth: 175,
        onRender: (item: any) =>
          item.userType,
      },
    ];
    const columnsServicePrincipal: IColumn[] = mobile
    ? [
      {
        key: "principalDisplayName",
        name: t("Users.GridView.Columns.Name", "Name") ?? "Name",
        fieldName: "principalDisplayName",
        minWidth: 200,
        onRender: renderUserPersona(props, (item) =>
          navigate(`/users/${item.id}`, {
            state: item,
          }),
        ),
        data: {
          isSortable: true,
        },
      },
    ]
    : [
      {
        key: "principalDisplayName",
        name: t("Users.GridView.Columns.Name", "Name") ?? "Name",
        fieldName: "principalDisplayName",
        minWidth: 250,
        maxWidth: 350,
        data: {
          isSortable: true,
        },
      },
      {
        key: "principalType",
        name: t("Users.GridView.Columns.UserType", "User type") ?? "User type",
        fieldName: "principalType",
        minWidth: 175,
        maxWidth: 175,
      },
      {
        key: "appRoleId",
        name: t("Users.GridView.Columns.appRoleId", "app Role Id") ?? "App role Id",
        fieldName: "appRoleId",
        minWidth: 175,
        maxWidth: 175,
      },
    ];
  const columns = external?.servicePrincipalType =="Application" ? columnsServicePrincipal:columnsAllUsers;

  const onSort = (fieldName: string, desc: boolean) => {
    options.query = options.query.orderBy(
      ...[desc ? fieldName : `${fieldName} desc`],
    );
    getItems(true);
  };

  const commands: IGridViewCommand[] = external?.id ?[{
    key: "refresh",
    text: t("Users.Commands.Refresh", "Refresh") ?? "",
    iconProps: {
      iconName: "Refresh",
    },
    buttonStyles: {
      root: {
        backgroundColor: "transparent",
      },
    },
    onClick: (ev) => {
      ev?.preventDefault();
      ev?.stopPropagation();
      getItems(true);
    },
  },] : [
    {
      key: "invite",
      text: t("Users.Commands.Invite", "Invite User") ?? "",
      iconProps: {
        iconName: "Mail",
      },
      buttonStyles: {
        root: {
          backgroundColor: "transparent",
        },
      },
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        setState({ ...state, state: "invitation" });
      },
      subMenuProps: {
        items: [
          {
            key: "invite",
            text: t("Users.Commands.Invite", "Invite User") ?? "",
            iconProps: {
              iconName: "Mail",
            },
            buttonStyles: {
              root: {
                backgroundColor: "transparent",
              },
            },
            onClick: (ev) => {
              ev?.preventDefault();
              ev?.stopPropagation();
              setState({ ...state, state: "invitation" });
            },
          },

          {
            key: "bulk",
            text: t("Users.Commands.BulkInvite", "Bulk Invite") ?? "",
            iconProps: {
              iconName: "BulkUpload",
            },
            buttonStyles: {
              root: {
                backgroundColor: "transparent",
              },
            },
            onClick: (ev) => {
              ev?.preventDefault();
              ev?.stopPropagation();
              setState({ ...state, state: "bulk" });
            },
          }
        ]
      }
    },
    {
      key: "refresh",
      text: t("Users.Commands.Refresh", "Refresh") ?? "",
      iconProps: {
        iconName: "Refresh",
      },
      buttonStyles: {
        root: {
          backgroundColor: "transparent",
        },
      },
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        setState({
          ...state,
          options: {
            ...options,
            fetched: undefined,
            nextLink: undefined,
          },
        });
        options.query = options.query /*.skip(0) */.top(999);
        getItems(true);
      },
    },
    {
      key: "delete",
      text: t("Users.Commands.Delete", "Delete User") ?? "",
      selectionRequired: true,
      confirmMessage: () => t("Users.Commands.DeleteConfirmationMessage", "Are you sure you want to delete these users") ?? "",
      confirmTitle: t("Users.Commands.DeleteConfirmationTitle", "Deleting User") ?? "",
      iconProps: {
        iconName: "Delete",
      },
      buttonStyles: {
        root: {
          backgroundColor: "transparent",
        },
      },
      onClick: (ev: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        ev?.preventDefault();
        ev?.stopPropagation();
        const items = selection.getItems();
        const id = IdentityUser.idOf(props.user);
        const hasMe = items.some((x) => x.key === id);
        if (hasMe) {
          // cannot delete yourself
          alert("You cannot delete yourself from this app.");
        } else {
          axios
            .all(
              items
                .filter((x) => x?.key !== undefined)
                .map((x) => api.api(`/users/${x.key}`).delete()),
            )
            .then(() => {
              options.query = options.query.skip(0).top(999);
              getItems(true);
            })
            .catch(() => {
              options.query = options.query.skip(0).top(999);
              getItems(true);
            });
        }
      },
    },
    ExportCommand(items, columns, selection, t),
  ];


  const onSearch = (newValue?: string) => {
    setState({ ...state, search: newValue });
  };

  const commandBarProps = () => {
    return {
      farItems: [
        {
          key: "search",
          name: "",
          onRender: SearchCommandItem(onSearch)
        }
      ],
      items: commands,
    };
  };

  const detailsListProps: IDetailsListProps | undefined =
    items === undefined
      ? undefined
      : {
        items: items,
        compact: true,
        constrainMode: ConstrainMode.horizontalConstrained,
        onItemInvoked: (item: any) =>
          
          navigate(`/users/${external?.servicePrincipalType =="Application" ? item.principalId:item.id}`, {
            state: item,
          }),
        onRenderRow: useCompactRow,
      };

  const selectionMode = SelectionMode.multiple;

  const onCommonDismiss = (force?: boolean) => {
    if (
      (typeof force !== "boolean" && typeof force !== "undefined") ||
      !(force ?? false)
    ) {
      setState({
        ...state,
        error: undefined,
        submitting: false,
        state: undefined,
      });
    } else if (force ?? true) {
      options.query = options.query.skip(0).top(999);
      getItems(true);
    }
  };

  const onUserInvitationDismiss = onCommonDismiss;

  const onBulkDismiss = onCommonDismiss;

  const breadcrumb = external?.id ?  ([
    {
      key: "home",
      text: t("ApplicationMenu.HomeTitle", "Home"),
      href: "/",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/");
      },
    },
    {
      key: "application",
      text: t("ApplicationMenu.Application", "Application"),
      href: "/",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/applications");
      },
    },
    {
      key: "users",
      text: external.displayName ?? t("ApplicationMenu.Group", "Group"),
    }
    ]) :  ([
    {
      key: "home",
      text: t("ApplicationMenu.HomeTitle", "Home"),
      href: "/",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/");
      },
    },
    {
      key: "users",
      text: t("Users.Title", "Users"),
    },
  ]);

  return {
    selectionPreservedOnEmptyClick: true,
    error,
    onFetch,
    onSort,
    options,
    items: items?.filter((x: any) =>
      !search ||
      (x.displayName?.toLowerCase() ?? "").includes(search.toLowerCase()) ||
      (x.email?.toLowerCase() ?? "").includes(search.toLowerCase())
    ),
    columns,
    detailsListProps,
    commandBarProps,
    selectionMode,
    selection,
    submitting,
    invitation: state.state === "invitation",
    bulk: state.state === "bulk",
    breadcrumb,
    onBulkDismiss,
    t,
    onUserInvitationDismiss,
  };
};
