import { styled } from "@fluentui/react";
import { IPropertyFieldProps } from "./PropertyField.types";
import {
  getStyles,
  IPropertyFieldStyleProps,
  IPropertyFieldStyles,
} from "./PropertyField.styles";
import { PropertyFieldComponent } from "./PropertyField.base";

/**
 * PropertyField
 */
export const PropertyField = styled<
  IPropertyFieldProps,
  IPropertyFieldStyleProps,
  IPropertyFieldStyles
>(PropertyFieldComponent, getStyles);
