import { DefaultPalette, Icon, IconButton, Stack, TooltipHost } from "@fluentui/react";

const stateToDesc = (item: any) => {
    const source: string = item.email;
    const target: string = item.target;
    if (source && target) {
        const sourceDomain = source.split('@').pop();
        const targetDomain = target.split('@').pop();
        if (sourceDomain !== targetDomain) {
            return "Registred domain issue";
        }
        const sourceAlias = source.split('@').shift() ?? "";
        const targetAlias = target.split('@').shift() ?? "";
        if (sourceAlias.includes('.')) {
            if (targetAlias.includes('.')) {
                return "Identity theft or account sharing";
            }
            const parts = sourceAlias.split('.');
            const letter = parts[0].substring(0, 1);
            const family = parts.pop();
            if (`${letter}${family}` === targetAlias) {
                return "Alias problem detected";
            }
            return "Alias problem detected";
        }
    }
    return "Undetermined";
}

const stateToIcon = (item: any) => {
    const source: string = item.email;
    const target: string = item.target;

    if (source && target) {
        const sourceDomain = source.split('@').pop();
        const targetDomain = target.split('@').pop();
        if (sourceDomain !== targetDomain) {
            return "GlobeFavorite";
        }
        const sourceAlias = source.split('@').shift() ?? "";
        const targetAlias = target.split('@').shift() ?? "";
        if (sourceAlias.includes('.')) {
            if (targetAlias.includes('.')) {
                return "UserSync";
            }
            const parts = sourceAlias.split('.');
            const letter = parts[0].substring(0, 1);
            const family = parts.pop();
            if (`${letter}${family}` === targetAlias) {
                return "Tag";
            }
            return "TagUnknown";
        }
    }
    return "Unknown";
}

export const onRenderApprove = (
    onRepair: (item: any) => void,
    onApprove: (item: any) => void,
    onCancelApprove: (item: any) => void) => (item: any) => {
        return (item.target ?? "") === "" ? <></> : <Stack
            horizontal tokens={{ childrenGap: 5 }}>
            <IconButton
                title="Repair"
                style={{ display: "none" }}
                iconProps={{ iconName: "Repair", styles: { root: { marginTop: "-8px" } } }}
                onClick={item.approved ? undefined : (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    onRepair(item);
                }}
            />
            <TooltipHost
                content={stateToDesc(item)}>
                <Icon iconName={stateToIcon(item)} />
            </TooltipHost>
            <IconButton disabled={item.approved} title="Approuver" iconProps={{
                iconName: item.approved ? "Completed" : "Permissions",
                styles: { root: { marginTop: "-8px", color: item.approved ? DefaultPalette.greenDark : DefaultPalette.themePrimary } }
            }} onClick={item.approved ? undefined : (ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                onApprove(item);
            }} />
            <IconButton iconProps={{ iconName: "Cancel", styles: { root: { marginTop: "-8px" } } }} title="Annuler l'approbation"
                onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    onCancelApprove(item);
                }} />
        </Stack>
    }