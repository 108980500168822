import React from 'react';
import { useTranslation } from 'react-i18next';
import { getAddAppsClassNames } from './AddApps.styles';
import {
  IUserAppsProps
} from './AddApps.types';
import { IColumn } from '@fluentui/react';
import { useMediaQueries } from '../../../../components/MediaQueries';
import { IGridViewOptions } from '../../../../components/gridview/models/IGridViewOptions';
import { GridViewDisplayMode } from '../../../../components/gridview/models/GridViewDisplayMode';
import { QueryBuilder } from "../../../../components/QueryBuilder";
import {
  Selection,
} from "@fluentui/react";
import { InventoryService } from "../../../../InventoryService";


interface IAddAppsState {
  running: boolean;
  errorMessage?: string;
  success: boolean;
  index: number;
  fileName?: string;
  fileLoaded: boolean;
  accountType: number;
  options: IGridViewOptions;
  error:any;
  items?:any[];
  AppsModalApproved?:boolean;
  allSelected?:boolean;
  RoleSelected?:any;

}



export const useAddApps = (props: IUserAppsProps) => {
  const { user,selected,api  }  = props;
  const { styles, theme, onDismiss } = props;
  const { classNames /* , subComponentStyles */ } = getAddAppsClassNames(
    styles,
    {
      ...props,
    }
  );
  const [state, setState] = React.useState<IAddAppsState>({
    running: false,
    index: 0,
    success: false,
    fileLoaded: false,
    accountType: 1, /* Default to Federated */
    options:  {
      fetched: false,
      displayMode: GridViewDisplayMode.list,
      query: new QueryBuilder().top(20),
    },
    error:"",
    items: undefined,
  },
  );
  const { t } = useTranslation();
  const { running, errorMessage, success, 
     fileName, fileLoaded, index, options, error, items,AppsModalApproved} = state;


  const onStartUpload = () => {
    
  };
  const [modalselectedApps, setSelected] = React.useState<any[]>([])
  const [selection] = React.useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        setSelected(selection.getSelection()??[]);
      },
    }),
  );
  

  const { mobile } = useMediaQueries();

  const getItems = async () => {
    if(!props.RemovingApps){
    try {
      const orgId = "ba3754c0-02f7-431f-bfc3-43e37c96f3d1"
      const result = await api.api(`/users/${selected.id}/appRoleAssignments`)
      .header('ConsistencyLevel','eventual')
      .get();
      const idAuth = result.value.map((c:any) =>  c.resourceDisplayName)
      const allServicePrincipals = await api.api("/servicePrincipals").get();
      const apps = allServicePrincipals.value.filter((c:any) => c.signInAudience === 'AzureADandPersonalMicrosoftAccount' && c.appOwnerOrganizationId === orgId);
      const newApps = apps.filter((c:any) => !idAuth.includes(c.displayName))
      newApps.map(async (c:any) => {
        if (c.appRoles.length >=1){
        const suggestion = await InventoryService.getGroupsMapAppidUserid(user,c.id,selected.id);
        console.log(suggestion)
        c.SelectedRole = suggestion.length == 1 ? c.appRoles.map((c:any)=> {return c.id;}).filter((c:any)=> c == suggestion[0].roleId)[0]:c.appRoles[0].id
        console.log(c.SelectedRole)}

      })
      setState({
        ...state,
        error: undefined,
        options: {
          ...options,
          fetched: true,
        },
        items: newApps
      } );
      
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        error: "error",
        options: {
          ...options,
          fetched: true,
        },
        items: [],
      });
    } 
  }
  };

  React.startTransition(() => {
    state.items === undefined && getItems();
  });

   const onFetch = () => {
    options.query = options.query.skip(items?.length ?? 0);
    getItems();
  };

  const onSort = (fieldName: string, desc: boolean) => {
    options.query = options.query
      .skip(0)
      .top(20)
      .orderBy(...[desc ? fieldName : `${fieldName} desc`]);
    getItems();
  };
  const columns: IColumn[] = mobile
    ? [
      {
        key: "appId",
        name: t("Users.Apps.AppId","Application Id"),
        fieldName: "appId",
        minWidth: 10,
        maxWidth:20
      },
    ]
    : [
      {
        key: "appId",
        name: t("Users.Apps.AppId","Application Id"),
        fieldName: "appId",
        minWidth: 250,
        maxWidth: 250,
        visible: true
      },
      {
        key: "displayName",
        name:  t("Users.Apps.AppName","Application name"),
        fieldName: "displayName",
        minWidth: 100,
        maxWidth: 100
      },
    ].filter((x) => x.visible ?? true);

    const onDropdownChange = (event: React.FormEvent<HTMLDivElement>, appid: string,option:any): void => {
      
      event.isTrusted &&
      modalselectedApps.map((c:any) => {
        if(c.appId == appid){
          console.log(option)
          c.SelectedRole = option.key
        }

      })
    };

    const onModalApprove= async () => {
      setState({
        ...state,
        AppsModalApproved: true,            
      });

    }
    
    const onApprove = async () => {
      try{
        //const RoleId = [ { groupId: "", appId: "", roleId: ""}]
        modalselectedApps.map((c:any) =>{
          const appRoleAssignment = {
              principalId: selected.id,
              resourceId: c.id,
              appRoleId: c.SelectedRole ?? '00000000-0000-0000-0000-000000000000'
            };
            api.api(`/users/${selected.id}/appRoleAssignments`).post(appRoleAssignment);
          })
            onDismiss(modalselectedApps)
          }
          catch (e) {
            setState({
              ...state,
              error: "error",            
            });
            console.log(e)
          }
    }
    const onApproveRemove = async (selections:any) => {
      try{
          selections.map((c:any) =>{
            api.api(`/users/${selected.id}/appRoleAssignments/${c.id}`).delete();
          })
            onDismiss([]);
          }
          catch (e){
            console.log(e)
          }
    }


  


  return {
    classNames,
    readOnly: running,
    state,
    accountType: state.accountType,
    setState,
    errorMessage,
    success,
    theme,
    onDismiss,
    onStartUpload,
    fileLoaded,
    index,
    fileName,
    t,
    user,
    selected,
    columns,
    options,
    error,
    items,
    onFetch,
    onSort,
    onselect,
    selection,
    onApprove,
    modalselectedApps,
    onApproveRemove,
    onModalApprove,
    AppsModalApproved,
    onDropdownChange,
  };
}