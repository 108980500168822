import { TextField } from "@fluentui/react";
import { FieldProps } from "formik";
import { getErrorMessage, handleProps } from "./utils";
export interface ICustomFieldProps {
  name: string;
  form?: any;
  onRender: (props: FieldProps<any, any>) => JSX.Element;
  value: any | undefined;
}

export type FormikCustomFieldProps<V extends string, FormValues = any> = Omit<
  ICustomFieldProps,
  "value" | "name" | "form"
> &
  FieldProps<V, FormValues>;

export function FormikCustomField<V extends string, FormValues = any>(
  props: FormikCustomFieldProps<V, FormValues>,
) {
  const { label, controlled = false } = props as any;
  const errorMessage = getErrorMessage(props);

  return label === undefined || label == null || (controlled ?? false) ? (
    <>{props.onRender?.(props) ?? ""}</>
  ) : (
    <TextField
      {...handleProps(props)}
      form={undefined}
      readOnly
      type="text"
      errorMessage={errorMessage}
      onRenderInput={() => <>{props.onRender?.(handleProps(props)) ?? <></>}</>}
    />
  );
}
