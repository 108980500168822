import { IGridViewConfirmDialogProps } from "./GridViewConfirmDialog.types";
import { useTranslation } from "react-i18next";
import { DialogType } from "@fluentui/react";

export const useGridViewConfirmDialog = (
  props: IGridViewConfirmDialogProps,
) => {
  const {
    yesDisabled,
    yes,
    no,
    confirmTitle,
    confirmMessage,
    selection,
    isConfirming,
    onDismiss,
  } = props;
  const { t } = useTranslation("globals");

  const dialogProps = {
    hidden: !isConfirming,
    onDismiss: () => onDismiss(false),
    dialogContentProps: {
      type: DialogType.normal,
      title: confirmTitle,
      closeButtonAriaLabel: t("CloseLabel", "Close"),
    },
    modalProps: {
      isBlocking: true,
      styles: { main: { maxWidth: 450 } },
    },
  };

  const onConfirm = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    onDismiss(true);
  };

  const onCancel = (ev: any) => {
    ev.preventDefault();
    ev.stopPropagation();
    props.onDismiss(false);
  };

  const confirmButtonProps = {
    disabled: yesDisabled,
    text: yes ?? t("YesLabel", "Yes") ?? "Yes",
    onClick: onConfirm,
  };

  const cancelButtonProps = {
    text: no ?? t("NoLabel", "No") ?? "No",
    onClick: onCancel,
  };

  return {
    dialogProps,
    confirmButtonProps,
    cancelButtonProps,
    confirmMessage:
      selection !== undefined && typeof confirmMessage === "function"
        ? confirmMessage(selection?.getItems())
        : confirmMessage,
  };
};
