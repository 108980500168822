import { IFormProps } from "./IFormProps";

class Registry {
  private mapping: any;

  constructor() {
    this.mapping = {};
  }
  get(name: string) {
    const o = this.mapping[name];
    if (o == null) throw new Error("No object registered for: " + name);
    return o;
  }
  register(name: string, o: any) {
    this.mapping[name] = o;
  }
}

// Widgets
const widgetRegistry = new Registry();
export const makeWidget = (config: any, formikParams: any) =>
  widgetRegistry.get(config.type)(config, formikParams);
export const registerWidget = widgetRegistry.register.bind(widgetRegistry);

// Renderers
const rendererRegistry = new Registry();
export const buildRenderFunction =
  (props: IFormProps) => (formikParams: any) => {
    const defaultRenderer = "stack";
    const renderer =
      (props.schema.form && props.schema.form.renderer) || defaultRenderer;
    return rendererRegistry.get(renderer)(props)(formikParams);
  };
export const registerRenderer =
  rendererRegistry.register.bind(rendererRegistry);
