import { FieldProps, getIn } from "formik";

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function getErrorMessage<V = any>({ field, form }: FieldProps<V>) {
  const error = getIn(form.errors, field.name);
  const touched =
    getIn(form.touched, field.name) || getIn(form.touched, `_${field.name}`);

  return touched ? error : undefined;
}

export function invokeAll(...callbacks: any[]) {
  return () => {
    for (const callback of callbacks) {
      if (callback && typeof callback === "function") {
        callback();
      }
    }
  };
}

export function createFakeEvent({ name }: { name: string }) {
  return { target: { name } };
}

export const isReadOnly = (a: any, b: any, c: any) => {
  const x =
    c.readOnly ?? b.readOnly ?? b.status?.readOnly ?? a.readOnly ?? false;
  return typeof x === "function" ? x(c) : x;
};

const attributes = [
  "max",
  "maxLength",
  "min",
  "minLength",
  "multiple",
  "name",
  "pattern",
  "placeholder",
  "title",
  "alt",
  "required",
  "disabled",
  "size",
  "src",
];

const h = (a: any, b: any): any =>
  typeof a[b] === "function" ? a[b](b) : a[b];

export const handleProps = (a: any) => {
  const o: any = {};
  attributes.forEach((x) => a[x] !== undefined && (o[x] = h(a, x)));
  return {
    ...a,
    ...o,
  };
};
