import { Icon, ITextFieldProps } from "@fluentui/react";
import { copyToClipboard } from "./copyToClipboard";
const iconStyles = {
  root: {
    fontSize: "16px",
    marginTop: "2px",
    cursor: "pointer",
  },
};

export const useCopyTo = () => ({
  onRenderSuffix: (props?: ITextFieldProps) => (
    <>
      {props?.value !== "boolean" &&
        props?.value !== undefined &&
        props?.value != null ? (
        <Icon
          onClick={() =>
            props.value !== undefined && copyToClipboard(props.value)
          }
          iconName="Copy"
          title={"Copy to clipboard"}
          styles={iconStyles}
        />
      ) : (
        <div style={{ width: "16px", height: "16px" }}></div>
      )}
    </>
  ),
});
