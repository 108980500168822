import { IColumn, Icon, Link, Stack, Text } from "@fluentui/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { copyToClipboard } from "../../copyToClipboard";
import { IGridViewProps } from "./GridView.types";

const linkTextStyles = {
  root: {
    color: "inherit",
  },
};

const stackStyles = {
  root: {
    ".ms-CopyTo": {
      display: "none"
    },
    ":hover .ms-CopyTo": {
      display: "block"
    }
  }
};

export const useLinkColumn = (props: IGridViewProps, column: IColumn) => {
  const onItemInvoked = props.detailsListProps?.onItemInvoked;

  return (item?: any, index?: number) => (
    <Stack grow horizontal tokens={{ childrenGap: 5 }} styles={stackStyles}>
      <Link
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          onItemInvoked?.(item, index);
        }} styles={{
          root: {
            ".ms-CopyTo": {
              display: "none"
            },
            ":hover .ms-CopyTo": {
              display: "block"
            }
          }
        }}>
        <Text styles={linkTextStyles}>{item[column.fieldName ?? column.key]}</Text>
      </Link>
      <Icon
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          column.fieldName && item[column.fieldName] !== undefined && copyToClipboard(item[column.fieldName])
        }}
        iconName="Copy"
        className="ms-CopyTo"
        title={"Copy to clipboard"}
        styles={{
          root: {
            cursor: "pointer"
          }
        }}
      />
    </Stack>
  );
};

export const useTextColumn =
  (_: IGridViewProps, column: IColumn) => (item: any) =>
    <Stack grow horizontal tokens={{ childrenGap: 5 }} styles={{
      root: {
        ".ms-CopyTo": {
          display: "none"
        },
        ":hover .ms-CopyTo": {
          display: "block"
        }
      }
    }}>
      <Text styles={linkTextStyles}>{item[column.fieldName ?? column.key]}</Text>
      <Icon
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          column.fieldName && item[column.fieldName] !== undefined && copyToClipboard(item[column.fieldName])
        }}
        iconName="Copy"
        className="ms-CopyTo"
        title={"Copy to clipboard"}
        styles={{
          root: {
            cursor: "pointer"
          }
        }}
      />
    </Stack>;

const IsoFormat = "YYYY-MM-DD HH:mm:ss";

export const useISODateColumn =
  (_props: IGridViewProps, column: IColumn) => (item: any) =>
  (
    <Text>
      {moment(item[column.fieldName ?? column.key]).format(IsoFormat)}
    </Text>
  );

export const useDateColumn =
  (_props: IGridViewProps, column: IColumn) => (item: any) =>
    <Text>{moment(item[column.fieldName ?? column.key]).format("LLLL")}</Text>;

export const useBooleanColumn =
  (_props: IGridViewProps, column: IColumn) => (item: any) => {
    const { fieldName, key } = column;

    const { t } = useTranslation("globals");

    const loc =
      item[fieldName ?? key] !== undefined && (item[fieldName ?? key] ?? false)
        ? "YesLabel"
        : "NoLabel";
    const defaultValue =
      item[fieldName ?? key] !== undefined && (item[fieldName ?? key] ?? false)
        ? "Yes"
        : "No";

    return (
      <Text>{t(loc, defaultValue)}</Text>
    );
  };

export const useColumn = {
  boolean: useBooleanColumn,
  isodate: useISODateColumn,
  date: useDateColumn,
  text: useTextColumn,
};
