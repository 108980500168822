import {
  Callout,
  ColorPicker,
  IColor,
  IColorPickerProps,
  TextField,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { FieldProps } from "formik";
import React from "react";
import { createFakeEvent, handleProps, Omit } from "./utils";

export function mapFieldToColorPicker<
  V extends IColor | string = string,
  FormValues = any,
>({
  form,
  field,
}: FieldProps<V, FormValues>): Pick<IColorPickerProps, "color" | "onChange"> {
  return {
    ...field,
    color: field.value,
    onChange: (_, color) => {
      form.setFieldValue(
        field.name,
        color?.hex === undefined ? undefined : `#${color.hex}`,
      ),
        field.onBlur(createFakeEvent(field));
    },
  };
}
export type FormikColorPickerProps<
  V extends IColor | string = string,
  FormValues = any,
> = Omit<IColorPickerProps, "color" | "onChange"> & FieldProps<V, FormValues>;

export function FormikColorPicker<
  V extends IColor | string = string,
  FormValues = any,
>({ field, form, meta, ...props }: FormikColorPickerProps<V, FormValues>) {
  const [open, setOpen] = React.useState(false);
  const buttonId = useId("callout-button");
  const labelId = useId("callout-label");

  return (
    <>
      {open && (
        <Callout
          coverTarget
          ariaLabelledBy={labelId}
          role="dialog"
          onDismiss={() => setOpen(false)}
          target={`#${buttonId}`}
          isBeakVisible={true}
          setInitialFocus>
          <ColorPicker
            {...props}
            {...mapFieldToColorPicker({
              field,
              form,
              meta,
            })}
          />
        </Callout>
      )}
      <TextField
        {...handleProps(props)}
        componentRef={undefined}
        borderless
        aria-readonly
        styles={{
          wrapper: {
            borderBottom: "0px",
          },
          fieldGroup: {
            width: "33px",
            height: "33px",
          },
        }}
        onRenderInput={() => (
          <div
            id={buttonId}
            onClick={() => setOpen(true)}
            style={{
              float: "left",
              height: "32px",
              width: "32px",
              cursor: "pointer",
              border: `1px solid #000`,
              backgroundColor:
                typeof field.value === "string"
                  ? `${field.value}`
                  : field.value?.hex ?? field.value,
            }}></div>
        )}
      />
      <div style={{ clear: "both" }}></div>
    </>
  );
}
