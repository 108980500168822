import * as React from "react";
import { IGroupResourceProps } from "./Groups.types";
import { useGroupResource } from "./Groups.use";
import { GridView } from "../../components/gridview/GridView/GridView";
import { Breadcrumb } from "@fluentui/react";
// import { Organizations } from "../../../Organizations/Organizations";
import { MessageBar,MessageBarType } from "@fluentui/react";
import { useNavigate } from "react-router";
// import { Route,Routes } from "react-router";
import { GroupResource } from "./GroupResource";
import { Routes,Route } from "react-router";

export const GroupComponent: React.FunctionComponent<
  IGroupResourceProps
> = (props) => {
  const { classNames, item,error,items,breadcrumb,modal, group, ...gridProps } = useGroupResource(props);
  const navigate = useNavigate();
  
  item ?? navigate("/applications")
  
  const gridview =() => (
    <div className={classNames.root}>
        <Breadcrumb key={"bread"} onGrowData={() => undefined} items={breadcrumb} />
        <GridView items={items} {...props} {...gridProps}>
          {error !== undefined && (
            <MessageBar messageBarType={MessageBarType.error}>We cannot display Apps at the moment.</MessageBar>
          )}
        </GridView>
    </div>
  );

  return ( <Routes>
    <Route
      path=":groups/*"
      element={<GroupResource {...props} item={group} prev={item} styles={undefined} />}
    />
    <Route
      path=":groups/*"
      element={<GroupResource {...props}  item={group} prev={item} styles={undefined} />}
    />
    <Route path="" element={gridview()} />
  </Routes>
  );
};
