import { Icon, ITheme, Stack } from "@fluentui/react";
import * as React from "react";
import { MediaQueries } from "../MediaQueries";
import { cdn } from "../utils";
import { SplashScreen } from "./SplashScreen";

export interface ISplashScreenContainerProps {
  theme: ITheme;
  text: string;
  secondaryText: string;
  tertiaryText: string;
  footerSrc?: string;
  imageSrc?: string;
}

export const SplashScreenContainer: React.FunctionComponent<
  ISplashScreenContainerProps
> = ({ theme, text, secondaryText, tertiaryText, footerSrc }) => {
  document.body.style.backgroundColor = theme.semanticColors.bodyBackground;
  return (
    <Stack
      styles={{
        root: {
          display: "block",
          height: "100vh",
          backgroundColor: theme.isInverted ? undefined : "#FCFCFC",
          [MediaQueries.tablet]: {
            ".ms-Spinner-circle.ms-Spinner--large": {
              marginTop: "-32px",
            },
            h3: {
              display: "none!important",
            },
          },
        },
      }}
    >
      <SplashScreen
        theme={theme}
        text=""
        personaProps={{
          theme: theme,
          text: text,
          secondaryText: secondaryText,
          tertiaryText: tertiaryText,
        }}
        footerSrc={
          footerSrc === undefined
            ? theme.isInverted
              ? `${cdn()}/images/logo_w.png`
              : `${cdn()}/images/logo.png`
            : footerSrc
        }
      >
        <Icon
          imageProps={{
            styles: {
              root: {
                [MediaQueries.tablet]: {
                  marginTop: "-80px",
                },
                [MediaQueries.mobile]: {
                  width: "100vw",
                  marginTop: "0px",
                },
              },
              image: {
                [MediaQueries.mobile]: {
                  width: "100vw",
                },
                [MediaQueries.tablet]: {
                  maxHeight: "540px",
                },
              },
            },
            title: text,
          }}
        />
      </SplashScreen>
    </Stack>
  );
};
