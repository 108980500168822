import { styled } from "@fluentui/react/lib/Utilities";
import { IDepartmentResourceProps } from "./DepartmentResource.types";
import {
  getStyles,
  IDepartmentResourceStyleProps,
  IDepartmentResourceStyles,
} from "./DepartmentResource.styles";
import { DepartmentResourceComponent } from "./DepartmentResource.base";

/**
 * DepartmentResource
 */
export const DepartmentResource = styled<
  IDepartmentResourceProps,
  IDepartmentResourceStyleProps,
  IDepartmentResourceStyles
>(DepartmentResourceComponent, getStyles);
