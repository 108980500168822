import { DetailsRow, IDetailsRowProps, ITheme } from "@fluentui/react";

export const onRenderRow = (theme?: ITheme) => (props?: IDetailsRowProps) => {
  if (props !== undefined) {
    const backgroundColor =
      props.itemIndex % 2 === 0
        ? undefined
        : theme?.isInverted ?? props.theme?.isInverted ?? false
          ? "rgb(51, 51, 51)"
          : "#f8f8f8";
    const selectableStyle =
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      (props as any).isItemSelectable?.(props.item) ?? true
        ? {
          root: {
            backgroundColor,
            ".ms-DetailsRow-check.ms-Check-checkHost .ms-Check": {
              marginBottom: "7px",
              backgroundColor,
            },
          },
        }
        : {
          root: {
            backgroundColor,
            ":hover": {
              cursor: "not-allowed!important",
            },
            ".ms-DetailsRow-check.ms-Check-checkHost": {
              visibility: "hidden",
            },
          },
        };

    return <DetailsRow {...props} styles={selectableStyle} />;
  }
  return null;
};
