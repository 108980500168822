import { IOrganizationResourceProps } from "./OrganizationResource.types";
import { getOrganizationResourceClassNames } from "./OrganizationResource.styles";

export const useOrganizationResource = (
  props: IOrganizationResourceProps,
) => {
  const { styles } = props;
  const { classNames /* , subComponentStyles */ } =
    getOrganizationResourceClassNames(styles, {
      ...props,
    });
  return {
    classNames,
  };
};
