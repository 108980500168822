import { Dropdown, IconButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Text, TextField } from '@fluentui/react';
import * as React from 'react';
import {
  IBulkInvitationProps
} from './BulkInvitation.types';
import {
  useBulkInvitation
} from './BulkInvitation.use';
import { InvitationList } from './Controls/InvitationList';
import { StatusIndicator } from './Controls/StatusIndicator';


const accountTypes = [
  "Standard Account",
  "Federated Account"
].map((x, i) => ({
  key: i,
  text: x
}));

export const BulkInvitationComponent: React.FunctionComponent<
  IBulkInvitationProps
> = (props) => {
  const { classNames, onDismiss, onFileUpload, onStartUpload, fileLoaded,
    accountType, onAccountTypeChanged, fileName, success, items,
    done, index, fileUploadRef, readOnly, errorMessage, iconButtonStyles } = useBulkInvitation(props);
  return (
    <>
      <div className={classNames.root}></div>
      <Panel
        isOpen
        headerText="Bulk Invitation"
        isBlocking
        isFooterAtBottom
        type={PanelType.medium}
        onRenderFooter={() =>
          <div style={{ padding: "10px" }}><PrimaryButton text="Cancel" onClick={() => onDismiss()} /></div>}
        onDismiss={() => onDismiss()}>
        <>
          {!readOnly && <>
            <div style={{ margin: "30px 0px" }}><Text>Please select files containing people to invite.</Text></div>
            <Dropdown label={"Accounts Type"} options={accountTypes} selectedKey={accountType} onChange={(_, item) => item && onAccountTypeChanged(parseInt(item.key.toString()))} />
            <input
              ref={(ref) => {
                if (ref != null) {
                  fileUploadRef.current = ref;
                }
              }}
              style={{ display: "none" }}
              type="file"
              onChange={onFileUpload}
            />
            <TextField
              label="Files"
              readOnly
              type="text"
              value={readOnly ? "Files loaded" : "Select files..."}
              errorMessage={errorMessage}
              onRenderSuffix={() => (
                <IconButton
                  iconProps={{
                    iconName: "FileRequest",
                  }}
                  disabled={readOnly}
                  styles={iconButtonStyles}
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    fileUploadRef.current?.click();
                  }}
                />
              )}
            />
            <div style={{ height: "10px" }}></div>
            <PrimaryButton text="Import" disabled={!fileLoaded} onClick={onStartUpload} />
          </>}
          {readOnly && items !== undefined && fileName !== undefined && (<>
            <StatusIndicator {...props} styles={undefined} total={items.length} done={done?.length ?? 0} fileName={fileName} />
            {success && <MessageBar messageBarType={MessageBarType.error}>
              Un problème est survenu lors de l'importation en lot.
            </MessageBar>}
            {success && <MessageBar messageBarType={MessageBarType.success}>
              Les invitations ont été traités avec succès.
            </MessageBar>}
            <InvitationList {...props} styles={undefined} items={items} done={done ?? []} index={index} />
          </>)}</>
      </Panel>
    </>
  );
}
