import { styled } from "@fluentui/react";
import { IUserOverviewProps } from "./UserOverview.types";
import {
  getStyles,
  IUserOverviewStyleProps,
  IUserOverviewStyles,
} from "./UserOverview.styles";
import { UserOverviewComponent } from "./UserOverview.base";

/**
 * UserOverview
 */
export const UserOverview = styled<
  IUserOverviewProps,
  IUserOverviewStyleProps,
  IUserOverviewStyles
>(UserOverviewComponent, getStyles);
