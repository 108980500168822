import {
  DefaultPalette,
  ShimmerCircle,
  ShimmerLine,
  Stack,
  TooltipHost,
  useTheme,
} from "@fluentui/react";
import * as React from "react";

export interface IShimmeredCardProps {
  columnWidth: number;
  children?: any;
}

const depth8 =
  "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)";

const depth8w =
  "0 3.2px 5.2px 0 rgba(255, 255, 255, 0.122), 0 0.6px 0.8px 0 rgba(255, 255, 255, 0.108)";

export const ShimmeredCard: React.FunctionComponent<IShimmeredCardProps> = (
  props,
) => {
  const footerCardSectionStyles = {
    root: {
      alignSelf: "stretch",
      borderLeft: "1px solid #F3F2F1",
    },
  };
  const calloutProps = { gapSpace: 0 };

  const theme = useTheme();

  const footerCardSectionTokens = {
    padding: "0px 0px 0px 10px",
  };
  return (
    <Stack
      style={{
        backgroundColor: DefaultPalette.neutralLighter,
        padding: "10px",
        borderRadius: "8px",
        width: "100%",
        margin: "10px",
        maxWidth: "99%",
        boxShadow: theme?.isInverted ? depth8w : depth8,
      }}
      className="ListViewCard"
    >
      <Stack grow={true} tokens={{ childrenGap: 10 }}>
        <ShimmerLine
          width={"100%"}
          height={14}
          color={theme.semanticColors.bodyBackground}
          styles={{
            root: { backgroundColor: theme.semanticColors.bodyBackground },
          }}
        />
        <div style={{ clear: "both" }}></div>
        {props.children}
        <div>
          <ShimmerLine
            width={"50%"}
            height={14}
            color={theme.semanticColors.bodyBackground}
            styles={{
              root: { backgroundColor: theme.semanticColors.bodyBackground },
            }}
          />
        </div>
        <div>
          <ShimmerLine
            width={"75%"}
            height={14}
            color={theme.semanticColors.bodyBackground}
            styles={{
              root: { backgroundColor: theme.semanticColors.bodyBackground },
            }}
          />
        </div>
        <div>
          <div style={{ float: "left" }}>
            <ShimmerCircle styles={{ root: { width: "18px" } }} />
          </div>
          <TooltipHost
            hostClassName={"ms-Card-bottom-container"}
            content={""}
            calloutProps={calloutProps}
          >
            <ShimmerLine
              width={"85%"}
              height={14}
              color={theme.semanticColors.bodyBackground}
              styles={{
                root: { backgroundColor: theme.semanticColors.bodyBackground },
              }}
            />
          </TooltipHost>
        </div>
      </Stack>
      <div style={{ height: "10px" }}></div>
      <Stack styles={footerCardSectionStyles} tokens={footerCardSectionTokens}>
        <ShimmerCircle styles={{ root: { width: "18px" } }} />
        <Stack.Item grow={1}>
          <span />
        </Stack.Item>
      </Stack>
    </Stack>
  );
};
