import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { InvitationService } from "../../InvitationService";
import { ISettingsProps } from "./Settings.types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useSettings = (props: ISettingsProps) => {
  const { user } = props;
  const navigate = useNavigate();
  const goHome = () => navigate("/");
  const { t } = useTranslation();
  const breadcrumb = [
    {
      key: "home",
      text: t("ApplicationMenu.HomeTitle", "Home") ?? "Home",
      onClick: goHome,
    },
    {
      key: "settings",
      text: t("ApplicationMenu.SettingsTitle", "Settings") ?? "Settings",
      isCurrentItem: true,
    },
  ];
  const onDownloadOpenId = () => {
    InvitationService.openid(user);
  }
  const onDownloadKeys = () => {
    InvitationService.keys(user);
  }
  return {
    breadcrumb,
    onDownloadOpenId,
    onDownloadKeys
  };
};
