import { styled } from "@fluentui/react";
import { IUserProfileProps } from "./UserProfile.types";
import {
  getStyles,
  IUserProfileStyleProps,
  IUserProfileStyles,
} from "./UserProfile.styles";
import { UserProfileComponent } from "./UserProfile.base";

/**
 * UserProfile
 */
export const UserProfile = styled<
  IUserProfileProps,
  IUserProfileStyleProps,
  IUserProfileStyles
>(UserProfileComponent, getStyles);
