import {
  IButtonStyles,
  IDropdownStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
  ITextFieldStyles,
} from "@fluentui/react";
import {
  ComponentStylesLoader,
  IComponentBaseClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
} from "../base";
import { MediaQueries } from "../MediaQueries";

export interface IPropertyFieldSubComponentStyles {
  button?: IButtonStyles;
  text?: Partial<ITextFieldStyles>;
  number?: Partial<ITextFieldStyles>;
  enum?: Partial<IDropdownStyles>;
}

export interface IPropertyFieldTokens {
  ver?: string;
}

export interface IPropertyFieldStyles
  extends IComponentBaseStyles<IPropertyFieldSubComponentStyles> { }

export interface IPropertyFieldStyleProps
  extends IComponentBaseStyleProps<IPropertyFieldTokens> {
  readOnly?: boolean;
  value?: string;
}

export const getPropertyFieldClassNames = (
  styles?: IStyleFunctionOrObject<
    IPropertyFieldStyleProps,
    IPropertyFieldStyles
  >,
  props?: IPropertyFieldStyleProps,
): IComponentBaseClassNames<
  IPropertyFieldStyleProps,
  IPropertyFieldStyles,
  IPropertyFieldSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IPropertyFieldStyleProps,
    IPropertyFieldStyles,
    IPropertyFieldSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IPropertyFieldStyleProps,
  IPropertyFieldStyles
> = (props: IPropertyFieldStyleProps): IPropertyFieldStyles => {
  const { readOnly, theme, value } = props;
  return {
    root: ["app-PropertyField", {}],
    subComponentStyles: {
      text: {
        root: {
          maxWidth: readOnly === undefined || readOnly ? undefined : "500px",
        },
        fieldGroup: {
          backgroundColor: "transparent",
          ":hover .ms-Clipboard-Action": {
            visibility: "visible",
          },
        },
        field: {
          backgroundColor: "transparent",
          marginLeft: readOnly === undefined || readOnly ? "-8px" : "-2px",
          width:
            readOnly === undefined || readOnly
              ? value === undefined || value == null
                ? "fit-content"
                : `${value.length + 5}ch`
              : "100%",
          maxWidth: readOnly === undefined || readOnly ? undefined : "420px",
        },
        suffix: [
          "ms-Clipboard-Action",
          {
            backgroundColor: "transparent",
            visibility: "hidden",
          },
        ],
        prefix: {
          backgroundColor: "transparent",
          ":hover": {
            backgroundColor: "transparent",
          },
          marginBottom: "5px",
        },
        wrapper: {
          border: "0px!important",
          ":focus": {
            border: "0px!important",
          },
          ":hover": {
            border: "0px!important",
          },
          "::after": {
            border: "0px!important",
          },
        },
        subComponentStyles: {
          label: {
            root: {
              minWidth: "137px",
              maxWidth: "137px",
              lineHeight: "16px",
              marginBottom: "1px",
              color: theme?.semanticColors.bodyText,
              padding: "2px 0px 5px 12px",
              [MediaQueries.mobile]: {
                fontWeight: 600,
                padding: "2px 0px 5px 0px",
              },
            },
          },
        },
      },
      enum: {
        root: {
          maxWidth: readOnly === undefined || readOnly ? undefined : "500px",
        },
        subComponentStyles: {
          panel: {},
          multiSelectItem: {},
          label: {
            root: {
              minWidth: "137px",
              maxWidth: "137px",
              lineHeight: "16px",
              marginBottom: "1px",
              color: theme?.semanticColors.bodyText,
              padding: "2px 0px 5px 12px",
            },
          },
        },
      },
      number: {
        root: {
          maxWidth: readOnly === undefined || readOnly ? undefined : "500px",
        },
        fieldGroup: {
          backgroundColor: "transparent",
          ":hover .ms-Clipboard-Action": {
            visibility: "visible",
          },
        },
        field: {
          backgroundColor: "transparent",
          marginLeft: readOnly === undefined || readOnly ? "-8px" : "-2px",
          width:
            readOnly === undefined || readOnly
              ? value === undefined || value == null
                ? "fit-content"
                : `${value.length + 5}ch`
              : "100%",
          maxWidth: readOnly === undefined || readOnly ? undefined : "420px",
        },
        suffix: [
          "ms-Clipboard-Action",
          {
            backgroundColor: "transparent",
            visibility: "hidden",
          },
        ],
        prefix: {
          backgroundColor: "transparent",
          ":hover": {
            backgroundColor: "transparent",
          },
        },
        wrapper: {
          border: "0px!important",
          ":focus": {
            border: "0px!important",
          },
          ":hover": {
            border: "0px!important",
          },
          "::after": {
            border: "0px!important",
          },
        },
        subComponentStyles: {
          label: {
            root: {
              minWidth: "137px",
              maxWidth: "137px",
              color: theme?.semanticColors.bodyText,
              padding: "2px 0px 5px 12px",
            },
          },
        },
      },
    },
  };
};
