import {
  IButtonStyles,
  IDropdownStyles,
  IIconStyles,
  IStackStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../base";

export interface IPropertyEnumFieldSubComponentStyles {
  button?: IButtonStyles;
  field?: Partial<IDropdownStyles>;
  label?: Partial<IStackStyles>;
  suffix?: IIconStyles;
}

export interface IPropertyEnumFieldTokens {
  ver?: string;
}

export interface IPropertyEnumFieldStyles
  extends IComponentBaseStyles<IPropertyEnumFieldSubComponentStyles> { }

export interface IPropertyEnumFieldStyleProps
  extends IComponentBaseStyleProps<IPropertyEnumFieldTokens> {
  readOnly?: boolean;
}

export const getPropertyEnumFieldClassNames = (
  styles?: IStyleFunctionOrObject<
    IPropertyEnumFieldStyleProps,
    IPropertyEnumFieldStyles
  >,
  props?: IPropertyEnumFieldStyleProps,
): IComponentBaseClassNames<
  IPropertyEnumFieldStyleProps,
  IPropertyEnumFieldStyles,
  IPropertyEnumFieldSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IPropertyEnumFieldStyleProps,
    IPropertyEnumFieldStyles,
    IPropertyEnumFieldSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IPropertyEnumFieldStyleProps,
  IPropertyEnumFieldStyles
> = (props: IPropertyEnumFieldStyleProps): IPropertyEnumFieldStyles => {
  return {
    root: [
      "app-PropertyEnumField",
      {
        maxWidth: "500px",
      },
    ],
    subComponentStyles: {
      label: {
        root: {
          width: "225px",
          padding:
            props.readOnly === undefined || props.readOnly
              ? "5px 0px 12px 12px"
              : "5px 0px",
        },
      },
      suffix: {
        root: {
          fontSize: "16px",
          marginTop: "2px",
          cursor: "pointer",
        },
      },
    },
  };
};
