import { MessageBar, CommandBar, MessageBarType, Persona, PersonaSize, Stack, StackItem } from "@fluentui/react";
import { MediaQueries, useMediaQueries } from "../../../../../../components/MediaQueries";
import { Form, Formik } from "formik";
import moment from "moment";
import * as React from "react";
import { PropertyField } from "../../../../../../components/PropertyField/PropertyField";
import { IUser } from "../../../../../../models/IUser";
import { getUserSecurityClassNames } from "./UserSecurity.styles";
import { IUserSecurityProps } from "./UserSecurity.types";
import { meta } from "../../../../../../components/utils";
import { notify } from "../../../../../../AppLayout.use";

const formatted = (user: any): IUser =>
  Object.assign(user, {
    timestamp:
      user.timestamp === undefined
        ? undefined
        : moment(new Date(user.timestamp)).local().format("LLLL"),
  });

export const UserSecurityComponent: React.FunctionComponent<
  IUserSecurityProps
> = (props) => {
  const { styles, user, selected } = props;
  const { classNames /* , subComponentStyles */ } = getUserSecurityClassNames(
    styles,
    {
      ...props,
    },
  );

  const [metadata, setUserMetadata] = React.useState<any | undefined>(undefined);
  const { displayName, otherMails, email, userPrincipalName } = selected;

  async function getUserMetadata(id: string) {
    const response = await fetch(`${meta("WWW-api-url")}/users/${id}`, {
      method: "GET",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.id_token}`,
        "Content-Type": "application/json",
      }
    });

    if (response.status < 299) {
      const a = await response.json();
      setUserMetadata({
        ...a,
        email: email?.toLowerCase() ?? a.email,
        isRevoked: a.isRevoked ? "Yes" : "No"
      });
    }
    else {
      setUserMetadata({
        email,
        disabled: true,
        thumbprint: "",
        serialNumber: "",
        isRevoked: "No"
      });
    }
  }

  React.useEffect(() => {
    selected.id && metadata === undefined && getUserMetadata(selected.id);
  }, [email]);

  const onRevoke = async (id: string) => {
    const response = await fetch(`${meta("WWW-api-url")}/users/${id}/x509`, {
      method: "DELETE",
      body: "",
      mode: 'cors',
      credentials: "include",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.id_token}`,
        "Content-Type": "application/json",
      }
    });

    notify({
      title: response.status < 299 ? "Certificate revoked successfully" : "Could not revoke certificate",
      level: response.status < 299 ? MessageBarType.success : MessageBarType.blocked,
      description: response.status < 299 ? `X.509 Certificate for user ${displayName} has been revoked. The user will receive a notification shortly.` : `An error occured while revoking user certificate.`,
      timestamp: new Date()
    });
    getUserMetadata(id);
  }

  const commandProps = {
    items: [
      {
        key: "revoke",
        disabled: !selected.id || !metadata || metadata.isRevoked === "Yes",
        name: "Revoke Certificate",
        iconProps: {
          iconName: "Blocked"
        },
        onClick: (ev: any) => {
          ev.preventDefault();
          ev.stopPropagation();
          selected.id && onRevoke(selected.id);
        }
      }
    ]
  }

  const { mobile, tablet } = useMediaQueries();
  return (
    <div className={classNames.root}>
      <CommandBar {...props} {...commandProps} />
      <div style={{ height: "25px" }}></div>
      <Stack
        horizontal={!mobile && !tablet}
        grow
        styles={{
          root: {
            maxWidth: "880px",
          },
        }}
      >
        <StackItem grow>
          <Stack grow tokens={{ childrenGap: 10 }}>
            <Persona
              text={displayName}
              secondaryText={(otherMails?.[0] ?? email)?.toLowerCase()}
              tertiaryText={userPrincipalName}
              size={PersonaSize.size100}
              styles={{
                root: {
                  height: "unset",
                  [MediaQueries.mobile]: {
                    marginLeft: "-5px",
                  },
                  [MediaQueries.tablet]: {
                    marginLeft: "-5px",
                  },
                },
              }}
              coinProps={{
                styles: {
                  coin: { display: "none" },
                },
              }}
            />
            <div style={{ height: "45px" }}></div>
            {metadata && <Formik
              initialValues={formatted(metadata)}
              onSubmit={() => undefined}
            >
              <Form>
                <PropertyField
                  {...props}
                  styles={undefined}
                  property={{
                    key: "email",
                  }}
                  label={"Email"}
                  readOnly
                  schema={{
                    fields: [
                      {
                        key: "email",
                      },
                    ],
                    required: [],
                  }}
                />
                <PropertyField
                  {...props}
                  styles={undefined}
                  property={{
                    key: "thumbprint",
                  }}
                  label={"X.509 Thumbprint"}
                  readOnly
                  schema={{
                    fields: [
                      {
                        key: "thumbprint",
                      },
                    ],
                    required: [],
                  }}
                />
                <PropertyField
                  {...props}
                  styles={undefined}
                  property={{
                    key: "serialNumber",
                  }}
                  label={"Serial Number"}
                  readOnly
                  schema={{
                    fields: [
                      {
                        key: "serialNumber",
                      },
                    ],
                    required: [],
                  }}
                />
                <PropertyField
                  {...props}
                  styles={undefined}
                  property={{
                    key: "timestamp",
                  }}
                  label={"Updated On"}
                  readOnly
                  schema={{
                    fields: [
                      {
                        key: "timestamp",
                      },
                    ],
                    required: [],
                  }}
                />
                <PropertyField
                  {...props}
                  styles={undefined}
                  property={{
                    key: "isRevoked",
                  }}
                  label={"Is Revoked"}
                  readOnly
                  schema={{
                    fields: [
                      {
                        key: "isRevoked",
                      },
                    ],
                    required: [],
                  }}
                />
              </Form>
            </Formik>}
            {metadata && metadata.disabled && <MessageBar messageBarType={MessageBarType.warning}>
              No certificate were provisioned for this user. No mutual authentication based applications will be available to this user.
              Note that mtls solutions are only deploy for internal organisation and not for partners and external resources.
            </MessageBar>}
          </Stack>
        </StackItem>
      </Stack>
      <div style={{ height: "75px" }}></div>
    </div>
  );
};
