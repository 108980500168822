import {
  IButtonStyles,
  IStackStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
  IToggleStyles,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../base";

export interface IPropertyBooleanFieldSubComponentStyles {
  button?: IButtonStyles;
  label?: Partial<IStackStyles>;
  toggle?: Partial<IToggleStyles>;
}

export interface IPropertyBooleanFieldTokens {
  ver?: string;
}

export interface IPropertyBooleanFieldStyles
  extends IComponentBaseStyles<IPropertyBooleanFieldSubComponentStyles> { }

export interface IPropertyBooleanFieldStyleProps
  extends IComponentBaseStyleProps<IPropertyBooleanFieldTokens> { }

export const getPropertyBooleanFieldClassNames = (
  styles?: IStyleFunctionOrObject<
    IPropertyBooleanFieldStyleProps,
    IPropertyBooleanFieldStyles
  >,
  props?: IPropertyBooleanFieldStyleProps,
): IComponentBaseClassNames<
  IPropertyBooleanFieldStyleProps,
  IPropertyBooleanFieldStyles,
  IPropertyBooleanFieldSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IPropertyBooleanFieldStyleProps,
    IPropertyBooleanFieldStyles,
    IPropertyBooleanFieldSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IPropertyBooleanFieldStyleProps,
  IPropertyBooleanFieldStyles
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (_props: IPropertyBooleanFieldStyleProps): IPropertyBooleanFieldStyles => {
  return {
    root: ["app-PropertyBooleanField", {}],
    subComponentStyles: {
      label: {
        root: {
          width: "225px",
          padding: "5px 0px 12px 12px",
        },
      },
      toggle: {
        root: {
          marginTop: "8px",
          marginBottom: "2px",
        },
      },
    },
  };
};
