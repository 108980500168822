import { IColumn } from "@fluentui/react";
import React from "react";
import { useNavigate } from "react-router";
import { useMediaQueries } from "../../components/MediaQueries";
import { QueryBuilder } from "../../components/QueryBuilder";
import { GridViewDisplayMode } from "../../components/gridview/models/GridViewDisplayMode";
import { IGridViewOptions } from "../../components/gridview/models/IGridViewOptions";
//import { renderSignInActivity } from "./Controls/SignInActivity/SignInActivity.render";
import { IApplicationsProps } from "./Applications.types";
import { IContextualMenuItem } from "@fluentui/react";
import {
  Selection,
} from "@fluentui/react";
import { IGridViewCommand } from "../../components/gridview/models/IGridViewCommand";
import { IDetailsListProps,ConstrainMode } from "@fluentui/react";
import { useCompactRow } from "../../components/CompactDetailsRow";
import { meta } from "../../components/utils";
import { useTranslation } from "react-i18next";
import { ExportCommand } from "../../components/ExportCommand";

const renderAppsActivity = (_: IApplicationsProps, onItemInvoked: (item: any) => void): any => onItemInvoked !== undefined ? "" : " ";

export interface IUsersState {
  error?: string;
  options: IGridViewOptions;
  items?: any[];
  apps?: IContextualMenuItem;
  selectedItems?: any[]
  state?: string;
  addingApps?: boolean;
  RemovingApps?: boolean;
  modal?:boolean;
  item?:any;
  token?:string;
}

export const useApplications = (props: IApplicationsProps) => {
  const { api } = props;
  const navigate = useNavigate();
  const [state, setState] = React.useState<IUsersState>({
    options: {
      fetched: false,
      displayMode: GridViewDisplayMode.list,
      query: new QueryBuilder().top(20),
    },
  });
  

  const { error, options, items , addingApps, RemovingApps,modal,item,token  } = state;

  const getItems = async (force: boolean) => {
    try {
      const orgId = new URL(meta("WWW-authenticate")).pathname.substring(1).split('/')[0];
      console.log(token)
      const result = await (!force && state.token ? api.api(state.token) : api.api(`/servicePrincipals`)
      .header('ConsistencyLevel','eventual')
      // .count(true)
      // Note: "Unsupported or invalid query filter clause specified for property 'appOwnerOrganizationId' of resource 'ServicePrincipal'.", ***B2C only***
      // .filter(`appOwnerOrganizationId eq ${orgId}`)
      // .select(["id","appId"])
      // .skip(force ? 0 : items?.length ?? 0)
      .top(400)).get();
      
      const apps = result.value.filter((c:any) => c.signInAudience === 'AzureADandPersonalMicrosoftAccount' && c.appOwnerOrganizationId === orgId);
      setState({
        ...state,
        token: result["@odata.nextLink"],
        error: undefined,
        options: {
          ...options,
          fetched: true,
        },
        items: force ? apps : (state.items ?? []).concat(apps)
      } );
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        error: "error",
        options: {
          ...options,
          fetched: true,
        },
        items: [],
      });
    } 
  };
  const [selection] = React.useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        setSelected(selection.getSelection()??[]);
      },
    }),
  );

  const onCommonDismiss = (itemsAdd?:any[],force?: boolean) => {
    if (
      (typeof force !== "boolean" && typeof force !== "undefined") ||
      !(force ?? false)
    ) {
      itemsAdd?.map((c:any) => {c.resourceDisplayName = c.displayName; c.principalType =""; c.resourceId = c.id;c.creationTimestamp =c.createdDateTime })
      setState({
        ...state,
        items:RemovingApps  ? items?.filter((c:any)  => !selectedApps.includes(c)):items?.concat(itemsAdd),
        error: undefined,
        addingApps: false,
        RemovingApps: false,
        state: undefined,
      });
    } else if (force ?? true) {
      options.query = options.query.skip(0).top(999);
      //getItems(true);
    }
  };


  React.startTransition(() => {
    state.items === undefined && getItems(true);
  });

  const onFetch = () => {
    getItems(false);
  };
  
  const { mobile } = useMediaQueries();
  const {t} = useTranslation();
  const onItemInvoked = (item: any) =>
    item.id !== undefined && navigate(item.id);

  const columns: IColumn[] = mobile
    ? [
      {
        key: "Apps",
        name: t("Applications.Apps","Apps"),
        fieldName: "Apps",
        data: {
          type: "isodate",
          isSortable: true,
        },
        minWidth: 275,
        onRender: renderAppsActivity(props, onItemInvoked),
      },
    ]
    : [
      {
        key: "displayName",
        name: t("Applications.AppName", "App name"),
        fieldName: "displayName",
        minWidth: 220,
        maxWidth: 220,
        data: {
          isSortable: true,
        },
        visible: true
      },
      {
        key: "createdDateTime",
        name: t("Applications.CreationDate", "Creation date"),
        fieldName: "createdDateTime",
        data: {
          type: "isodate",
          isSortable: true,
        },
        minWidth: 240,
        maxWidth: 240
      },
      {
        key: "appId",
        name: t("Applications.AppId","App Id"),
        fieldName: "appId",
        minWidth: 240,
        maxWidth: 240
      },
    ].filter((x) => x.visible ?? true);

  const onSort = (fieldName: string, desc: boolean) => {
    options.query = options.query
      .skip(0)
      .top(20)
      .orderBy(...[desc ? fieldName : `${fieldName} desc`]);
    getItems(true);
  };
  const commands: IGridViewCommand[] = [
    {
      key: 'refresh',
      text: t("Common.Refresh", "Refresh"),
      ariaLabel: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      split: true,
      onClick: (ev) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        getItems(true);
      }
    },
    ExportCommand(items, columns, selection, t),

  ];
  const detailsListProps: IDetailsListProps | undefined =
    items === undefined
      ? undefined
      : {
        items: items,
        compact: true,
        constrainMode: ConstrainMode.horizontalConstrained,
        onRenderRow: useCompactRow,
        onItemInvoked: async (item: any)  =>{
            navigate(`/applications/${item.id}`, {
              state: item,
            }),
            setState({ ...state,modal:false ,item:item});
          }
      };
  const commandBarProps = () => {
    return {
      items: commands,
    };
  };
  const [selectedApps, setSelected] = React.useState<any[]>([])


  const breadcrumb = [
    {
      key: "Home",
      text: t("Common.Home","Home"),
      href: "/",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/");
      },
    },
    {
      key: "Application",
      text: t("Common.Apps","Apps")
    },
  ];

  const onSelectionChange = () => { };
  
  return {
    error,
    onFetch,
    onSort,
    options,
    items,
    onItemInvoked,
    onSelectionChange,
    columns,
    commands,
    addingApps,
    onCommonDismiss,
    RemovingApps,
    commandBarProps,
    selectedApps,
    selection,
    detailsListProps,
    modal,
    item,
    breadcrumb
  };
};
