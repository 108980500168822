import { ITheme } from "@fluentui/react";
import { AuthService } from "./components/AuthService";
import { User } from "oidc-react";
import * as React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { AppLayout } from "./AppLayout";
import { Authenticated } from "./Authenticated";
import { Client } from "@microsoft/microsoft-graph-client";

interface IAppRouteProps {
  theme: ITheme;
  onThemeChanged: (theme: string) => void;
}

export const AppRouter: React.FunctionComponent<IAppRouteProps> = ({
  theme,
  onThemeChanged,
}) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/error" element={<div><h2>Access Denied</h2></div>} />
        <Route
          path="/*"
          element={
            <Authenticated onThemeChanged={onThemeChanged} theme={theme}>
              {(user: User, authService: AuthService, api: Client) => (
                <Routes>
                  <Route path="/signout-oidc" element={<div />} />
                  <Route
                    path="/*"
                    element={
                      <AppLayout
                        user={user}
                        theme={theme}
                        api={api}
                        authService={authService}
                      />
                    }
                  />
                </Routes>
              )}
            </Authenticated>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
