import {
  ConstrainMode,
  IColumn,
  IDetailsListProps,
  Selection,
  SelectionMode,
} from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useCompactRow } from "../../components/CompactDetailsRow";
import { QueryBuilder } from "../../components/QueryBuilder";
// import { IGridViewProps } from "../../components/gridview/GridView/GridView.types";
import { GridViewDisplayMode } from "../../components/gridview/models/GridViewDisplayMode";
import { IGridViewCommand } from "../../components/gridview/models/IGridViewCommand";
import { IGridViewOptions } from "../../components/gridview/models/IGridViewOptions";
import { IOrganizationsProps } from "./Organizations.types";
import { InventoryService } from "../../InventoryService";
import { useMediaQueries } from "../../components/MediaQueries";

const MAX_ITEMS = 1000;

export interface IOrganizationsState {
  error?: string;
  options: IGridViewOptions;
  submitting: boolean;
  state?: string;
  item?:any;
  items?:any[];
}

export const useOrganizations = (props: IOrganizationsProps) => {
  const {  modal,user,item } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();

  const [state, setState] = React.useState<IOrganizationsState>({
    options: {
      fetched: false,
      displayMode: GridViewDisplayMode.list,
      query: new QueryBuilder().top(MAX_ITEMS),
    },
    submitting: false,
    state: undefined,
    
  });
  // const { mobile } = useMediaQueries();
  const [, setSelected] = React.useState<number>(0);

  const { error, options, submitting,items } = state;

  

  const getItems = async (force: boolean) => {
    try {
      console.log(item)
      const result = item ? await InventoryService.getOrganizationsByDepId(user,item.id) : await InventoryService.getOrganizations(user);
      console.log(force)
      console.log(result)

      setState({
        ...state,
        error: undefined,
        submitting: false,
        options: {
          ...options,
          fetched: true,
        },
        items: result,
      });
    } catch (e) {
      console.log(e);
      setState({
        ...state,
        error: "error",
        submitting: false,
        state: undefined,
        options: {
          ...options,
          nextLink: undefined,
          fetched: true,
        },
        items: [],
      });
    }
  };

  React.useEffect(() => {
    state.items === undefined && getItems(true);
  }, []);

  const [selection] = React.useState<Selection>(
    new Selection({
      onSelectionChanged: () => {
        setSelected(selection.count);
      },
    }),
  );

  const onFetch = () => {
    if ((items?.length ?? 0) >= MAX_ITEMS) {
      getItems(false);
    }
  };
  const mobile = useMediaQueries()
  const columns: IColumn[] = mobile
    ? [
      {
        key: "name",
        name: t("Organizations.Name", "Name") ?? "Name",
        fieldName: "name",
        minWidth: 200,
        data: {
          isSortable: true,
        },
      },
    ]
    : [
      {
        key: "name",
        name: t("Organizations.Name", "Name") ?? "Name",
        fieldName: "name",
        minWidth: 250,
        maxWidth: 350,
        data: {
          isSortable: true,
        },
      },
      // {
      //   key: "id",
      //   name: t("Departments.GridView.Columns.id", "ID") ?? "UserPrincipalName",
      //   fieldName: "id",
      //   minWidth: 250,
      //   maxWidth: 350,
      // },
    ];

  const onSort = (fieldName: string, desc: boolean) => {
    options.query = options.query
      .skip(0)
      .orderBy(...[desc ? fieldName : `${fieldName} desc`]);
    getItems(true);
  };

  const commands: IGridViewCommand[] = modal
    ? props.commands ?? []
    : props.commands ?? [
      {
        key: "refresh",
        text: t("Common.Refresh", "Refresh"),
        iconProps: {
          iconName: "Refresh",
        },
        buttonStyles: {
          root: {
            backgroundColor: "transparent",
          },
        },
        onClick: (ev) => {
          ev?.preventDefault();
          ev?.stopPropagation();
          setState({
            ...state,
            options: {
              ...options,
              fetched: undefined,
              nextLink: undefined,
            },
          });
          setTimeout(() => {
            options.query = options.query.skip(0).top(MAX_ITEMS);
            getItems(true);
          }, 400);
        },
      },
    ];

  // const onSearch = (value?: string) => {
  //   const fieldName = columns[0].fieldName;
  //   if (fieldName) {
  //     options.query = value
  //       ? options.query
  //         .skip(0)
  //         .top(MAX_ITEMS)
  //         .filter((x) => x.contains(fieldName, value))
  //       : options.query.skip(0).top(MAX_ITEMS).filter();
  //     getItems(true);
  //   }
  // };

  const commandBarProps = () => {
    return {
      items: commands,
    };
  };

  const detailsListProps: IDetailsListProps | undefined =
    items === undefined
      ? undefined
      : {
        items: items,
        compact: true,
        constrainMode: ConstrainMode.horizontalConstrained,
        onItemInvoked:
          (item: any) =>
            navigate(`/departments/${params.department ?? item.departmentId}/organizations/${item.id}`, {
              state: item,
            }),
        onRenderRow: useCompactRow,
      };

  const selectionMode = SelectionMode.multiple;

  const onCommonDismiss = (force?: boolean) => {
    if (
      (typeof force !== "boolean" && typeof force !== "undefined") ||
      !(force ?? false)
    ) {
      setState({
        ...state,
        error: undefined,
        submitting: false,
        state: undefined,
      });
    } else if (force ?? true) {
      options.query = options.query.skip(0).top(MAX_ITEMS);
      getItems(true);
    }
  };

  const breadcrumb = item? [
    {
      key: "home",
      text: t("Common.Home", "Home") ?? "Home",
      href: "/",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/");
      },
    },
    {
      key: "Dep",
      text: t("Common.Departments", "Departments") ?? "Departments",
      href: "/",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/departments");
      },
    },
    {
      key: "Organizations",
      text: item.name ?? t("Organizations.Organization","Organization")
    },
  ] :[
    {
      key: "home",
      text: t("Common.Home", "Home"),
      href: "/",
      onClick: (ev?: any) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        navigate("/");
      },
    },
    {
      key: "Organizations",
      text: t("Organizations.Organization", "Organizations") ?? "Organizations",
    },
  ];

  return {
    selectionPreservedOnEmptyClick: true,
    error,
    onFetch,
    onSort,
    options,
    columns,
    detailsListProps,
    commandBarProps,
    selectionMode,
    selection,
    submitting,
    breadcrumb,
    t,
    onCommonDismiss,
    modal,
    items
  };
};
