import { CommandBar, ICommandBarProps, Text } from "@fluentui/react";
import { FormikContextType, useFormikContext } from "formik";
import * as React from "react";
import { ErrorMessages } from "../../../../../../components/ErrorMessages";
import { SuccessMessage } from "../../../../../../components/SuccessMessage";

interface IUserProfileLayoutHeaderProps {
  commandBarProps: (form: FormikContextType<any>) => ICommandBarProps;
}

export const UserProfileLayoutHeader: React.FunctionComponent<
  IUserProfileLayoutHeaderProps
> = (props) => {
  const { commandBarProps } = props;
  const form = useFormikContext<any>();
  const itemProps = commandBarProps(form);
  return (
    <>
      <CommandBar {...props} {...itemProps} />
      <div style={{ position: "relative" }}>
        <div style={{ width: "100%", position: "absolute" }}>
          <SuccessMessage
            {...props}
            style={{ margin: "10px 0px" }}
            {...itemProps}
          />
          <ErrorMessages
            {...props}
            style={{ margin: "10px 0px" }}
            {...itemProps}
            children={<></>}
          />
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Text variant="xLarge">Identity</Text>
      </div>
    </>
  );
};
