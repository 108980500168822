
import { WebStorageStateStore, UserManagerSettings } from "oidc-client-ts";
import { meta, ServiceUris } from "./utils";

const extraParams = (): any | undefined => {
  const x = localStorage.getItem("tid");
  const locale = localStorage.getItem("mkt") ?? "en-US";
  return x === null ? { locale } : { acr_values: `tenant:${x}`, locale };
};

export const OidcSettings: UserManagerSettings = {
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: false,
  authority: ServiceUris.authority(),
  client_id: meta("oidc-client-id"),
  redirect_uri: `${location.origin}/signin-callback.html`, // ?returnUrl=${location.href}
  silent_redirect_uri: `${location.origin}/silent-renew.html`,
  response_type: "code",
  scope: `openid profile email ${meta("oidc-client-id")}`,
  post_logout_redirect_uri: `${location.origin}/signout-callback-oidc`,
  userStore: new WebStorageStateStore({ store: sessionStorage }),
  stateStore: new WebStorageStateStore({
    store: sessionStorage,
  }),
  ui_locales: localStorage.getItem("mkt") ?? "fr-CA",
  extraQueryParams: extraParams(),
  extraTokenParams: extraParams(),
};
