import { styled } from "@fluentui/react";
import { IUserBladeProps } from "./UserBlade.types";
import {
  getStyles,
  IUserBladeStyleProps,
  IUserBladeStyles,
} from "./UserBlade.styles";
import { UserBladeComponent } from "./UserBlade.base";

/**
 * UserBlade
 */
export const UserBlade = styled<
  IUserBladeProps,
  IUserBladeStyleProps,
  IUserBladeStyles
>(UserBladeComponent, getStyles);
