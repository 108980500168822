import { User } from "oidc-react";

export const IdentityUser = {
  nameOf: (user: User): string => {
    return user.profile.name as string;
  },
  firstNameOf: (user: User): string => {
    return user.profile.givenName as string;
  },

  lastNameOf: (user: User): string => {
    return user.profile.surname as string;
  },

  idpOf: (user: User): string => {
    return user.profile.idp as string ?? "local";
  },

  idOf: (user: User): string => {
    return user.profile.sub;
  },

  emailOf: (user: User): string | undefined => {
    return user.profile.email;
  },

  tenantIdOf: (user: User): string | undefined => {
    return user.profile.tid as string | undefined;
  },

  zoneInfoOf: (user: User): string => {
    return user.profile.zoneinfo ?? "America/New_York";
  },

  localeOf: (user: User): string => {
    return user.profile.locale ?? "en-US";
  },

  rolesOf: (user: User): string[] => {
    return user.profile.role === undefined ? [] : (user.profile.role as string).split(",");
  },

  sessionIdOf: (user: User): string => {
    return user.profile.locale ?? "en-US";
  },
};

/***
 *

amr: ["pwd"]
auth_time: 1624382001
email: "admin@komifo.com"
email_verified: true
http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname: "System"
http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name: "System Administrator"
http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname: "System"
idp: "local"
locale: "en-US"
name: "admin"
preferred_username: "admin@komifo.com"
role: "c27a960a-8c7a-4421-aef2-cc8c2c72b2e5"
s_hash: "RU4ijeiPFnzmqWZ0OaTHxA"
sid: "CB3EE91538ADC49D3A7C02E5D3B47303"
sub: "d08f2985-3eca-417e-8b2e-0d0f807ece5e"
tcount: "1"
tid: "2ba975ff-d8cf-407c-82d8-802494e385b1"
tname: "Example Corporation"
zoneinfo: "America/New_York"


 *
 */
