import {MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Text } from '@fluentui/react';
import * as React from 'react';
import {
  IUserAppsProps
} from './AddApps.types';
import {
  useAddApps
} from './AddApps.use';
import { GridView } from '../../../../components/gridview/GridView/GridView';
import { Dropdown } from '@fluentui/react';




export const AddAppsComponent: React.FunctionComponent<
IUserAppsProps
> = (props) => {
  const { classNames, onDismiss, 
    fileName,user, success,
     readOnly,selection,onApprove,modalselectedApps,onModalApprove,AppsModalApproved,items,onDropdownChange, ...gridProps } = useAddApps(props);
  console.log(modalselectedApps);
   return (
    <>
      <div className={classNames.root}></div>
      {AppsModalApproved ? 
      <Panel
        isOpen
        headerText="Choose roles"
        isBlocking
        customWidth='600px'
        isFooterAtBottom
        type={PanelType.medium}
        onRenderFooter={() =>
          <div style={{ padding: "10px" }}><PrimaryButton style={{backgroundColor:"red",borderColor:"red"}} text="Cancel" onClick={() => onDismiss([])} /> <PrimaryButton text="Approve" onClick={() => onApprove()} /></div>}
          
        onDismiss={() => onDismiss([])}>
        <>
          {!readOnly && <>
            <div style={{ margin: "30px 0px" }}><Text key={"textmsg"}>Please select Apps roles that you want to give {props.selected.displayName} </Text></div>
            {console.log(modalselectedApps[0].SelectedRole)}
            {modalselectedApps?.map((c:any) => c.appRoles.length >=1 && <Dropdown
        placeholder="Select an option"
        key={c.appId}
        options={c.appRoles.map((d:any)=> {return {key:d.id,text:d.displayName};})}
        required={true}
        selectedKey={c.SelectedRole}
        label={c.displayName}
        onChange={(e,item) => onDropdownChange(e, c.appId,item)}
        
      />)}
            <br></br>
            <div style={{ height: "10px" }}>Are you sure you want to add these apps to {props.selected.displayName}'s library</div>
          </>}
          
          {readOnly !== undefined && fileName !== undefined && (<>
            {success && <MessageBar messageBarType={MessageBarType.error}>
              Un problème est survenu lors de l'importation en lot.
            </MessageBar>}
            {success && <MessageBar messageBarType={MessageBarType.success}>
              Les invitations ont été traités avec succès.
            </MessageBar>}
          </>)}</>
      </Panel>
      
      :
      
      <Panel
        isOpen
        headerText="Add Apps"
        isBlocking
        customWidth='600px'
        isFooterAtBottom
        type={PanelType.extraLarge}
        onRenderFooter={() =>
          <div style={{ padding: "10px" }}><PrimaryButton style={{backgroundColor:"red",borderColor:"red"}} text="Cancel" onClick={() => onDismiss([])} /> <PrimaryButton text="Add Apps" disabled={modalselectedApps.length ==0} onClick={() => onModalApprove()} /></div>}
          
        onDismiss={() => onDismiss([])}>
        <>
          {!readOnly && <>
            <div style={{ margin: "30px 0px" }}><Text>Please select Apps that you want to give {props.selected.displayName} access </Text></div>
            <GridView  selectionMode={2} items={items}  selection={selection}{...props} {...gridProps}></GridView>
            <div style={{ height: "10px" }}></div>
          </>}
          
          {readOnly !== undefined && fileName !== undefined && (<>
            {success && <MessageBar messageBarType={MessageBarType.error}>
              Un problème est survenu lors de l'importation en lot.
            </MessageBar>}
            {success && <MessageBar messageBarType={MessageBarType.success}>
              Les invitations ont été traités avec succès.
            </MessageBar>}
          </>)}</>
      </Panel>}
    </>
  );
}
export const RemoveAppsComponent: React.FunctionComponent<
IUserAppsProps
> = (props) => {
  const { classNames, onDismiss, 
    fileName, success,
     readOnly,onApproveRemove } = useAddApps(props);
  return (
    <>
      <div className={classNames.root}></div>
      <Panel
        isOpen
        headerText="Remove apps"
        isBlocking
        isFooterAtBottom
        key={"pannel"}
        type={PanelType.smallFixedFar}
        onRenderFooter={() =>
          <div style={{ padding: "10px" }}><PrimaryButton  text="Cancel" onClick={() => onDismiss([])} /> <PrimaryButton   text="Remove Apps" style={{backgroundColor:"red",borderColor:"red"}} onClick={() => onApproveRemove(props.selectedApps)} /></div>}
        onDismiss={() => onDismiss([])}>
        <>
          {!readOnly && <>
            <div style={{ margin: "30px 0px" }}><Text>Are you sure you want to revoke {props.selected.displayName} access to these apps:</Text></div>
            {props.selectedApps.map((c:any)=>{console.log(c.resourceDisplayName); return <div key={c.id} style={{ margin: "30px 0px" }}><Text key={c.id}>- {c.resourceDisplayName}</Text></div>})}
            <div style={{ height: "10px" }}></div>
          </>}
          
          {readOnly !== undefined && fileName !== undefined && (<>
            {success && <MessageBar messageBarType={MessageBarType.error}>
              Un problème est survenu lors de l'importation en lot.
            </MessageBar>}
            {success && <MessageBar messageBarType={MessageBarType.success}>
              Les invitations ont été traités avec succès.
            </MessageBar>}
          </>)}</>
      </Panel>
    </>
  );
}