import { FormikDatePicker } from "../FormikDatePicker";
import { Field } from "formik";
import { registerWidget } from "./registry";
import { getClassName } from "./utils";

export const dateFormField = (config: any, formikParams: any) => {
  const extraProps = config ?? {};
  if (formikParams.handleChange != null)
    extraProps["onChange"] = formikParams.handleChange;
  return (
    <Field
      {...extraProps}
      name={config.name}
      type={config.type}
      className={getClassName(config)}
      component={FormikDatePicker}
    />
  );
};

registerWidget("date", dateFormField);
