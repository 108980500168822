import { FormikCustomField } from "../FormikCustomField";
import { Field } from "formik";
import { registerWidget } from "./registry";
import { getClassName } from "./utils";

export const customFormField = (
  config: any,
  formikParams: any,
  formParams?: any,
) => {
  const extraProps = config ?? {};
  if (formikParams.handleChange != null)
    extraProps["onChange"] = formikParams.handleChange;
  return (
    <Field
      {...extraProps}
      {...formParams}
      name={config.name}
      type={config.type}
      disabled={config.disabled ?? formikParams?.isSubmitting}
      label={config.label}
      className={getClassName(config)}
      id={config.name}
      component={FormikCustomField}
    />
  );
};

registerWidget("custom", customFormField);
