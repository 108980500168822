import {
  CommandBarButton,
  INavLink,
  INavLinkGroup, ITheme, Nav,
  SearchBox,
  Stack,
  StackItem,
  Text
} from "@fluentui/react";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { Theming } from "../Theming";
import {
  AppNavigationHeader,
  IAppNavigationHeaderProps
} from "./AppNavigationHeader";

export interface IAppNavigationProps {
  theme?: ITheme;
  navigation: INavLinkGroup[];
  collapsible: boolean;
  collapsed: boolean;
  onCollapsed: () => void;
  onNavigate: (value: string) => void;
  initialSelectedKey?: string;
  header?: IAppNavigationHeaderProps;
}

interface IAppNavigationState {
  filter?: string;
}

const initial: IAppNavigationState = {
  filter: undefined,
};

export const AppNavigation: React.FunctionComponent<IAppNavigationProps> = ({
  theme,
  navigation,
  collapsible,
  collapsed,
  onCollapsed,
  onNavigate,
  initialSelectedKey,
  header,
}) => {
  const [state, setState] = React.useState(initial);

  const getLink = (x: INavLink): INavLink => {
    function onDefaultClick(ev?: React.MouseEvent<HTMLElement>) {
      ev?.preventDefault();
      ev?.stopPropagation();
      if (x.path !== null) {
        onNavigate(x.path ?? x.key);
      }
      return false;
    }
    return {
      key: x.key,
      name: collapsed ?? false ? "" : x.name,
      icon: x.icon ?? x.iconProps?.iconName ?? "Document",
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      url: x.path ?? x.url ?? x.href ?? `/${x.key ?? ""}`,
      theme: theme,
      collapseByDefault: x.collapseByDefault,
      isExpanded:
        x.url !== undefined && location.pathname.startsWith(x.url)
          ? true
          : x.isExpanded,
      links: collapsed ? undefined : x.links?.map(getLink),
      iconProps: {
        title: x.name,
        styles: {
          root: {
            color: `${
              (theme?.isInverted ?? false
                ? theme?.semanticColors.bodyText
                : theme?.palette.themePrimary) ?? "#000"
            }!important`,
          },
        },
        iconName: x.icon ?? x.iconProps?.iconName,
      },
      onClick:
        x.onClick !== undefined && x.onClick != null
          ? x.onClick
          : onDefaultClick,
    };
  };

  const getNodes = (): INavLinkGroup[] => {
    return navigation
      .filter((x) => {
        if (state.filter === undefined) return x;
        return x.name?.toUpperCase().startsWith(state.filter.toUpperCase()) ??
          false
          ? x
          : state.filter !== undefined &&
            x.links.filter((z) =>
              z.name
                .toUpperCase()
                .startsWith((state.filter ?? "").toUpperCase()),
            ).length > 0
          ? x
          : undefined;
      })
      .map((y, index) => {
        return {
          ...y,
          key: `g-${index}`,
          links: y.links
            .filter((z) =>
              state.filter === undefined
                ? z
                : z.name
                    .toUpperCase()
                    .startsWith((state.filter ?? "").toUpperCase())
                ? z
                : undefined,
            )
            .map(getLink),
        };
      });
  };

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const clearFilter = () => setState({ filter: undefined });

  const onChange = (
    _event?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string,
  ) => {
    setState({ filter: newValue });
  };

  const location = useLocation();

  const styles = {
    root: {
      ".ms-Nav-compositeLink .ms-Nav-chevronButton": {
        right: "1px!important",
        lineHeight: "29px",
        height: "29px",
        marginRight: "4px",
        left: "unset",
        backgroundColor: "transparent",
        "::after": {
          borderLeft: "0px",
        },
      },
    },
    group: {
      minWidth: collapsed ? "20px" : undefined,
    },
    chevronIcon: {
      left: "unset",
      right: "1px!important",
      height: "29px",
      marginRight: "4px",
      lineHeight: "29px",
      backgroundColor: "transparent",
      color:
        theme?.isInverted ?? false
          ? theme?.semanticColors.bodyText
          : theme?.palette.themePrimary,
      "::after": {
        borderLeft: "0px!important",
      },
    },
    groupContent: {
      marginBottom: "12px",
    },
    link: collapsed
      ? {
          paddingTop: "2px",
          paddingRight: "3px",
          paddingBottom: "2px",
          paddingLeft: "3px",
          height: "32px",
          fontSize: "12px",
          backgroundColor:
            theme?.isInverted ?? false
              ? theme?.semanticColors.bodyBackground
              : "transparent",
          ":hover": {
            backgroundColor:
              theme?.isInverted ?? false
                ? theme?.semanticColors.bodyBackground
                : undefined,
          },
          ".ms-Button-icon": {
            color:
              theme?.isInverted ?? false
                ? theme?.semanticColors.bodyText
                : theme?.palette.themePrimary,
          },
        }
      : {
          height: "32px",
          backgroundColor:
            theme?.isInverted ?? false
              ? theme?.semanticColors.bodyBackground
              : "transparent",
          ":hover": {
            backgroundColor: theme?.semanticColors.bodyBackground,
          },
        },
    linkText: {
      display: collapsed ? "none" : undefined,
      fontSize: "12px",
      color: theme?.semanticColors.bodyText,
    },
  };

  const headerStyle = {
    root: {
      width: collapsed ? undefined : "100%",
      paddingBottom: "2px",
      marginBottom: "2px",
      marginLeft: collapsed ? "3px" : "6px",
      borderBottom: `1px solid ${
        theme === undefined
          ? Theming.getTheme().semanticColors.bodyFrameDivider
          : theme.semanticColors.bodyFrameDivider
      }`,
      color: collapsed ? "transparent" : "",
      fontWeight: "bold",
    },
  };

  const containerStyle = {
    root: {
      height: isMobile || !collapsible ? "100%" : "calc(-76px + 100vh)",
      overflowY: "auto",
      overflowX: "hidden",
      maxHeight: isMobile ? undefined : "calc(-144px + 100vh)",
    },
  };

  const chevronStyle = {
    root: {
      fontSize: "10px",
      backgroundColor: "transparent", // theme?.semanticColors.bodyBackground,
      color:
        theme?.isInverted ?? false
          ? theme?.semanticColors.bodyText
          : theme?.palette.themePrimary,
      ":hover": {
        backgroundColor:
          theme?.isInverted ?? false
            ? theme?.semanticColors.bodyBackground
            : "transparent",
        color:
          theme?.isInverted ?? false
            ? theme?.semanticColors.bodyText
            : theme?.palette.themePrimary,
      },
    },
  };

  const collapseStyle = {
    root: {
      height: "28px",
      backgroundColor: "transparent", // theme?.semanticColors.bodyBackground,
      color:
        theme?.isInverted ?? false
          ? theme?.semanticColors.bodyText
          : theme?.palette.themePrimary,
    },
  };

  const searchContainerStyle = {
    root: {
      ".ms-SearchBox": {
        borderRadius: "4px",
      },
      ".ms-SearchBox::after": {
        borderRadius: "4px",
      },
    },
  };

  const searchStyle = {
    root: {
      marginLeft: "6px",
      width: isMobile ? "100%" : "140px!important",
    },
  };

  return (
    <>
      {!collapsed && header && <AppNavigationHeader {...header} />}
      <div style={{ height: "5px" }} />
      {collapsed ? (
        collapsible ? (
          <CommandBarButton
            theme={theme}
            styles={collapseStyle}
            iconProps={{
              iconName: "DoubleChevronLeftMedMirrored",
              styles: chevronStyle,
            }}
            onClick={onCollapsed}
          />
        ) : (
          <></>
        )
      ) : (
        <Stack horizontal>
          <StackItem grow styles={searchContainerStyle}>
            <SearchBox
              theme={theme}
              data-dashlane-rid="0"
              autoComplete="off"
              value={state.filter}
              onClear={clearFilter}
              onChange={onChange}
              styles={searchStyle}
            />
          </StackItem>
          {!isMobile && collapsible && (
            <StackItem>
              <CommandBarButton
                theme={theme}
                styles={collapseStyle}
                iconProps={{
                  iconName: "DoubleChevronLeftMed",
                  styles: chevronStyle,
                }}
                onClick={onCollapsed}
              />
            </StackItem>
          )}
        </Stack>
      )}
      <div style={{ height: "5px" }} />
      <Stack verticalAlign="start" verticalFill styles={containerStyle}>
        <Nav
          groups={getNodes()}
          onRenderGroupHeader={(group?: INavLinkGroup) => group ? (
            <Stack key={group.name} grow horizontal>
              <Text styles={headerStyle}>{collapsed ? "" : group.name}</Text>
            </Stack>
          ) : <></>}
          styles={styles}
          ariaLabel="Navigation"
          initialSelectedKey={initialSelectedKey}
        />
      </Stack>
    </>
  );
};
