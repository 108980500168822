import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IComponentBaseStyleProps, IComponentBaseStyles, IComponentBaseClassNames, ComponentStylesLoader } from "../../../../../../components/base";

export interface IInvitationListSubComponentStyles {
  button?: IButtonStyles;
}

export interface IInvitationListTokens {
  ver?: string;
}

export interface IInvitationListStyles extends IComponentBaseStyles<IInvitationListSubComponentStyles> {

}

export interface IInvitationListStyleProps extends IComponentBaseStyleProps<IInvitationListTokens> {

}

export const getInvitationListClassNames = (
  styles?: IStyleFunctionOrObject<
    IInvitationListStyleProps,
    IInvitationListStyles
  >,
  props?: IInvitationListStyleProps
): IComponentBaseClassNames<
  IInvitationListStyleProps,
  IInvitationListStyles,
  IInvitationListSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IInvitationListStyleProps,
    IInvitationListStyles,
    IInvitationListSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IInvitationListStyleProps,
  IInvitationListStyles

> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IInvitationListStyleProps): IInvitationListStyles => {
    return {
      root: ['app-InvitationList', {

      }]
    };
  };
