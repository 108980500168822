import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../../../../components/base";

export interface IUserHeaderSubComponentStyles {
  button?: IButtonStyles;
}

export interface IUserHeaderTokens {
  ver?: string;
}

export interface IUserHeaderStyles
  extends IComponentBaseStyles<IUserHeaderSubComponentStyles> { }

export interface IUserHeaderStyleProps
  extends IComponentBaseStyleProps<IUserHeaderTokens> { }

export const getUserHeaderClassNames = (
  styles?: IStyleFunctionOrObject<IUserHeaderStyleProps, IUserHeaderStyles>,
  props?: IUserHeaderStyleProps,
): IComponentBaseClassNames<
  IUserHeaderStyleProps,
  IUserHeaderStyles,
  IUserHeaderSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IUserHeaderStyleProps,
    IUserHeaderStyles,
    IUserHeaderSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IUserHeaderStyleProps,
  IUserHeaderStyles
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (_props: IUserHeaderStyleProps): IUserHeaderStyles => {
  return {
    root: ["app-UserHeader", {}],
  };
};
