import { Stack } from "@fluentui/react";
import { FormPanel } from "./FormPanel";
import { IFormProps } from "./IFormProps";
import { makeWidget, registerRenderer } from "./registry";

export const getRendererOption = (
  schema: any,
  name: string,
  defaultValue: any,
) =>
  (schema &&
    schema.form &&
    schema.form.rendererOptions &&
    schema.form.rendererOptions[name]) ||
  defaultValue;

export const renderFieldAsRow = (formikParams: any) => (config: any) =>
(
  <Stack grow tokens={{ childrenGap: 10 }}>
    {config.onRenderFieldHeader?.(formikParams, config)}
    {makeWidget(config, formikParams)}
    {config.helpText !== undefined &&
      typeof config.helpText === "function" ? (
      config.helpText({
        ...formikParams,
        ...config,
      })
    ) : (
      <small className="form-text text-muted">{config.helpText}</small>
    )}
    {config.onRenderFieldFooter?.(formikParams, config)}
  </Stack>
);

registerRenderer("stack", (props: IFormProps) => (formikParams: any) => (
  <form onSubmit={formikParams.handleSubmit}>
    {props.schema.form.onRenderLayoutHeader?.(props)}
    {props.schema.fields
      .filter((x) => props.schema.predicate?.(formikParams, x) ?? true)
      .map((x, i) => (
        <div key={i.toString()}>{renderFieldAsRow(formikParams)(x)}</div>
      ))}
    {props.schema.form.onRenderLayoutFooter?.(props)}
  </form>
));



registerRenderer("panel", (props: IFormProps) => (formikParams: any) => (
  <form onSubmit={formikParams.handleSubmit}>
    <FormPanel {...props} {...formikParams}>
      {props.schema.form.onRenderLayoutHeader?.({
        ...props,
        ...formikParams,
      })}
      {props.schema.fields
        .filter((x) => props.schema.predicate?.(formikParams, x) ?? true)
        .map((x, i) => (
          <div key={i.toString()}>{renderFieldAsRow(formikParams)(x)}</div>
        ))}
      {props.schema.form.onRenderLayoutFooter?.(props)}
    </FormPanel>
  </form>
));

registerRenderer("twocolumns", (props: IFormProps) => (formikParams: any) => (
  <form onSubmit={formikParams.handleSubmit}>
    {props.schema.form.onRenderLayoutHeader?.(props)}
    <Stack horizontal tokens={{ childrenGap: 15 }}>
      <Stack grow>
        <Stack tokens={{ childrenGap: 5 }}>
          {props.schema.fields
            .filter(
              (x) => props.schema.predicate?.(formikParams, x) ?? true,
            )
            .filter((_x, i) => i % 2 === 0)
            .map((x, i) => (
              <div key={i.toString()}>
                {renderFieldAsRow(formikParams)(x)}
              </div>
            ))}
        </Stack>
      </Stack>
      <Stack grow>
        <Stack tokens={{ childrenGap: 5 }}>
          {props.schema.fields
            .filter(
              (x) => props.schema.predicate?.(formikParams, x) ?? true,
            )
            .filter((_x, i) => i % 2 !== 0)
            .map((x, i) => (
              <div key={i.toString()}>
                {renderFieldAsRow(formikParams)(x)}
              </div>
            ))}
        </Stack>
      </Stack>
    </Stack>
    {props.schema.form.onRenderLayoutFooter?.(props)}
  </form>
));


export const FORMIK_PANEL_RENDERER = "panel";
export const FORMIK_STACK_RENDERER = "stack";
export const FORMIK_TWOCOLS_RENDERER = "twocolumns";