import { IDepartmentResourceProps } from "./DepartmentResource.types";
import { getDepartmentResourceClassNames } from "./DepartmentResource.styles";

export const useDepartmentResource = (
  props: IDepartmentResourceProps,
) => {
  const { styles,item } = props;
  const { classNames /* , subComponentStyles */ } =
    getDepartmentResourceClassNames(styles, {
      ...props,
    });
  return {
    classNames,
    item

  };
};
