import { IPanelProps, IRenderFunction, Stack, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { ErrorMessages } from "../../../../components/ErrorMessages";

const InvitationInstructions: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return <Text>{t("UserInvitation.Messages.Instructions", "Enter the email address of the person you would like to invite.")}</Text>
}

export const renderUserInvitationBody: IRenderFunction<IPanelProps> = (
  props,
  defaultRenderer,
) => (
  <>
    <div style={{ height: "25px" }}></div>
    <div style={{ margin: "0px 25px" }}>
      <InvitationInstructions />
    </div>
    <ErrorMessages {...props}>
      <div></div>
    </ErrorMessages>
    <Stack
      grow
      tokens={{ childrenGap: 10 }}
      styles={{
        root: {
          ".ms-TextField": {
            marginBottom: "10px",
          },
        },
      }}
    >
      {defaultRenderer?.(props)}
    </Stack>
  </>
);
