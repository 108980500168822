import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { IAppLayoutProps } from "./AppLayout";
import { IHeaderProps } from "./components/Layouts/Header";
import { useMediaQueries } from "./components/MediaQueries";
import { triggerEvent } from "./components/useEvent";
import { INotificationItem } from "./models/INotificationItem";
import { asNotificationsCommand } from "./modules/Notifications/Notifications.use";
import {
  GlobalNavigationResources,
  NavigationResources
} from "./NavigationItems";

export const notify = (data?: INotificationItem) =>
  triggerEvent("onNotification", data);

const useGlobalTranslation = () => {
  const { t } = useTranslation("globals");
  return t;
};

export const useAppLayout = (props: IAppLayoutProps) => {
  const { user, authService } = props;
  const { t } = useTranslation();
  const gres = new GlobalNavigationResources(useGlobalTranslation());
  const res = new NavigationResources(t);
  const navigate = useNavigate();
  const { mobile } = useMediaQueries();

  const onNavigate = (value: string) => {
    if (value.startsWith("https://")) document.location.href = value;
    else navigate(value);
  };
  const [isNotficationOpen, setShowNotifications] = React.useState(false);
  const onOpenNotifications = () => {
    if (isNotficationOpen) {
      setShowNotifications(false);
    } else {
      notify(undefined);
      setShowNotifications(true);
    }
  };
  const onDismissNotifications = () => {
    notify(undefined);
    setShowNotifications(false);
  };

  const headerProps: IHeaderProps = {
    appBarProps: {
      title: res.ApplicationTitle,
      user: user,
      commands: [asNotificationsCommand(props, onOpenNotifications)],
      nav: gres.global,
      appNav: mobile ? undefined : res.application,
      areaNav: mobile ? res.application : undefined,
      onSignout: () => authService.logout(),
      onUserChangeRequested: (id: string, action?: string) => {
        if (id && action == "tenant") {
          localStorage.setItem("tid", id);
          for (const key in sessionStorage) {
            if (key.indexOf("oidc.user") === 0) {
              sessionStorage.removeItem(key);
            }
          }
          document.location.reload();
        } else authService.logout();
      },
      onNavigate: onNavigate,
    },
  };

  return {
    headerProps,
    onNavigate,
    isNotficationOpen,
    onOpenNotifications,
    onDismissNotifications,
  };
};
