import { IPanelProps } from "@fluentui/react";
import { FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { trigger } from "../../../../components/events";
import { IFormSchema } from "../../../../components/formik/schema/IFormProps";
import { InvitationService } from "../../../../InvitationService";
import { INotificationItem } from "../../../../models/INotificationItem";
import { IUserInvitation } from "../../../../models/IUserInvitation";
import { asNotificationItem } from "./NotificationItem";
import { renderUserInvitationBody } from "./UserInvitationForm.body";
import { IUserInvitationFormProps } from "./UserInvitationForm.types";


export const useUserInvitationForm = (props: IUserInvitationFormProps) => {
  const { authService, user, onDismiss } = props;
  const { t } = useTranslation();
  const locales = [
    {
      key: "fr-CA",
      text: t("UserInvitation.Locales.fr.Name")
    },
    {
      key: "en-US",
      text: t("UserInvitation.Locales.en.Name")
    }
  ]
  const accountTypes = [
    t("UserInvitation.AccountTypes.Standard", "Standard Account"),
    t("UserInvitation.AccountTypes.Federated", "Federated Account")
  ].map((x, i) => ({
    key: i,
    text: x
  }));


  const schema: IFormSchema = {
    form: {
      renderer: "panel",
    },
    fields: [
      {
        name: "email",
        type: "email",
        label: t("UserInvitation.Fields.Email", "Email"),
        maxLength: 512,
        required: true,
      },
      {
        name: "firstName",
        type: "text",
        label: t("UserInvitation.Fields.FirstName", "First name"),
        maxLength: 255,
        required: true
      },
      {
        name: "lastName",
        type: "text",
        label: t("UserInvitation.Fields.LastName", "Last name"),
        maxLength: 255,
        required: true
      },
      {
        name: "type",
        type: "option",
        label: t("UserInvitation.Fields.AccountType", "Account Type"),
        options: accountTypes,
        required: true
      },
      {
        name: "locale",
        type: "option",
        label: t("UserInvitation.Fields.Locale", "Language"),
        options: locales,
        required: true
      },
    ],
  };

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    type: 1,
    locale: "fr-CA"
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .max(255, t("UserInvitation.Validation.Email.Maxlength", "255 characters maximum") ?? "")
      .required(t("UserInvitation.Validation.Email.Required", "Email address is required") ?? "")
      .email(t("UserInvitation.Validation.Email.Format", "The email is not valid") ?? ""),
    message: Yup.string().max(400, t("UserInvitation.Validation.Message.MaxLength", "400 characters maximum") ?? ""),
    firstName: Yup.string().max(255, t("UserInvitation.Validation.FirstName.MaxLength", "255 characters maximum") ?? "")
      .required(t("UserInvitation.Validation.FirstName.Required", "Firstname is required") ?? ""),
    lastName: Yup.string().max(255, t("UserInvitation.Validation.LastName.MaxLength", "255 characters maximum") ?? "")
      .required(t("UserInvitation.Validation.LastName.Required", "Lastname is required") ?? ""),
  });

  const onSubmit = async (
    values: IUserInvitation,
    formikHelpers: FormikHelpers<any>,
  ) => {
    try {
      const expires_in =
        user?.expires_in == undefined
          ? user?.profile.auth_time === undefined
            ? 0
            : user?.profile.auth_time + 3600 - Math.round(Date.now() / 1000)
          : user?.expires_in;
      if (expires_in < 25) {
        authService.login();
        return;
      }
      const response = await InvitationService.invite(values, user);
      if (response.status > 299) {
        formikHelpers.setFieldError(
          "form",
          t("UserInvitation.Messages.InvitationCreationError", "We could not create an invitation for this user right now.") ?? "",
        );
        formikHelpers.setSubmitting(false);
      }
      else {
        const j = await response.json();
        const invitationUrl = j?.invitationUrl;
        const notif: INotificationItem = {
          title: t("UserInvitation.Messages.InvitationCreated", "Invitation Created"),
          description: asNotificationItem({ url: invitationUrl, text: "Invitation Link" }),
          level: 4,
          timestamp: new Date()
        };
        trigger("onNotification", notif);
        console.log('Invitation Url', invitationUrl);
        formikHelpers.setSubmitting(false);
        onDismiss();
      }
    } catch (e) {
      console.log(e);
      formikHelpers.setFieldError(
        "form",
        t("UserInvitation.Messages.InvitationCreationError", "We could not create an invitation for this user right now.") ?? "",
      );
      formikHelpers.setSubmitting(false);
    }
  };

  const panelProps: IPanelProps = {
    onDismiss: onDismiss,
    headerText: t("UserInvitation.Messages.Header", "Invite User") ?? "",
    onRenderBody: renderUserInvitationBody,
  };

  const text = t("UserInvitation.Commands.Invite", "Invite");

  return {
    schema: schema,
    initialValues,
    onSubmit,
    text,
    panelProps,
    validationSchema,
  };
};
