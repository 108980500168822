import { Formik } from "formik";
import * as React from "react";
import { IFormProps } from "./IFormProps";
import { buildRenderFunction } from "./registry";

export const Form: React.FunctionComponent<IFormProps> = (props) => {
  const { render, schema, validationSchema, onSubmit } = props;
  return (
    <Formik
      {...(props as any)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {render === undefined || typeof render !== "function"
        ? buildRenderFunction(props)
        : render(schema)}
    </Formik>
  );
};
