import { FilterBuilder } from "./FilterBuilder";


const encode = (q: string) => {
  const searchParams = new URLSearchParams();
  Object.keys(q).forEach((x) => searchParams.append(x, (q as any)[x]));
  return searchParams.toString().replace(/%24/, '$');
};


interface QueryReader {
  top: () => number | undefined;
  skip: () => number | undefined;
  skipToken: () => string | undefined;
  pageToken: () => string | undefined;
  select: () => string[] | undefined;
  orderBy: () => string[] | undefined;
  filter: () => string | undefined;
  expand: () => string[] | undefined;
  count: () => boolean | undefined;
}

export class QueryBuilder {
  public top(top: number): QueryBuilder {
    this._top = top;
    return this;
  }

  private _reader: QueryReader = {
    top: () => this._top,
    skip: () => this._skip,
    skipToken: () => this._skipToken,
    pageToken: () => this._pageToken,
    select: () => this._select,
    orderBy: () => this._orderBy,
    filter: () => this._filter,
    expand: () => this._expand,
    count: () => this._count,
  };

  private _skip?: number;
  private _top?: number;
  private _skipToken?: string;
  private _pageToken?: string;
  private _select?: string[];
  private _orderBy?: string[];
  private _filter?: string;
  private _expand?: string[];
  private _count?: boolean;

  public get(): QueryReader {
    return this._reader;
  }

  public skip(skip: number): QueryBuilder {
    this._skip = skip;
    return this;
  }

  public toQ(skipOrder?: boolean) {
    const q = {} as any;
    if (this._skip !== undefined) q.$skip = this._skip;
    if (this._top !== undefined) q.$top = this._top;
    if (this._skipToken !== undefined) q.$skiptoken = this._skipToken;
    if (this._pageToken !== undefined) q.$pagetoken = this._pageToken;
    if (this._count !== undefined) q.$count = this._count;
    if (this._select !== undefined) q.$select = this._select.join(",");
    if (this._orderBy !== undefined && !(skipOrder ?? false)) q.$orderBy = this._orderBy.join(",");
    if (this._expand !== undefined) q.$expand = this._expand;
    if (this._filter !== undefined) q.$filter = this._filter;
    return q;
  }

  public toQs(skipOrder?: boolean) {
    return encode(this.toQ(skipOrder));
  }

  public count(): QueryBuilder {
    this._count = true;
    return this;
  }

  public skipToken(skipToken: string): QueryBuilder {
    if (this._skipToken !== skipToken) {
      this._skip = 0;
    }
    this._skipToken = skipToken;
    return this;
  }

  public pageToken(pageToken: string): QueryBuilder {
    if (this._pageToken !== pageToken) {
      this._skip = 0;
    }
    this._pageToken = pageToken;
    return this;
  }

  public select(...fields: string[]): QueryBuilder {
    this._select = fields;
    return this;
  }

  public orderBy(...fields: string[]): QueryBuilder {
    this._orderBy = fields;
    return this;
  }

  public expand(
    field: string,
    ...callbacks: ((builder: QueryBuilder) => void)[]
  ): QueryBuilder {
    const q: string[] = [`${field}`];
    if (callbacks.length > 0) {
      const b = new QueryBuilder();
      callbacks.forEach((c) => c(b));
      q.push(b.toSubQuery());
    }
    this._expand = q;
    return this;
  }

  public filter(
    ...callbacks: ((builder: FilterBuilder) => void)[]
  ): QueryBuilder {
    if ((callbacks?.length ?? 0) > 0) {
      let q: string = ``;
      const filterBuilder = new FilterBuilder();
      callbacks.forEach((c) => c(filterBuilder));
      q += filterBuilder.toQuery();
      this._filter = q;
    } else {
      this._filter = undefined;
    }
    return this;
  }

  private toSubQuery(): string {
    //real vs q object // TODO:
    return ""; // "(" + this.query.join(";") + ")";
  }
}
