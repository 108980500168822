import { DefaultPalette, ISliderProps, Slider, Text } from "@fluentui/react";
import { FieldProps } from "formik";
import { FormikTextField } from "./FormikTextField";
import { createFakeEvent, getErrorMessage, handleProps, Omit } from "./utils";

export function mapFieldToSlider<V extends number = number, FormValues = any>({
  form,
  field,
}: FieldProps<V, FormValues>): Pick<
  ISliderProps,
  "value" | "onChange" | "onChanged"
> {
  const shared = {
    readOnly: (field as any).readOnly ?? form?.status?.readOnly,
  };
  return {
    ...field,
    ...shared,
    value: field.value,
    onChange: (value) => {
      form.setFieldValue(field.name, value);
    },
    onChanged: () => field.onBlur(createFakeEvent(field)),
  };
}

export type FormikSliderProps<
  V extends number = number,
  FormValues = any,
> = Omit<ISliderProps, "value" | "onChange" | "onChanged"> &
  FieldProps<V, FormValues>;

export function FormikSlider<V extends number = number, FormValues = any>({
  field,
  form,
  meta,
  ...props
}: FormikSliderProps<V, FormValues>) {
  const fieldProps = mapFieldToSlider({ field, form, meta });
  const { readOnly } = props as any;
  const { theme } = props;
  const errorMessage = getErrorMessage({ form, field, meta });
  return readOnly ? (
    FormikTextField({
      ...handleProps(props),
      ...fieldProps,
      errorMessage: errorMessage,
      field: field,
      form,
      meta,
    } as any)
  ) : (
    <>
      <Slider {...handleProps(props)} {...fieldProps} />
      {errorMessage !== undefined && (
        <div>
          <Text
            variant="small"
            styles={{
              root: { color: theme?.palette.redDark ?? DefaultPalette.redDark },
            }}>
            {errorMessage}
          </Text>
        </div>
      )}
    </>
  );
}
