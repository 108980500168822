import { Breadcrumb, Link, Text } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { getSettingsClassNames } from "./Settings.styles";
import { ISettingsProps } from "./Settings.types";
import { useSettings } from "./Settings.use";

export const SettingsComponent: React.FunctionComponent<ISettingsProps> = (
  props,
) => {
  const { styles } = props;
  const { classNames /* , subComponentStyles */ } = getSettingsClassNames(
    styles,
    {
      ...props,
    },
  );
  const { breadcrumb, onDownloadOpenId, onDownloadKeys } = useSettings(props);
  const { t } = useTranslation();
  return (
    <div className={classNames.root}>
      <Breadcrumb items={breadcrumb} />
      <div style={{ padding: "10px" }}>
        <Text>{t("Settings.InstructionsTitle", "Configure all settings for your organization.")}</Text>
        <div style={{ marginTop: 50, marginLeft: 15 }}>
          <Link href="#" onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            onDownloadOpenId();
          }}>{t("Settings.DownloadJwtKeys", "Download openid configuration")}</Link>
        </div>
        <div style={{ marginTop: 50, marginLeft: 15 }}>
          <Link href="#" onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            onDownloadKeys();
          }}>
            {t("Settings.DownloadJwtKeys", "Dowload openid keys")}
          </Link>
        </div>
      </div>
    </div>
  );
};
