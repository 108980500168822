import { styled } from "@fluentui/react";
import { IUserSecurityProps } from "./UserSecurity.types";
import {
  getStyles,
  IUserSecurityStyleProps,
  IUserSecurityStyles,
} from "./UserSecurity.styles";
import { UserSecurityComponent } from "./UserSecurity.base";

/**
 * UserSecurity
 */
export const UserSecurity = styled<
  IUserSecurityProps,
  IUserSecurityStyleProps,
  IUserSecurityStyles
>(UserSecurityComponent, getStyles);
