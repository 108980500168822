import { useTranslation } from "react-i18next";
import { GridViewDisplayMode } from "../models/GridViewDisplayMode";
import { useGridViewColumns } from "./GridView.columns";
import { IGridViewProps } from "./GridView.types";

export const useGridView = (props: IGridViewProps) => {
  const { options, onFetch, items = [] } = props;
  const { t } = useTranslation("globals");
  const onFetchFallback = () => {};
  const { fetched, displayMode = "list", query } = options;
  const columns = useGridViewColumns(props);
  const top = query.get().top() ?? 20;

  const listProps = {
    getItemCountForPage: () => top,
    getPageSpecification: () => {
      return {
        itemCount: items.length < top ? top : items.length,
      };
    },
  };

  const emptyMessage =
    props.emptyMessage ??
    t("GridView.NoItemsMesssage", "There are no items available");

  return {
    emptyMessage,
    listProps,
    onFetch: onFetch ?? onFetchFallback,
    displayMode,
    fetched,
    top,
    columns:
      !displayMode || displayMode === GridViewDisplayMode.list
        ? columns
        : columns.slice(
            props.summaryColumStartIndex ?? 0,
            props.summaryColumCount ?? 1,
          ),
  };
};
