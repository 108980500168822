import { Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import { IOrganizationResourceProps } from "./OrganizationResource.types";
import { useOrganizationResource } from "./OrganizationResource.use";
import { useTranslation } from "react-i18next";

export const OrganizationResourceComponent: React.FunctionComponent<
  IOrganizationResourceProps
> = (props) => {
  const { classNames } = useOrganizationResource(props);
  const { t } = useTranslation();
  return (
    <div className={classNames.root}>
      <Pivot>

        <PivotItem key={"users"} headerText={t("Common.Users", "Users")}>
          {/* <Hostnames {...props} styles={undefined} /> */}
        </PivotItem>
      </Pivot>
    </div>
  );
};
