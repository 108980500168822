import { styled } from "@fluentui/react";
import { IUserHeaderProps } from "./UserHeader.types";
import {
  getStyles,
  IUserHeaderStyleProps,
  IUserHeaderStyles,
} from "./UserHeader.styles";
import { UserHeaderComponent } from "./UserHeader.base";

/**
 * UserHeader
 */
export const UserHeader = styled<
  IUserHeaderProps,
  IUserHeaderStyleProps,
  IUserHeaderStyles
>(UserHeaderComponent, getStyles);
