import { Persona, PersonaSize, Stack, StackItem } from "@fluentui/react";
import { MediaQueries, useMediaQueries } from "../../../../../../components/MediaQueries";
import { Form, Formik } from "formik";
import moment from "moment";
import * as React from "react";
import { PropertyField } from "../../../../../../components/PropertyField/PropertyField";
import { IUser } from "../../../../../../models/IUser";
import { getUserOverviewClassNames } from "./UserOverview.styles";
import { IUserOverviewProps } from "./UserOverview.types";
import { useTranslation } from "react-i18next";

const formatted = (user: IUser): IUser =>
  Object.assign(user, {
    createdOn:
      user.createdOn === undefined
        ? undefined
        : moment(new Date(user.createdOn)).format("LLLL"),
  });

export const UserOverviewComponent: React.FunctionComponent<
  IUserOverviewProps
> = (props) => {
  const { styles, selected } = props;
  const { classNames /* , subComponentStyles */ } = getUserOverviewClassNames(
    styles,
    {
      ...props,
    },
  );

  const { displayName, otherMails, email, userPrincipalName } = selected;
  const {t} = useTranslation()
  const { mobile, tablet } = useMediaQueries();
  return (
    <div className={classNames.root}>
      <Stack
        horizontal={!mobile && !tablet}
        grow
        styles={{
          root: {
            maxWidth: "880px",
          },
        }}
      >
        <StackItem grow>
          <Stack grow tokens={{ childrenGap: 10 }}>
            <Persona
              text={displayName}
              secondaryText={(otherMails?.[0] ?? email)?.toLowerCase()}
              tertiaryText={userPrincipalName}
              size={PersonaSize.size100}
              styles={{
                root: {
                  height: "unset",
                  [MediaQueries.mobile]: {
                    marginLeft: "-5px",
                  },
                  [MediaQueries.tablet]: {
                    marginLeft: "-5px",
                  },
                },
              }}
              coinProps={{
                styles: {
                  coin: { display: "none" },
                },
              }}
            />
            <Formik
              initialValues={formatted(selected)}
              onSubmit={() => undefined}
            >
              <Form>
                <PropertyField
                  {...props}
                  styles={undefined}
                  property={{
                    key: "givenName",
                  }}
                  label={t("Users.FirstName","First Name")}
                  readOnly
                  schema={{
                    fields: [
                      {
                        key: "givenName",
                      },
                    ],
                    required: [],
                  }}
                />
                <PropertyField
                  {...props}
                  styles={undefined}
                  property={{
                    key: "surname",
                  }}
                  label={t("Users.LastName","Last Name")}
                  readOnly
                  schema={{
                    fields: [
                      {
                        key: "surname",
                      },
                    ],
                    required: [],
                  }}
                />
                <PropertyField
                  {...props}
                  styles={undefined}
                  property={{
                    key: "email",
                  }}
                  label={t("Users.Email","Email")}
                  readOnly
                  schema={{
                    fields: [
                      {
                        key: "email",
                      },
                    ],
                    required: [],
                  }}
                />
                <PropertyField
                  {...props}
                  styles={undefined}
                  property={{
                    key: "createdDateTime",
                  }}
                  label={t("Users.CreatedOn","Created On")}
                  readOnly
                  schema={{
                    fields: [
                      {
                        key: "createdDateTime",
                      },
                    ],
                    required: [],
                  }}
                />
              </Form>
            </Formik>
          </Stack>
        </StackItem>
      </Stack>
      <div style={{ height: "75px" }}></div>
    </div>
  );
};
