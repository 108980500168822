import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../../../../components/base";

export interface IUserNavigationSubComponentStyles {
  button?: IButtonStyles;
}

export interface IUserNavigationTokens {
  ver?: string;
}

export interface IUserNavigationStyles
  extends IComponentBaseStyles<IUserNavigationSubComponentStyles> { }

export interface IUserNavigationStyleProps
  extends IComponentBaseStyleProps<IUserNavigationTokens> { }

export const getUserNavigationClassNames = (
  styles?: IStyleFunctionOrObject<
    IUserNavigationStyleProps,
    IUserNavigationStyles
  >,
  props?: IUserNavigationStyleProps,
): IComponentBaseClassNames<
  IUserNavigationStyleProps,
  IUserNavigationStyles,
  IUserNavigationSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IUserNavigationStyleProps,
    IUserNavigationStyles,
    IUserNavigationSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IUserNavigationStyleProps,
  IUserNavigationStyles
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (_props: IUserNavigationStyleProps): IUserNavigationStyles => {
  return {
    root: [
      "app-UserNavigation",
      {
        marginRight: "15px",
      },
    ],
  };
};
