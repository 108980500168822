import { ICommandBarProps } from "@fluentui/react";
import { IFormProps } from "../../../../../../components/formik/schema/IFormProps";
import { FormikContextType } from "formik";
import { IUserProfileProps } from "./UserProfile.types";
import { UserProfileLayoutHeader } from "./UserProfileLayoutHeader";

export const useUserPofileLayoutHeader =
  (
    props: IUserProfileProps,
    commandBarProps: (form: FormikContextType<any>) => ICommandBarProps,
  ) =>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_schema: IFormProps) =>
      <UserProfileLayoutHeader {...props} commandBarProps={commandBarProps} />;
