import { IPanelProps, Panel, PanelType } from "@fluentui/react";
import * as React from "react";
import { PanelCommandBar } from "./PanelCommandBar";

interface IFormPanelProps {
  panelProps?: IPanelProps;
  children?: any;
}

export const FormPanel: React.FunctionComponent<IFormPanelProps> = ({
  panelProps,
  children,
  ...props
}) => (
  <Panel
    isOpen={true}
    {...panelProps}
    styles={panelProps?.styles}
    isFooterAtBottom={true}
    type={panelProps?.type ?? PanelType.medium}
    headerText={panelProps?.headerText ?? ""}
    onRenderFooterContent={() => (
      <PanelCommandBar {...props} onDismiss={panelProps?.onDismiss} />
    )}>
    {children}
  </Panel>
);
