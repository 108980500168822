import { copyToClipboard } from "../../../../../../components/copyToClipboard";
import React from "react";
import { getObjectModel } from "../../../../../../components/utils";
import { IUserExportProps } from "./UserExport.types";

interface IEditorValue {
  target: any;
  content: string;
}

export const useUserExport = (props: IUserExportProps) => {
  const [value, setValue] = React.useState<IEditorValue | undefined>(undefined);

  React.startTransition(() => {
    function assignValue() {
      const target = getObjectModel(props.selected);
      setValue({
        target,
        content: JSON.stringify(target, null, 2),
      });
    }
    (value === undefined || props.selected.id !== value.target.id) &&
      assignValue();
  });

  function download(filename: string, text: string) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text),
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const commandBarProps = {
    items: [
      {
        key: "download",
        text: "Download",
        iconProps: {
          iconName: "Download",
        },
        onClick: () => {
          value !== undefined &&
            download(`${props.selected.id}.json`, value.content);
        },
      },
      {
        key: "copy",
        text: "Copy to clipboard",
        iconProps: {
          iconName: "Copy",
        },
        onClick: () => {
          value !== undefined && copyToClipboard(value.content);
        },
      },
    ],
  };
  return {
    value,
    commandBarProps,
  };
};
