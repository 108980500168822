import Papa from "papaparse";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InvitationService } from "../../../../InvitationService";
import { IUserInvitation } from "../../../../models/IUserInvitation";
import { getBulkInvitationClassNames } from './BulkInvitation.styles';
import {
  IBulkInvitationProps
} from './BulkInvitation.types';


interface IBulkInvitationState {
  running: boolean;
  errorMessage?: string;
  success: boolean;
  index: number;
  items?: IUserInvitation[];
  fileName?: string;
  done?: IUserInvitation[];
  fileLoaded: boolean;
  accountType: number;
}

export const useBulkInvitation = (props: IBulkInvitationProps) => {
  const { styles, theme, user, onDismiss } = props;
  const { classNames /* , subComponentStyles */ } = getBulkInvitationClassNames(
    styles,
    {
      ...props,
    }
  );
  const [state, setState] = React.useState<IBulkInvitationState>({
    running: false,
    index: 0,
    success: false,
    fileLoaded: false,
    accountType: 1 /* Default to Federated */
  });
  const { t } = useTranslation();
  const { running, errorMessage, success, items,
    done, fileName, fileLoaded, index } = state;
  const fileUploadRef = React.useRef<HTMLInputElement | undefined>(undefined);

  async function invite(items?: IUserInvitation[]) {
    if (items === undefined || errorMessage !== undefined) return;
    const values = items[index];
    const response = await InvitationService.invite(values, user);
    if (response.status > 299) {
      setState({
        ...state,
        index: 0,
        errorMessage: "error",
      })
    }
    else {
      setState({
        ...state,
        index: index + 1,
        done: (done ?? []).concat([values]),
        errorMessage: "error",
      });
      if (index + 1 < items.length - 1) {
        setTimeout(() => invite(items), 900);
      }
      else {
        setState({
          ...state,
          success: true
        });
      }
    }
  }

  const onFileUpload = () => {
    setState({
      ...state,
      fileLoaded: true
    });
  };

  const onStartUpload = () => {
    const { files } = fileUploadRef.current as any;
    if (running || files.length === 0) {
      return;
    } else {
      for (let i = 0; files.length; i++) {
        // const { name, size, lastModified, type } = files[i];
        let r = false;
        Papa.parse(files[i], {
          header: true,
          skipEmptyLines: true,
          complete: function (results: any) {
            const values = (items ?? []).concat((results.data ?? []).map((x: any) => ({ ...x, type: 1, displayName: x.displayName ?? `${x.firstName} ${x.lastName}` })));
            setState({
              ...state,
              index: 0,
              running: true,
              items: values,
              done: [],
              fileName: files[i].name ?? "default"
            });
            !r && invite(values);
            r = true;
          },
        });
      }
    }
  };

  const iconButtonStyles = {
    root: {
      backgroundColor: "transparent",
    },
    rootHovered: {
      backgroundColor: "transparent",
      border: "0px",
    },
  };

  const onAccountTypeChanged = (key: number) => {
    setState({
      ...state,
      accountType: key
    })
  }

  return {
    classNames,
    readOnly: running,
    state,
    accountType: state.accountType,
    setState,
    errorMessage,
    success,
    theme,
    onDismiss,
    iconButtonStyles,
    fileUploadRef,
    onFileUpload,
    onStartUpload,
    onAccountTypeChanged,
    fileLoaded,
    items,
    done,
    index,
    fileName,
    t,
  };
}