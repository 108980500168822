import React from "react";
import { on, off } from "./events";

export function triggerEvent(eventType: string, data?: any) {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

export const useEvent = (eventType: string, callback: (args?: any) => void) => {
  return React.useEffect(() => {
    const listener = {
      handleEvent: (ev: CustomEvent) => callback(ev.detail),
    };
    on(eventType, listener);
    return () => off(eventType, listener);
  }, []);
};
