import { CommandBar } from "@fluentui/react";
import * as React from "react";
import { GridViewConfirmDialog } from "../GridViewConfirmDialog/GridViewConfirmDialog";
import { getGridViewCommandBarClassNames } from "./GridViewCommandBar.styles";
import { IGridViewCommandBarProps } from "./GridViewCommandBar.types";
import { useGridViewCommandBar } from "./GridViewCommandBar.use";

export const GridViewCommandBarComponent: React.FunctionComponent<
  IGridViewCommandBarProps
> = (props) => {
  const { styles } = props;
  const { classNames, subComponentStyles } = getGridViewCommandBarClassNames(
    styles,
    {
      ...props,
    },
  );

  const { onConfirming, confirmingItem, commandBarProps } =
    useGridViewCommandBar(props);

  return (
    <>
      <GridViewConfirmDialog
        {...props}
        isConfirming={confirmingItem !== undefined}
        confirmMessage={
          typeof confirmingItem?.confirmingMessage === "function"
            ? confirmingItem?.confirmingMessage()
            : confirmingItem?.confirmingMessage
        }
        confirmTitle={confirmingItem?.confirmTitle}
        onDismiss={onConfirming}
      />
      <CommandBar
        className={classNames.root}
        {...commandBarProps}
        styles={subComponentStyles?.commandBar}
      />
    </>
  );
};
