import {
  DirectionalHint,
  Icon,
  ITheme,
  Stack,
  TeachingBubble,
  Text,
} from "@fluentui/react";
import { useEvent } from "../../components/useEvent";
import * as React from "react";
import {
  INotificationItem,
  notificationColor,
  notificationIcon,
} from "../../models/INotificationItem";
import { INotificationManager } from "./NotificationManager";

interface INotificationToastProps {
  theme?: ITheme;
}

export const NotificationToast: React.FunctionComponent<
  INotificationToastProps
> = (props) => {
  const [notification, setNotification] = React.useState<
    INotificationItem | undefined
  >(undefined);

  useEvent("onNotification", (e?: INotificationItem) => {
    setNotification(e ?? undefined);
    e && window.di.use<INotificationManager>("notifications").add(e);
    e &&
      setTimeout(() => {
        notification !== undefined && setNotification(undefined);
      }, 15000);
  });

  const bubble =
    notification === undefined
      ? undefined
      : {
        target: `#notifications-icon`,
        theme: props.theme,
        hasSmallHeadline: true,
        headline: notification.title,
        onDismiss: () =>
          notification !== undefined && setNotification(undefined),
        styles: {
          root: {
            marginTop: "15px",
          },
          bodyContent: {
            backgroundColor: (props.theme?.semanticColors as any)["controlBackground"],
          },
          headline: {
            color: props.theme?.semanticColors.bodyText,
          },
          closeButton: {
            color: props.theme?.semanticColors.bodyText,
            ":hover": {
              color: props.theme?.semanticColors.bodyText,
            },
          },
        },
        calloutProps: {
          directionalHint: DirectionalHint.bottomCenter,
          isBeakVisible: false,
        },
        hasCloseButton: true,
        closeButtonAriaLabel: "Close",
        children: (
          <Stack
            horizontal
            tokens={{ childrenGap: 20 }}
            verticalAlign="center"
          >
            <Icon
              styles={{
                root: {
                  fontSize: "26px",
                  marginTop: "4px",
                  color: notificationColor(notification, props.theme),
                },
              }}
              iconName={notificationIcon(notification)}
            />
            {typeof notification.description === "string" ? <Text>{notification.description}</Text> : notification.description}
          </Stack>
        ),
      };

  return bubble ? <TeachingBubble {...bubble} /> : <></>;
};
