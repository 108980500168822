import { FormikChoiceGroup } from "../FormikChoiceGroup";
import { Field } from "formik";
import { registerWidget } from "./registry";
import { getClassName } from "./utils";

export const choiceFormField = (
  config: any,
  formikParams: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _formParams?: any,
) => {
  const extraProps = config ?? {};
  if (formikParams.handleChange != null)
    extraProps["onChange"] = formikParams.handleChange;
  return (
    <Field
      {...extraProps}
      name={config.name}
      type={config.type}
      label={config.label}
      defaultSelectedKey={config.defaultSelectedKey}
      className={getClassName(config)}
      id={config.name}
      options={config.options}
      component={FormikChoiceGroup}
    />
  );
};

registerWidget("choice", choiceFormField);
