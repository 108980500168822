import { Icon, Stack, Text, TooltipHost } from "@fluentui/react";
import { Field } from "formik";
import * as React from "react";
import { FormikTextField } from "../../../formik/FormikTextField";
import { FormikToggle } from "../../../formik/FormikToggle";
import { getPropertyBooleanFieldClassNames } from "./PropertyBooleanField.styles";
import { IPropertyBooleanFieldProps } from "./PropertyBooleanField.types";

export const PropertyBooleanFieldComponent: React.FunctionComponent<
  IPropertyBooleanFieldProps
> = (props) => {
  const { styles, description, readOnly, required } = props;
  const { classNames, subComponentStyles } = getPropertyBooleanFieldClassNames(
    styles,
    {
      ...props,
    },
  );
  return (
    <Field
      {...props}
      description={undefined}
      onRenderLabel={
        description === undefined || description === ""
          ? undefined
          : () => (
            <Stack
              styles={subComponentStyles?.label}
              horizontal
              tokens={{ childrenGap: 5 }}
            >
              <Text>{props.label}</Text>
              <TooltipHost content={description}>
                <Icon
                  iconName="Info"
                  styles={{
                    root: {
                      fontSize: "12px",
                      cursor: "pointer",
                    },
                  }}
                />
              </TooltipHost>
            </Stack>
          )
      }
      required={readOnly === undefined || readOnly ? false : required}
      inlineLabel={readOnly === undefined || readOnly ? true : undefined}
      classNames={classNames.root}
      styles={subComponentStyles?.toggle}
      component={
        readOnly === undefined || readOnly ? FormikTextField : FormikToggle
      }
      role="checkbox"
    />
  );

  // inlineLabel
  // onText=" "
  // offText=" "
};
