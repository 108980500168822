import {
  ITheme,
  mergeStyles,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import { useFormikContext } from "formik";
import * as React from "react";

interface ISuccessMessageProps {
  theme?: ITheme;
  style?: any;
  text?: string;
  children?: any;
}

export const SuccessMessage: React.FunctionComponent<ISuccessMessageProps> = ({
  theme,
  style,
  text = "Done!",
  children,
}) => {
  const containerClassName = mergeStyles(style ?? { margin: "15px 25px" });
  const form = useFormikContext<any>();
  return (
    <>
      {!(form.status?.success ?? false) ? (
        <></>
      ) : (
        <div className={containerClassName}>
          <MessageBar theme={theme} messageBarType={MessageBarType.success}>
            <>
              {text}
              {children}
            </>
          </MessageBar>
        </div>
      )}
    </>
  );
};
