import i18next from "i18next";
import {
  Log,
  User,
  UserManager,
} from "oidc-react";
import { UserManagerSettings, UserLoadedCallback } from "oidc-client-ts";
import { OidcSettings } from "./OidcSettings";

const defaultCallback: UserLoadedCallback = (user) => {
  (window as any).user = user;
};

export class AuthService {
  public userManager: UserManager;

  public static getDefaultSettings(): UserManagerSettings {
    return OidcSettings;
  }

  constructor(
    settings?: UserManagerSettings,
    callback?: UserLoadedCallback,
  ) {
    this.userManager = new UserManager(settings ?? OidcSettings);
    this.userManager.events.addUserLoaded(callback ?? defaultCallback);
    Log.setLogger(console);
    Log.setLevel(Log.INFO);
  }

  public async cleanUp(): Promise<void> {
    return await this.userManager.clearStaleState();
  }

  public async getUser(): Promise<User | null> {
    const x = await this.userManager.getUser();
    if (x === null) {
      return null;
    }
    if (x.profile.tid !== undefined) {
      localStorage.setItem("tid", x.profile.tid as string);
    }

    const mkt = localStorage.getItem("mkt");
    if (
      x.profile.locale !== undefined &&
      (mkt === null || mkt !== x.profile.locale)
    ) {
      const override = localStorage.getItem("mkt_override");
      if (override == null) {
        localStorage.setItem("mkt", x.profile.locale);
        await i18next.changeLanguage(x.profile.locale);
      } else if (i18next.language !== override) {
        localStorage.setItem("mkt", override);
        await i18next.changeLanguage(override);
      }
    }

    return x;
  }

  public async login(): Promise<void> {
    return await this.userManager.signinRedirect();
  }

  public async renewToken(): Promise<User> {
    try {
      const user = await this.userManager.signinSilent();
      if (user == null) throw new Error("signin_failed");
      return user;
    } catch {
      await this.userManager.signinRedirect();
      return {} as any;
    }
  }

  public async logout(): Promise<void> {
    return await this.userManager.signoutRedirect();
  }
}

export const authService = new AuthService({
  ...OidcSettings,
  redirect_uri: `${location.origin}/signin-callback.html`,
  response_type: "code",
  includeIdTokenInSilentRenew: true,
  automaticSilentRenew: true,
  scope: "openid profile email Directory.ReadWrite.All",
});
