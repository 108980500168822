import { styled } from "@fluentui/react/lib/Utilities";
import { IOrganizationsProps } from "./Organizations.types";
import {
  getStyles,
  IOrganizationsStyleProps,
  IOrganizationsStyles,
} from "./Organizations.styles";
import { OrganizationsComponent } from "./Organizations.base";

/**
 * Organizations
 */
export const Organizations = styled<
  IOrganizationsProps,
  IOrganizationsStyleProps,
  IOrganizationsStyles
>(OrganizationsComponent, getStyles);
