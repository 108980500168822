import {
  IButtonStyles,
  IIconStyles,
  IStackStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
  ITextFieldStyles,
} from "@fluentui/react";
import {
  ComponentStylesLoader,
  IComponentBaseClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
} from "../../../base";

export interface IPropertyNumberFieldSubComponentStyles {
  button?: IButtonStyles;
  field?: Partial<ITextFieldStyles>;
  label?: Partial<IStackStyles>;
  suffix?: IIconStyles;
}

export interface IPropertyNumberFieldTokens {
  ver?: string;
}

export interface IPropertyNumberFieldStyles
  extends IComponentBaseStyles<IPropertyNumberFieldSubComponentStyles> { }

export interface IPropertyNumberFieldStyleProps
  extends IComponentBaseStyleProps<IPropertyNumberFieldTokens> { }

export const getPropertyNumberFieldClassNames = (
  styles?: IStyleFunctionOrObject<
    IPropertyNumberFieldStyleProps,
    IPropertyNumberFieldStyles
  >,
  props?: IPropertyNumberFieldStyleProps,
): IComponentBaseClassNames<
  IPropertyNumberFieldStyleProps,
  IPropertyNumberFieldStyles,
  IPropertyNumberFieldSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IPropertyNumberFieldStyleProps,
    IPropertyNumberFieldStyles,
    IPropertyNumberFieldSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IPropertyNumberFieldStyleProps,
  IPropertyNumberFieldStyles
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (_props: IPropertyNumberFieldStyleProps): IPropertyNumberFieldStyles => {
  return {
    root: ["app-PropertyNumberField", {}],
    subComponentStyles: {
      label: {
        root: {
          width: "225px",
          padding: "5px 0px 12px 12px",
        },
      },
      suffix: {
        root: {
          fontSize: "16px",
          marginTop: "2px",
          cursor: "pointer",
        },
      },
    },
  };
};
