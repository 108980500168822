import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../../components/base";
import { IUserHeaderStyles } from "./Controls/UserHeader";
import { IUserNavigationStyles } from "./Controls/UserNavigation";
import { IUserOverviewStyles } from "./Controls/UserOverview";
import { IUserProfileStyles } from "./Controls/UserProfile";

export interface IUserBladeSubComponentStyles {
  button?: IButtonStyles;
  overview?: IUserOverviewStyles;
  header?: IUserHeaderStyles;
  navigation?: IUserNavigationStyles;
  profile?: IUserProfileStyles;
}

export interface IUserBladeTokens {
  ver?: string;
}

export interface IUserBladeStyles
  extends IComponentBaseStyles<IUserBladeSubComponentStyles> { }

export interface IUserBladeStyleProps
  extends IComponentBaseStyleProps<IUserBladeTokens> { }

export const getUserBladeClassNames = (
  styles?: IStyleFunctionOrObject<IUserBladeStyleProps, IUserBladeStyles>,
  props?: IUserBladeStyleProps,
): IComponentBaseClassNames<
  IUserBladeStyleProps,
  IUserBladeStyles,
  IUserBladeSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IUserBladeStyleProps,
    IUserBladeStyles,
    IUserBladeSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IUserBladeStyleProps,
  IUserBladeStyles
// eslint-disable-next-line @typescript-eslint/no-unused-vars
> = (_props: IUserBladeStyleProps): IUserBladeStyles => {
  return {
    root: ["app-UserBlade", {}],
  };
};
