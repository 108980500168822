import { Form } from "../../../../components/formik/schema";
import * as React from "react";
import { getUserInvitationFormClassNames } from "./UserInvitationForm.styles";
import { IUserInvitationFormProps } from "./UserInvitationForm.types";
import { useUserInvitationForm } from "./UserInvitationForm.use";

export const UserInvitationFormComponent: React.FunctionComponent<
  IUserInvitationFormProps
> = (props) => {
  const { styles } = props;
  const { classNames /* , subComponentStyles */ } =
    getUserInvitationFormClassNames(styles, {
      ...props,
    });

  const form = useUserInvitationForm(props);
  return (
    <>
      <div className={classNames.root}></div>
      <Form {...props} {...form} />
    </>
  );
};
