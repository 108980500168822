import { ITheme } from "@fluentui/react";
import { AuthService } from "./components/AuthService";
import { User } from "oidc-react";
import * as React from "react";
import { Navigate, Route, Routes } from "react-router";
import { useAppLayout } from "./AppLayout.use";
import { Error404 } from "./Error404";
import { Landing } from "./modules/Landing/Landing";
import { Notifications } from "./modules/Notifications/Notifications";
import { Settings } from "./modules/Settings";
import { MainLayout } from "./components/Layouts";
import { Users } from "./modules/Users/Users";
import { Client } from "@microsoft/microsoft-graph-client";
import { Invitations } from "./modules/Invitations/Invitations";
import { Departments } from "./modules/Departments/Departments";
import { Group } from "./modules/Groups/Groups";
// import { Organizations } from "./modules/Organizations";
import { Applications } from "./modules/Applications/Applications";
export interface IAppLayoutProps {
  authService: AuthService;
  user: User;
  theme: ITheme;
  api: Client;
}

export const AppLayout: React.FunctionComponent<IAppLayoutProps> = (props) => {
  const { theme } = props;
  const { headerProps, isNotficationOpen, onDismissNotifications } =
    useAppLayout(props);
  return (
    <>
      <Notifications
        {...props}
        isOpen={isNotficationOpen}
        onDismiss={onDismissNotifications}
      />
      <div id="main-layout">
        <MainLayout theme={theme} headerProps={headerProps}>
          <Routes>
            <Route
              path="/users/*"
              element={<Users {...props} styles={undefined} />}
            />
            <Route
              path="/invitations/*"
              element={<Invitations {...props} styles={undefined} />}
            />
            <Route
              path="/departments/*"
              element={<Departments {...props} styles={undefined} />}
            />
            <Route
              path="/applications/*"
              element={<Applications {...props}  />}
            />
             <Route
              path="/groups/*"
              element={<Group {...props}  />}
            />
            <Route
              path="/health/*"
              element={<div></div>}
            />
            <Route
              path="/settings/*"
              element={<Settings {...props} styles={undefined} />}
            />
            <Route path="/home" element={<Navigate to="/" />} />
            <Route path="/index.html" element={<Navigate to="/" />} />
            <Route path="/404" element={<Error404 />} />
            <Route path="/" element={<Landing {...props} />} />
            <Route path="/*" element={<Navigate to="/404" />} />
          </Routes>
        </MainLayout>
      </div>
    </>
  );
};
