import {
  IIconProps,
  IStyle,
  ITheme,
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Sticky,
  StickyPositionType
} from "@fluentui/react";
import * as React from "react";
import { useMediaQueries } from "../MediaQueries";
import { Theming } from "../Theming";
import { Header, IHeaderProps } from "./Header";
import { MenuLayout } from "./MenuLayout";

interface IFooterProps {
  children?: any;
}

export interface IMainLayoutProps {
  theme?: ITheme;
  initialSelectedKey?: string;
  headerProps?: IHeaderProps;
  footerProps?: IFooterProps;
  accountIconProps?: IIconProps;
  globalMenuOnly?: boolean;
  initialMenuCollapsed?: boolean;
  navStyle?: IStyle;
  children?: any;
}

export const MainLayout: React.FunctionComponent<IMainLayoutProps> = ({
  children,
  theme,
  initialSelectedKey,
  globalMenuOnly,
  initialMenuCollapsed,
  headerProps,
  footerProps,
  navStyle,
}) => {
  const { mobile } = useMediaQueries();
  const stickyHeaderProps = {
    stickyPosition: StickyPositionType.Header,
    children: (
      <div>
        {headerProps !== undefined && (
          <Header
            theme={theme}
            {...headerProps}
            globalMenuOnly={globalMenuOnly}
          />
        )}
      </div>
    ),
  };
  const stickyFooterProps =
    footerProps === undefined
      ? undefined
      : {
          stickyPosition: StickyPositionType.Footer,
          children: <div>{footerProps?.children}</div>,
        };
  return (
    <>
      <ScrollablePane
        scrollbarVisibility={ScrollbarVisibility.auto}
        initialScrollPosition={0}
        styles={{
          root: {
            backgroundColor: theme?.semanticColors.bodyBackground,
          },
          contentContainer: {
            overflowX: "hidden",
          },
        }}
      >
        <Sticky {...stickyHeaderProps} />
        {!mobile &&
        !(globalMenuOnly ?? false) &&
        headerProps?.appBarProps?.appNav != null ? (
          <MenuLayout
            navStyle={navStyle}
            initialCollapsed={initialMenuCollapsed}
            initialSelectedKey={initialSelectedKey}
            nav={
              headerProps.appBarProps.areaNav ?? headerProps.appBarProps.appNav
            }
            theme={theme}
            onNavigate={headerProps.appBarProps.onNavigate}
          >
            {children}
          </MenuLayout>
        ) : (
          <Stack
            theme={theme}
            grow
            horizontal
            verticalAlign="start"
            styles={{
              root: {
                backgroundColor: (theme ?? Theming.getTheme()).semanticColors
                  .bodyBackground,
                position: "relative",
              },
            }}
          >
            {children}
          </Stack>
        )}
        {stickyFooterProps && <Sticky {...stickyFooterProps} />}
      </ScrollablePane>
    </>
  );
};
