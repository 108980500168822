import { CommandBar } from "@fluentui/react";
import * as React from "react";
import { getUserExportClassNames } from "./UserExport.styles";
import { IUserExportProps } from "./UserExport.types";
import { useUserExport } from "./UserExport.use";

export const UserExportComponent: React.FunctionComponent<IUserExportProps> = (
  props,
) => {
  const { styles } = props;
  const { classNames /* , subComponentStyles */ } = getUserExportClassNames(
    styles,
    {
      ...props,
    },
  );
  const { commandBarProps, value } = useUserExport(props);
  return (
    <div className={classNames.root}>
      <CommandBar {...commandBarProps} />
      {value !== undefined && <pre>{value.content}</pre>}
    </div>
  );
};
