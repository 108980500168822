import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../../components/base";

export interface INotificationItemSubComponentStyles {
  button?: IButtonStyles;
}

export interface INotificationItemTokens {
  ver?: string;
}

export interface INotificationItemStyles
  extends IComponentBaseStyles<INotificationItemSubComponentStyles> { }

export interface INotificationItemStyleProps
  extends IComponentBaseStyleProps<INotificationItemTokens> { }

export const getNotificationItemClassNames = (
  styles?: IStyleFunctionOrObject<
    INotificationItemStyleProps,
    INotificationItemStyles
  >,
  props?: INotificationItemStyleProps,
): IComponentBaseClassNames<
  INotificationItemStyleProps,
  INotificationItemStyles,
  INotificationItemSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    INotificationItemStyleProps,
    INotificationItemStyles,
    INotificationItemSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  INotificationItemStyleProps,
  INotificationItemStyles
> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: INotificationItemStyleProps,
  ): INotificationItemStyles => {
    return {
      root: ["app-NotificationItem", {}],
    };
  };
