import { ICommandBarProps } from "@fluentui/react";
import { FormikContextType, FormikHelpers } from "formik";
import React from "react";
import { IFormProps } from "../../../../../../components/formik/schema/IFormProps";
import { FORMIK_TWOCOLS_RENDERER } from "../../../../../../components/formik/schema/renderer";
import { MediaQueries } from "../../../../../../components/MediaQueries";
import { formReadOnly, mapObjects } from "../../../../../../components/utils";
import { IUser } from "../../../../../../models/IUser";
import { useUserPofileLayoutHeader } from "./LayoutHeader.use";
import { useUserErrors } from "./UserProfile.errors";
import { IUserProfileProps } from "./UserProfile.types";

const usingFields = [
  "email",
  "userPrincipalName",
  "givenName",
  "surname",
  "jobTitle",
  "department",
  "companyName",
  "accountEnabled",
  "phoneNumber",
  "zoneinfo",
  "theme",
];

const textFieldStyles = {
  root: {
    margin: "8px 0px",
  },
  suffix: {
    backgroundColor: "transparent",
  },
  subComponentStyles: {
    label: {
      root: {
        minWidth: "117px",
        [MediaQueries.desktop]: {
          minWidth: "177px",
        },
      },
    },
  },
};

export const useUserProfile = (props: IUserProfileProps) => {
  const { selected, api, onUserChanged } = props;

  const [editing, setEditing] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const commandBarProps = (form: FormikContextType<any>): ICommandBarProps => ({
    items: [
      {
        key: editing ? "save" : "edit",
        visible: true,
        disabled:
          (editing && Object.keys(form.touched ?? {}).length === 0) ||
          (form?.isValidating ?? false) ||
          (form?.isSubmitting ?? false),
        iconProps: {
          iconName: editing ? "Save" : "EditContact",
        },
        text: editing ? "Save" : "Edit",
        onClick: () => {
          const value = editing;
          value && form?.submitForm();
          !value && setEditing(true);
          !value && form.setStatus({ readOnly: false });
        },
      },
      {
        key: "cancel",
        visible: editing,
        disabled:
          (form?.isValidating ?? false) || (form?.isSubmitting ?? false),
        iconProps: {
          iconName: "Cancel",
        },
        text: "Cancel",
        onClick: () => {
          form.resetForm();
          form.setStatus({ readOnly: true });
          setEditing(false);
        },
      },
    ].filter((x) => x.visible),
  });

  const formParams = { readOnly: !editing };

  const onSubmit = (values: any, formikHelpers: FormikHelpers<any>) => {
    const payload: IUser = {};
    payload.userPrincipalName = selected.userPrincipalName;
    payload.displayName = `${values.givenName} ${values.surname}`;
    payload.givenName = values.givenName;
    payload.surname = values.surname;
    payload.department = values.department;
    payload.companyName = values.companyName;
    payload.jobTitle = values.jobTitle;
    payload.locale = values.locale;
    payload.phoneNumber = values.phoneNumber;
    payload.accountEnabled = values.accountEnabled;
    mapObjects(payload, values, usingFields);
    formikHelpers.setFieldValue("displayName", payload.displayName);

    selected.id !== undefined &&
      api.api(`/users/${selected.id}`)
        .patch(payload)
        .then(() => {
          setEditing(false);
          formikHelpers.setSubmitting(false);
          formikHelpers.setStatus({ readOnly: true, success: true });
          values.displayName = `${values.givenName} ${values.surname}`;
          mapObjects(selected, values, usingFields);
          onUserChanged(selected);
        })
        .catch((x) => {
          useUserErrors(x, formikHelpers);
          formikHelpers.setSubmitting(false);
        });
  };

  const formProps: IFormProps = {
    ...formParams,
    initialValues: selected,
    onSubmit: onSubmit,
    initialStatus: {
      readOnly: !editing,
    },
    schema: {
      form: {
        renderer: FORMIK_TWOCOLS_RENDERER,
        onRenderLayoutHeader: useUserPofileLayoutHeader(props, commandBarProps),
      },
      fields: [
        {
          name: "displayName",
          type: "text",
          label: "Display Name",
          styles: textFieldStyles,
          ...formReadOnly(true),
          required: true,
        },
        {
          name: "email",
          type: "text",
          label: "Courriel",
          ...formReadOnly(true),
          styles: textFieldStyles,
        },
        {
          name: "userPrincipalName",
          type: "text",
          label: "User Principal Name",
          ...formReadOnly(true),
          styles: textFieldStyles,
        },
        {
          name: "givenName",
          type: "text",
          label: "First name",
          ...formReadOnly(!editing),
          styles: textFieldStyles,
        },
        {
          name: "surname",
          type: "text",
          label: "Last name",
          ...formReadOnly(!editing),
          styles: textFieldStyles,
        },
        {
          name: "jobTitle",
          type: "text",
          label: "Job Title",
          ...formReadOnly(!editing),
          styles: textFieldStyles,
        },
        {
          name: "department",
          type: "text",
          label: "Department",
          ...formReadOnly(!editing),
          styles: textFieldStyles,
        },
        {
          name: "companyName",
          type: "text",
          label: "Organization",
          ...formReadOnly(!editing),
          styles: textFieldStyles,
        },
        {
          name: "userType",
          type: "text",
          label: "User Type",
          ...formReadOnly(true),
          styles: textFieldStyles,
        },
        {
          name: "phoneNumber",
          type: "tel",
          label: "Phone Number",
          ...formReadOnly(!editing),
          styles: textFieldStyles,
        },
        {
          name: "accountEnabled",
          type: "boolean",
          label: "Allow sign in",
          ...formReadOnly(!editing),
          styles: textFieldStyles,
        },
        {
          name: "createdDateTime",
          type: "date",
          label: "Created On",
          ...formReadOnly(true),
          styles: textFieldStyles,
        },
        {
          name: "modifiedOn",
          type: "date",
          label: "Modified On",
          ...formReadOnly(true),
          styles: textFieldStyles,
        },
      ],
    },
  };

  return {
    ...formProps,
  };
};
