import { useMediaQuery } from "react-responsive";

const x: number = 767;
const y: number = 1224;
const z: number = 1280;

export const MediaQueries = {
  small:
    "@media only screen and (min-width: 360px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 3.0)",
  mobile: `@media only screen and (max-width: ${x}px)`,
  landscape: `@media only screen and (max-width: ${x}px) and (orientation: landscape)`,
  desktop: `@media only screen and (min-width: ${y}px)`,
  tablet: `@media only screen and (max-width: ${y}px)`,
  notebook: `@media only screen and (max-width: ${z}px)`,
};

export const useMediaQueries = () => ({
  mobile: useMediaQuery({ query: `(max-width: ${x}px)` }),
  tablet: useMediaQuery({ query: `(max-width: ${y}px)` }),
  desktop: useMediaQuery({ query: `(min-width: ${y}px)` }),
  landscape: useMediaQuery({
    query: `(max-width: ${x}px) and (orientation: landscape)`,
  }),
  notebook: useMediaQuery({
    query: `(max-width: ${z}px)`,
  }),
  small: useMediaQuery({
    query: `(min-width: 360px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 3.0)`,
  }),
});
