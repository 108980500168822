import { styled } from "@fluentui/react";
import { IPropertyEnumFieldProps } from "./PropertyEnumField.types";
import {
  getStyles,
  IPropertyEnumFieldStyleProps,
  IPropertyEnumFieldStyles,
} from "./PropertyEnumField.styles";
import { PropertyEnumFieldComponent } from "./PropertyEnumField.base";

/**
 * PropertyEnumField
 */
export const PropertyEnumField = styled<
  IPropertyEnumFieldProps,
  IPropertyEnumFieldStyleProps,
  IPropertyEnumFieldStyles
>(PropertyEnumFieldComponent, getStyles);
