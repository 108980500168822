import {
  IStyleFunctionOrObject,
  ISpinnerStyleProps,
  ISpinnerStyles,
  IIconStyleProps,
  IIconStyles,
} from "@fluentui/react";

export interface ISplashScreenStyles {
  spinner?: IStyleFunctionOrObject<ISpinnerStyleProps, ISpinnerStyles>;
  icon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
  container?: React.CSSProperties;
  logo?: React.CSSProperties;
  wait?: React.CSSProperties;
  footer?: React.CSSProperties;
}

export const SplashScreenStyles: ISplashScreenStyles = {
  spinner: {
    circle: {
      left: "calc(50vw - 40px)",
      top: "calc(50vh + 150px)",
      position: "fixed",
      width: "90px",
      height: "90px",
    },
  },
  icon: {
    root: { fontWeight: "400!important", fontSize: "86px" },
  },
  container: { width: "100%", textAlign: "center" },
  logo: {
    position: "fixed",
    top: "calc(50vh - 200px)",
    left: "calc(50vw - 200px)",
    width: "400px",
  },
  wait: {
    position: "fixed",
    height: "80px",
    width: "180px",
    left: "calc(50vw - 90px)",
    top: "calc(50vh)",
  },
  footer: {
    position: "fixed",
    bottom: "36px",
    left: "calc(50vw - 50px)",
    width: "300px",
    height: "90px",
  },
};
