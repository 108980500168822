import { styled } from "@fluentui/react";
import { IUserInvitationFormProps } from "./UserInvitationForm.types";
import {
  getStyles,
  IUserInvitationFormStyleProps,
  IUserInvitationFormStyles,
} from "./UserInvitationForm.styles";
import { UserInvitationFormComponent } from "./UserInvitationForm.base";

/**
 * UserInvitationForm
 */
export const UserInvitationForm = styled<
  IUserInvitationFormProps,
  IUserInvitationFormStyleProps,
  IUserInvitationFormStyles
>(UserInvitationFormComponent, getStyles);
