import { IBreadcrumbProps, INavLinkGroup } from "@fluentui/react";
import { Client } from "@microsoft/microsoft-graph-client";
import { User as CurrentUser } from "oidc-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { IBladePanelProps } from "./BladePanel/BladePanel.types";

export interface IBladeProps<T> {
  api: Client;
  user: CurrentUser;
  name: string;
  route: string;
  navigation?: (item?: T) => INavLinkGroup[];
  title: (item?: T) => string;
  get: (id: string) => Promise<T | undefined>;
}

const idOf = (item: any) => item?.id ?? "0";

export function useBlade<T>(props: IBladeProps<T>) {
  const { get, navigation, name, title, route } = props;
  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = React.useState<T | undefined>(undefined);
  React.startTransition(() => {
    async function getItem() {
      id !== undefined && item === undefined && setItem(await get(id));
    }
    id !== undefined && item === undefined && getItem();
  });

  const { t } = useTranslation();

  const onDismiss = () => navigate(route);
  const titleValue = item === undefined ? "" : title(item);
  const breadcrumbProps: IBreadcrumbProps = {
    items:
      item === undefined
        ? []
        : [
          {
            key: "home",
            text: t("ApplicationMenu.HomeTitle", "Home") ?? "Home",
            href: "/",
            onClick: (ev?: any) => {
              ev?.preventDefault();
              ev?.stopPropagation();
              navigate("/");
            },
          },
          {
            key: route,
            text: name,
            href: route,
            onClick: (ev?: any) => {
              ev?.preventDefault();
              ev?.stopPropagation();
              navigate(route);
            },
          },
          {
            key: idOf(item),
            text: titleValue,
          },
        ],
  };

  const onNavigate = (url: string) => {
    navigate(url);
  };

  const nav =
    item == undefined || navigation === undefined ? undefined : navigation();

  const bladePanelProps: IBladePanelProps = {
    title: titleValue,
    canPin: false,
    onDismiss,
    breadcrumbProps,
  };

  return {
    bladePanelProps,
    onNavigate,
    navigation: nav,
    breadcrumbProps,
    onDismiss,
    item,
    setItem,
  };
}
