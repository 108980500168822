import { Text, Icon, Stack, TooltipHost } from "@fluentui/react";
import { copyToClipboard } from "../../../copyToClipboard";
import { FormikDropdown } from "../../../formik/FormikDropdown";
import { Field } from "formik";
import * as React from "react";
import { getPropertyEnumFieldClassNames } from "./PropertyEnumField.styles";
import { IPropertyEnumFieldProps } from "./PropertyEnumField.types";

export const PropertyEnumFieldComponent: React.FunctionComponent<
  IPropertyEnumFieldProps
> = (props) => {
  const {
    styles,
    name,
    description,
    property,
    readOnly,
    required,
    type,
    value,
  } = props;
  const { classNames, subComponentStyles } = getPropertyEnumFieldClassNames(
    styles,
    {
      ...props,
    },
  );

  const options =
    property.enum === undefined
      ? []
      : (property.enum as any[]).map((x) => {
        return {
          key: x,
          text: x,
          name: x,
        };
      });

  const copyTo = (
    <>
      {type !== "boolean" && value !== undefined && value != null && (
        <Icon
          onClick={() => copyToClipboard(options[value].text)}
          iconName="Copy"
          title={"Copy to clipboard"}
          styles={subComponentStyles?.suffix}
        />
      )}
    </>
  );

  const onRenderInput =
    type === "boolean" && (readOnly === undefined || readOnly)
      ? (props: any, defaultRender: any) =>
        defaultRender?.({
          ...props,
          value: props?.value === undefined ? "" : props.value ? "Yes" : "No",
        }) as any
      : readOnly === undefined || readOnly
        ? (props: any, defaultRender: any) => {
          defaultRender?.({
            ...props,
            value: props?.value === undefined ? "" : props.value ? "Yes" : "No",
          }) as any;
          return <div>{props?.value}</div>;
        }
        : undefined;

  return (
    <Field
      {...props}
      name={name}
      required={readOnly === undefined || readOnly ? false : required}
      data-form-type="other"
      autoComplete="off"
      description={undefined}
      selectedKey={value}
      onRenderLabel={
        description === undefined || description === ""
          ? undefined
          : () => (
            <Stack
              styles={subComponentStyles?.label}
              horizontal
              tokens={{ childrenGap: 5 }}
            >
              <Text>
                {props.label}-{type}
              </Text>
              <TooltipHost content={description}>
                <Icon
                  iconName="Info"
                  styles={{
                    root: {
                      fontSize: "12px",
                      cursor: "pointer",
                    },
                  }}
                />
              </TooltipHost>
            </Stack>
          )
      }
      options={options}
      component={FormikDropdown}
      className={classNames.root}
      styles={subComponentStyles?.field}
      borderless={readOnly === undefined || readOnly}
      readOnly={readOnly === undefined ? true : readOnly}
      underlined={readOnly === undefined ? true : readOnly}
      onRenderSuffix={() => copyTo}
      prefix={readOnly === undefined || readOnly ? ":" : undefined}
      onRenderInput={onRenderInput}
    />
  );
};
