import { Form } from "../../../../../../components/formik/schema/Form";
import * as React from "react";
import { getUserProfileClassNames } from "./UserProfile.styles";
import { IUserProfileProps } from "./UserProfile.types";
import { useUserProfile } from "./UserProfile.use";

export const UserProfileComponent: React.FunctionComponent<
  IUserProfileProps
> = (props) => {
  const { styles } = props;
  const { classNames /* , subComponentStyles */ } = getUserProfileClassNames(
    styles,
    {
      ...props,
    },
  );

  const formProps = useUserProfile(props);

  return (
    <div className={classNames.root}>
      <Form {...formProps} />
    </div>
  );
};
