import { Icon, Link, Panel, Stack, StackItem, Text } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router";
import { NotificationItem } from "./Controls/NotificationItem";
import { INotificationManager } from "./NotificationManager";
import { getNotificationsClassNames } from "./Notifications.styles";
import { INotificationsProps } from "./Notifications.types";

export const NotificationsComponent: React.FunctionComponent<
  INotificationsProps
> = (props) => {
  const { styles, isOpen, onDismiss } = props;
  const { classNames /* , subComponentStyles */ } = getNotificationsClassNames(
    styles,
    {
      ...props,
    },
  );
  const navigate = useNavigate();
  const [counter, setCounter] = React.useState(0);

  const notifications = isOpen
    ? window.di.use<INotificationManager>("notifications").notifications
    : [];

  const onClear = () => {
    window.di.use<INotificationManager>("notifications").clear();
    setCounter(counter + 1);
  };

  const onNavigateActivities = () => navigate("/activities");

  return (
    <Panel
      isOpen={isOpen}
      onDismiss={onDismiss}
      onLightDismissClick={onDismiss}
      headerText={"Notifications"}
      isLightDismiss={true}
      theme={props.theme}
      overlayProps={{
        isDarkThemed: props.theme?.isInverted,
      }}
      className={classNames.root}
      styles={{
        main: {
          marginTop: "34px",
        },
      }}
    >
      {notifications.length === 0 && (
        <Stack
          grow
          verticalFill
          tokens={{ childrenGap: 10 }}
          horizontalAlign="center"
        >
          <Icon
            iconName="Ringer"
            styles={{
              root: {
                color: "#fff",
                marginTop: "35px",
                fontSize: "100px",
              },
            }}
          />
          <Text variant="large">No notifications for this session</Text>
          <Link onClick={onNavigateActivities}>
            {"More events in the activity log"}
          </Link>
        </Stack>
      )}
      {notifications.length > 0 && (
        <Stack
          grow
          horizontal
          verticalFill
          styles={{
            root: {
              marginTop: "25px",
            },
          }}
        >
          <StackItem grow>
            <Link onClick={onNavigateActivities}>
              <Stack
                horizontal
                tokens={{ childrenGap: 5 }}
                verticalAlign="center"
              >
                {"More events in the activity log"}
                <Icon
                  iconName="NavigateForward"
                  styles={{
                    root: {
                      paddingTop: "4px",
                      margin: "2px 7px",
                      fontSize: "12px",
                    },
                  }}
                />
              </Stack>
            </Link>
          </StackItem>
          <StackItem>
            <Link
              onClick={onClear}
              styles={{
                root: {
                  marginTop: "2px",
                },
              }}
            >
              {"Clear all"}
            </Link>
          </StackItem>
        </Stack>
      )}
      {notifications.map((x, i) => (
        <NotificationItem key={i} item={x} />
      ))}
    </Panel>
  );
};
