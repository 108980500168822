import { IconButton, ITextFieldProps, TextField } from "@fluentui/react";
import { FieldProps } from "formik";
import React from "react";
import {
  createFakeEvent,
  getErrorMessage,
  handleProps,
  isReadOnly,
  Omit,
} from "./utils";

export interface IFileInstance {
  name: string;
  size: number;
  lastModified: Date;
  type: string;
  buffer: string | ArrayBuffer | null;
}

export function mapFieldToFileField<
  V extends string = string,
  FormValues = any,
>({
  form,
  field,
  meta,
}: FieldProps<V, FormValues>): Pick<
  ITextFieldProps,
  "value" | "name" | "onChange" | "readOnly" | "errorMessage" | "form"
> {
  return {
    ...field,
    errorMessage: getErrorMessage({ form, field, meta }),
  };
}

export type FormikFileFieldProps<V extends string, FormValues = any> = Omit<
  ITextFieldProps,
  "value" | "name" | "onChange" | "form"
> &
  FieldProps<V, FormValues>;

const getBase64 = (
  id: number,
  file: File,
  cb: (id: number, item: string | ArrayBuffer | null) => void,
): void => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(id, reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

export function FormikFileField<V extends string, FormValues = any>({
  field,
  form,
  meta,
  ...props
}: FormikFileFieldProps<V, FormValues>) {
  const { errorMessage, ...fieldProps } = mapFieldToFileField({
    field,
    form,
    meta,
  });

  const readOnly = isReadOnly(field, form, props);
  const fileUploadRef = React.useRef<HTMLInputElement | undefined>(undefined);
  const handleFileUpload = () => {
    const { files } = fileUploadRef.current as any;
    if (readOnly) {
      return;
    } else if (files.length === 0) {
      field.value !== undefined &&
        field.value != null &&
        form.setFieldValue(field.name, undefined);
    } else {
      const { name, size, lastModified, type } = files[0];
      getBase64(0, files[0], (_id, buffer) => {
        const instance: IFileInstance = {
          name,
          buffer,
          size,
          type,
          lastModified,
        };
        form.setFieldValue(field.name, instance);
      });
      field.onBlur(createFakeEvent(field));
    }
  };
  const value = field.value as any as IFileInstance;
  const iconButtonStyles = {
    root: {
      backgroundColor: "transparent",
    },
    rootHovered: {
      backgroundColor: "transparent",
      border: "0px",
    },
  };
  return (
    <>
      <input
        ref={(ref) => {
          if (ref != null) {
            fileUploadRef.current = ref;
          }
        }}
        style={{ display: "none" }}
        type="file"
        onChange={handleFileUpload}
      />
      <TextField
        {...handleProps(props)}
        {...fieldProps}
        readOnly
        type="text"
        value={value?.name}
        errorMessage={errorMessage}
        onRenderSuffix={() => (
          <IconButton
            iconProps={{
              iconName: "FileRequest",
            }}
            disabled={readOnly}
            styles={iconButtonStyles}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              fileUploadRef.current?.click();
            }}
          />
        )}
      />
    </>
  );
}
