import { styled } from "@fluentui/react";
import { IUserNavigationProps } from "./UserNavigation.types";
import {
  getStyles,
  IUserNavigationStyleProps,
  IUserNavigationStyles,
} from "./UserNavigation.styles";
import { UserNavigationComponent } from "./UserNavigation.base";

/**
 * UserNavigation
 */
export const UserNavigation = styled<
  IUserNavigationProps,
  IUserNavigationStyleProps,
  IUserNavigationStyles
>(UserNavigationComponent, getStyles);
