import { SearchBox } from "@fluentui/react";

export const SearchCommandItem = (onSearch: (newValue?: string) => void) => () => <SearchBox
    onClear={() => onSearch(undefined)}
    onSearch={onSearch} placeholder="Rechercher..."
    styles={{
        root: {
            marginRight: "30px"
        }
    }}
/>