import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../components/base";

export interface IUserPersonaSubComponentStyles {
  button?: IButtonStyles;
}

export interface IUserPersonaTokens {
  ver?: string;
}

export interface IUserPersonaStyles
  extends IComponentBaseStyles<IUserPersonaSubComponentStyles> {}

export interface IUserPersonaStyleProps
  extends IComponentBaseStyleProps<IUserPersonaTokens> {}

export const getUserPersonaClassNames = (
  styles?: IStyleFunctionOrObject<IUserPersonaStyleProps, IUserPersonaStyles>,
  props?: IUserPersonaStyleProps,
): IComponentBaseClassNames<
  IUserPersonaStyleProps,
  IUserPersonaStyles,
  IUserPersonaSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IUserPersonaStyleProps,
    IUserPersonaStyles,
    IUserPersonaSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IUserPersonaStyleProps,
  IUserPersonaStyles
> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IUserPersonaStyleProps,
): IUserPersonaStyles => {
  return {
    root: [
      "app-UserPersona",
      {
        width: "100%",
      },
    ],
  };
};
