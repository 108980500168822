import { Text, Icon, Stack, TooltipHost } from "@fluentui/react";
import { copyToClipboard } from "../../../copyToClipboard";
import { FormikTextField } from "../../../formik/FormikTextField";
import { Field } from "formik";
import * as React from "react";
import { getPropertyTextFieldClassNames } from "./PropertyTextField.styles";
import { IPropertyTextFieldProps } from "./PropertyTextField.types";

export const PropertyTextFieldComponent: React.FunctionComponent<
  IPropertyTextFieldProps
> = (props) => {
  const {
    styles,
    underlined,
    name,
    description,
    readOnly,
    required,
    type,
    value,
  } = props;
  const { classNames, subComponentStyles } = getPropertyTextFieldClassNames(
    styles,
    {
      ...props,
    },
  );

  const copyTo = (
    <>
      {type !== "boolean" && value !== undefined && value != null && (
        <Icon
          onClick={() => copyToClipboard(value)}
          iconName="Copy"
          title={"Copy to clipboard"}
          styles={subComponentStyles?.suffix}
        />
      )}
    </>
  );

  const onRenderInput =
    type === "boolean" && (readOnly === undefined || readOnly)
      ? (props: any, defaultRender: any) =>
        defaultRender?.({
          ...props,
          value: props?.value === undefined ? "" : props.value ? "Yes" : "No",
        }) as any
      : readOnly === undefined || readOnly
        ? (props: any, defaultRender: any) => {
          defaultRender?.({
            ...props,
            value: props?.value === undefined ? "" : props.value ? "Yes" : "No",
          }) as any;
          return <div>{props?.value}</div>;
        }
        : undefined;

  return (
    <Field
      {...props}
      name={name}
      required={readOnly === undefined || readOnly ? false : required}
      data-form-type="other"
      autoComplete="off"
      description={undefined}
      onRenderLabel={
        description === undefined || description === ""
          ? undefined
          : () => (
            <Stack
              styles={subComponentStyles?.label}
              horizontal
              tokens={{ childrenGap: 5 }}
            >
              <Text>{props.label}</Text>
              <TooltipHost content={description}>
                <Icon
                  iconName="Info"
                  styles={{
                    root: {
                      fontSize: "12px",
                      cursor: "pointer",
                    },
                  }}
                />
              </TooltipHost>
            </Stack>
          )
      }
      component={FormikTextField}
      className={classNames.root}
      styles={subComponentStyles?.field}
      borderless={readOnly === undefined || readOnly}
      readOnly={readOnly === undefined ? true : readOnly}
      underlined={
        readOnly === undefined ? underlined ?? true : underlined ?? readOnly
      }
      onRenderSuffix={() => copyTo}
      prefix={
        readOnly === undefined || (underlined ?? readOnly) ? ":" : undefined
      }
      onRenderInput={onRenderInput}
    />
  );
};
