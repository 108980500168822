import {
  IButtonStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseClassNames,
  ComponentStylesLoader,
} from "../../../../../../components/base";

export interface IUserExportSubComponentStyles {
  button?: IButtonStyles;
}

export interface IUserExportTokens {
  ver?: string;
}

export interface IUserExportStyles
  extends IComponentBaseStyles<IUserExportSubComponentStyles> { }

export interface IUserExportStyleProps
  extends IComponentBaseStyleProps<IUserExportTokens> { }

export const getUserExportClassNames = (
  styles?: IStyleFunctionOrObject<IUserExportStyleProps, IUserExportStyles>,
  props?: IUserExportStyleProps,
): IComponentBaseClassNames<
  IUserExportStyleProps,
  IUserExportStyles,
  IUserExportSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IUserExportStyleProps,
    IUserExportStyles,
    IUserExportSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IUserExportStyleProps,
  IUserExportStyles
> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _props: IUserExportStyleProps,
  ): IUserExportStyles => {
    return {
      root: [
        "app-UserExport",
        {
          minHeight: "500px",
        },
      ],
    };
  };
