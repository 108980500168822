import { IGroupResourceProps } from "./GroupResource.types";
import { getGroupResourceClassNames } from "./GroupResource.styles";

export const useGroupResource = (
  props: IGroupResourceProps,
) => {
  console.log(props)
  const { styles,item,api,user,authService } = props;
  const { classNames /* , subComponentStyles */ } =
    getGroupResourceClassNames(styles, {
      ...props,
    });
  
  return {
    classNames,
    item,
    api,
    user,
    authService,
    
    
  };
};
