import {
  DefaultButton,
  Dialog,
  DialogFooter,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import { getGridViewConfirmDialogClassNames } from "./GridViewConfirmDialog.styles";
import { IGridViewConfirmDialogProps } from "./GridViewConfirmDialog.types";
import { useGridViewConfirmDialog } from "./GridViewConfirmDialog.use";

export const GridViewConfirmDialogComponent: React.FunctionComponent<
  IGridViewConfirmDialogProps
> = (props) => {
  const { styles } = props;
  const { classNames, subComponentStyles } = getGridViewConfirmDialogClassNames(
    styles,
    {
      ...props,
    },
  );
  const { confirmButtonProps, cancelButtonProps, dialogProps, confirmMessage } =
    useGridViewConfirmDialog(props);

  const footer = (
    <>
      <PrimaryButton
        {...confirmButtonProps}
        styles={subComponentStyles?.button}
      />
      <DefaultButton
        {...cancelButtonProps}
        styles={subComponentStyles?.button}
      />
    </>
  );

  const footerProps = {
    styles: subComponentStyles?.footer,
    children: footer,
  };

  const contentProps = {
    ...dialogProps,
    modalProps: {
      ...dialogProps.modalProps,
      className: classNames.root,
    },
    styles: subComponentStyles?.dialog,
    children: (
      <>
        {confirmMessage}
        <DialogFooter {...footerProps} />
      </>
    ),
  };

  return <Dialog {...contentProps} />;
};
